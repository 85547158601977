import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as OrderCreatedIcon } from '../../svg/order-created.svg';
import { ReactComponent as OrderErrorIcon } from '../../svg/order-error.svg';
import { orderSelector, getOrderStatus, createOrderPaymentForm } from '../../ducks/order';
import { clearCart } from '../../ducks/cart';
import {joinUser, useUserCustomError, useUserToken} from '../../ducks/auth';
import { useUserLoading, useUserError, useAuthorized } from '../../ducks/auth'
import { useTranslation } from "react-i18next";
import {paymentMethods} from "../../utils";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

function OrderCreatedModal({ orderCreated, openOrderCreatedFunc, closeOrderCreatedFunc, openLoginFunc }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const orderData = useSelector(orderSelector);
  const dispatch = useDispatch();
  const isAuthorized = useAuthorized();
  const userToken = useUserToken();
  const userLoading = useUserLoading();
  const userError = useUserError();
  const userCustomError = useUserCustomError();

  const handleJoinUser = () => {
    dispatch(joinUser({ userId: orderData.newUserId, locale: i18n.language }))
  }

  const handlePayAgain = () => {
    dispatch(createOrderPaymentForm({ orderId: orderData.success, token: userToken, locale: i18n.language }))
  }

  const MINUTE_MS = 1000 * 60;

  useEffect(() => {
    let interval;
    if (orderData.success) {
      dispatch(getOrderStatus({ orderId: orderData.success, locale: i18n.language }))
      interval = setInterval(() => {
        dispatch(getOrderStatus({ orderId: orderData.success, locale: i18n.language }))
      }, MINUTE_MS);
    }

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if (orderData.success && !orderData.payment) {
      openOrderCreatedFunc();
      dispatch(clearCart());
      navigate('/');
    }
  }, [orderData.success, orderData.payment]);

  const blockClassnames = classNames({
    'order-created': true,
    'order-created--visible': orderCreated,
    'order-created--user': isAuthorized,
  });

  const userExist = userCustomError === 'Perhaps you are already registered, please try to log in or recover your password.' ||
    userCustomError === 'Возможно вы уже зарегистрированы, попробуйте войти или восстановить пароль.';

  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': userError || userExist,
    'form__error--text-center': true,
    'form__error--black-text': userExist
  });

  let errorString = null;

  if (userError && userCustomError) {
    errorString = <span>{userCustomError}</span>
  }

  const readyString = orderData.pickup ? t('page.order.readyTime') : t('page.order.deliveryTime');
  const readyTime = orderData.preorderDateTime ? orderData.preorderDateTime : orderData.deliveryTime;

  return (
    <div className={blockClassnames}>
      <div className='order-created__top-icon'>
        {
          (orderData.paymentMethod === paymentMethods.CARD) && (orderData.orderStatus === 'новый' || orderData.orderStatus === 'new') ?
            <OrderErrorIcon title='Заказ' /> : <OrderCreatedIcon title='Заказ' />
        }
      </div>
      <div className='order-created__header'>
        <div className='base-header'>
          {t('page.order.orderNum')} {orderData.success}.
        </div>
        <div className='base-header'>
          {t('page.order.status')} {orderData.orderStatus}
        </div>
        {
          (orderData.paymentMethod === paymentMethods.CARD || orderData.paymentMethod === paymentMethods.TEST) && orderData.orderStatus === "новый"?
            <div className='order-created__pay-button'>
              <button onClick={handlePayAgain}  className='button yellow effect'>
                {t('page.order.goToPayment')}
              </button>
            </div> : null
        }

        <div className='base-header-grey'>
          {
            (orderData.paymentMethod === paymentMethods.CARD || orderData.paymentMethod === paymentMethods.TEST) &&
            (orderData.orderStatus === 'новый' || orderData.orderStatus === 'new') ? null : readyString + readyTime + '.'
          }
        </div>
      </div>
      {
        isAuthorized || userExist ? null :
        <div className='base-text-grey'>
          {t('page.order.saveAddressText')}
        </div>
      }
      <div className={formErrorClassnames}>
        {errorString}
      </div>
      {
        isAuthorized || userExist ? null :
        <div className='order-created__buttons'>
          <button onClick={userLoading ? null : handleJoinUser} className='button effect' disabled={userLoading}>
            {t('page.order.saveAddress')}
          </button>
          <button onClick={userLoading ? null : closeOrderCreatedFunc} className='button button--revert effect'>
            {t('page.order.notSaveAddress')}
          </button>
        </div>
      }
      {
        !userExist ? null :
        <div className='order-created__buttons'>
          <button onClick={userLoading ? null : openLoginFunc} className='button effect' disabled={userLoading}>
            {t('page.auth.enter')}
          </button>
        </div>
      }
    </div>
  );
}

export default OrderCreatedModal;
