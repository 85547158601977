import React, {useEffect, useState} from 'react';
import DishList from '../dishes/dish-list';
import Categories from '../dishes/categories';
import { useSelector } from 'react-redux';
import { dishesLoadingSelector, dishListSelector } from './../../ducks/dishes';
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import {Outlet, useNavigate} from "react-router";
import classNames from "classnames";
import {useLocation} from "react-router-dom";

function HomePage({ selectDishFunc, closeModals }) {
    const location = useLocation();
    const navigate = useNavigate();
    const dishesLoading = useSelector(dishesLoadingSelector);
    const dishes = useSelector(dishListSelector);
    const [showModalBg, setShowModalBg] = useState(false);
    const [hideChild, setHideChild] = useState(false);

    const formBgClassnames = classNames({
        'app__form-bg': true,
        'app__form-bg--visible': showModalBg,
        // 'app__form-bg--visible-dish-in-cart': dishInCart,
        // 'app__form-bg--visible-login-in-payment': (order && loginFlag) || orderData.showAboutPickupModal
    });

    const handleClickOutside = () => {
        setHideChild(true);
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('main_open');
        ReactPixel.pageView();
    }, []);

    useEffect(() => {
        if (location.pathname === '/') {
            setShowModalBg(false);
        } else {
            setShowModalBg(true);
        }

    }, [location]);

    useEffect(() => {
        if (closeModals) {
            setHideChild(true);
        }
    }, [closeModals]);

    return (
        <>
            <div className='home'>
                {
                    dishesLoading || !dishes.length ? null : <Categories />
                }
                <DishList selectDishFunc={selectDishFunc} />
            </div>
            <div className={formBgClassnames} onClick={handleClickOutside}><span className='app__form-bg-line'></span></div>
            <Outlet context={[hideChild, setHideChild]} />
        </>

    );
}

export default HomePage;
