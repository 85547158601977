import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

function InputBlockCheckbox(props) {
    const [field, meta, helpers] = useField(props);
    const validationFailed = !!meta.touched && !!meta.error;
    const { options } = props;
    const { value } = meta;
    const { setValue } = helpers;

    const inputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--grey': true,
        'input-block__input--error': validationFailed,
    });
    const errorClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': validationFailed,
    });

    return (
        <div className='input-block'>
            <div className='input-block__checkbox'>
                <label htmlFor={props.name}>{props.label}
                    <input
                        id={props.name}
                        type='checkbox'
                        checked={value}
                        onChange={(event) => setValue(event.target.checked)}
                        name={props.name}
                    />
                    <span className='checkmark link'></span>
                </label>
            </div>
            <span className={errorClassnames}>{meta.error}</span>
        </div>
    )
}

export default InputBlockCheckbox;
