import { put, takeEvery, all, call } from 'redux-saga/effects';
import apiService from '../services';

/**
 * Constants
 * */
export const moduleName = 'santa';
const prefix = 'santa';

export const GET_SANTA_SALES_REQUEST = `${prefix}/GET_SANTA_SALES_REQUEST`;
export const GET_SANTA_SALES_START = `${prefix}/GET_SANTA_SALES_START`;
export const GET_SANTA_SALES_SUCCESS = `${prefix}/GET_SANTA_SALES_SUCCESS`;
export const GET_SANTA_SALES_FAIL = `${prefix}/GET_SANTA_SALES_FAIL`;

export const TOGGLE_SANTA_MODAL = `${prefix}/TOGGLE_SANTA_MODAL`;

/**
 * Reducer
 * */

const initialState = {
  loading: true,
  total: 0,
  showSantaModal: false
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SANTA_SALES_START:
      return {...state, loading: true };
    case GET_SANTA_SALES_SUCCESS:
      return {...state, total: payload.total, loading: false };
    case TOGGLE_SANTA_MODAL:
      return {...state, showSantaModal: payload };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const santaSalesSelector = (state) => state[moduleName].total;
export const showSantaModalSelector = (state) => state[moduleName].showSantaModal;

/**
 * Action Creators
 * */

export const getSantaSales = () => ({
  type: GET_SANTA_SALES_REQUEST
});

export const toggleSantaModal = (payload) => ({
  type: TOGGLE_SANTA_MODAL,
  payload: payload
});

/**
 * Sagas
 * */

export const getSantaSalesSaga = function* ({ }) {
  try {
    yield put({
      type: GET_SANTA_SALES_START
    });

    const request = yield call(apiService.getSantaSales);

    yield put({
      type: GET_SANTA_SALES_SUCCESS,
      payload: request.data
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const saga = function* () {
  yield all(
    [
      takeEvery(GET_SANTA_SALES_REQUEST, getSantaSalesSaga)
    ],
  );
};
