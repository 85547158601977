import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import rootSaga from './saga';


export const initStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const composedEnhancer = composeWithDevTools(applyMiddleware(
      sagaMiddleware,
      //logger
  ))

  const store = createStore(reducer, composedEnhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};
