import React, {useEffect, useState} from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import InputMask from "react-input-mask";

function InputBlockDate(props) {
    const [field, meta] = useField(props);
    const validationFailed = !!meta.touched && !!meta.error;

    let dateMask = 'Dd.Mm.Yayy'

    let timeFormatChars = {
        'D': '[0-3]',
        'd': '[0-9]',
        'M': '[0-1]',
        'm': '[0-9]',
        'Y': '[1-2]',
        'a': '[0,9]',
        'y': '[0-9]'
    };

    let beforeTimeMaskedValueChange = (newState, oldState, userInput) => {
        let { value } = newState;

        if (value.startsWith('2')) {
            timeFormatChars['h'] = '[0-1]';
        }

        return { value, selection: newState.selection };
    };

    const inputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--grey': true,
        'input-block__input--error': validationFailed,
    });
    const errorClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': validationFailed,
    });

    return (
        <div className='input-block'>
            <div className='input-block__label'>
                <label htmlFor={props.name}>{props.label}</label>
            </div>
            <div className={inputClassnames}>
                <InputMask
                    {...props}
                    {...field}
                    mask={dateMask}
                    alwaysShowMask={false}
                    formatChars={timeFormatChars}
                    // beforeMaskedValueChange={beforeTimeMaskedValueChange}
                    placeholder='01.12.1999'
                />
            </div>
            <span className={errorClassnames}>{meta.error}</span>
        </div>
    )
}

export default InputBlockDate;
