import React, {useState} from 'react';
import classNames from 'classnames';
import { ReactComponent as Chevron } from '../../svg/chevron.svg';

function Dropdown(props) {
    const { options, onClickItem } = props;
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        if (options.length) {
            setOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const selectClassnames = classNames({
        'dropdown__select--select': true,
        'dropdown__select--select-hidden': isOpen,
    });

    const iconClassnames = classNames({
        'dropdown__select--select-icon': true,
        'dropdown__select--select-icon-hidden': isOpen,
    });

    const dropdownClassnames = classNames({
        'dropdown__select-dropdown': true,
        'dropdown__select-dropdown--visible': isOpen,
    });

    const iconDropdownClassnames = classNames({
        'dropdown__select--dropdown-icon': true,
        'dropdown__select--dropdown-icon-visible': isOpen
    });

    return (
        <div className='dropdown__select'>
            <div className={selectClassnames} onClick={ handleOpen }>
                {
                    options.length?
                        options[0].data.street:'Адреса доставки не добавлены'
                }
                <div className={iconClassnames}><Chevron/></div>
            </div>
            <div className={dropdownClassnames}>
                {options.map((option, index) => {
                    if (index === 0) {
                        return (
                            <div key={option.id} className='dropdown__select--option'>
                                <div onClick={ () => { onClickItem(option.id) } }>{option.data.street}</div>
                                <div onClick={ handleClose } className={iconDropdownClassnames}><Chevron/></div>
                            </div>
                        )
                    }
                    return (
                        <div key={option.id}
                             className='dropdown__select--option'
                             onClick={ () => { onClickItem(option.id) } }>
                            <div key={option.id}>{option.data.street}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Dropdown;
