import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { CATEGORIES } from '../../utils';
import CategoryBtn from './category-btn';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { useTranslation } from "react-i18next";

let timeout = null;

// const debounce = (func, wait, immediate) => {
//   let timeout;
//   return function() {
//     const context = this;
//     const args = arguments;
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     }, wait);
//     if (immediate && !timeout) func.apply(context, args);
//   };
// };

function Categories() {
  const { i18n } = useTranslation();

  const [selectedCategory, setSelected] = useState(null);
  const [categoryToScroll, setCategoryToScroll] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [categories, setCategories] = useState(CATEGORIES.slice());

  useEffect(() => {
    getAnchorPoints();
    // const observer = new MutationObserver(getAnchorPoints);
    // observer.observe(document.getElementById('root'), {
    //   childList: true,
    //   subtree: true,
    // });

    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollBtn);

    function scrollBtn() {
      if (!selectedCategory) return;

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        setCategoryToScroll(selectedCategory);
      }, 100);
    }
  });

  useEffect(() => {
    if (categoryToScroll) {
      elementScrollIntoView(document.getElementById(categoryToScroll), { behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [categoryToScroll]);

  function getAnchorPoints() {
    const curScroll = window.scrollY - 130;

    let _categories = categories.filter((category, i) => {
      return document.getElementById(category.anchor);
    });

    _categories.forEach((category, i) => {
      categories[i].pos = document.getElementById(category.anchor).getBoundingClientRect().top + curScroll;
    });

    setCategories(_categories);
    handleScroll();
  };

  const handleScroll = () => {
    const curPos = window.scrollY;
    let curSection = null;

    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i];
      const { pos, anchorSelf } = cat;

      if (i === categories.length - 1) {
        curSection = (curPos >= pos)
         ? anchorSelf : curSection;
      } else {
        curSection = (curPos >= pos && curPos < categories[i + 1].pos)
         ? anchorSelf : curSection;
      }

      if (curSection !== selectedCategory) {
        setSelected(curSection);
        break;
      }
    }

    if (curPos > 371) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  const catClassnames = classNames({
    'categories': true,
    'categories--scrolled': scrolled
  });

  return (
    <div className={catClassnames}>
        {
          categories.map((category) => {
            const { name, name_en, anchor, anchorSelf, decoration } = category;
            return (
              <CategoryBtn
                key={anchorSelf}
                anchor={anchor}
                name={i18n.language === 'en' ? name_en : name}
                decoration={decoration}
                selectedCategory={selectedCategory}
                anchorSelf={anchorSelf}
              />
            )
          })
        }
    </div>
  );
}

export default Categories;
