import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Formik, useFormik} from 'formik';
import { useDispatch } from 'react-redux';
import { useUserLoading, useUserInfo, useUserToken, useUserError, useUserSuccess, updateAddress, deleteAddress } from '../../ducks/auth'
import { ReactComponent as TrashIcon } from '../../svg/trash.svg';
import { getUserAddresses, findAddressById } from '../../utils';
import { useTranslation } from "react-i18next";
import {set} from "react-ga";
import {ReactComponent as ArrowLeft} from "../../svg/arrow-left-small.svg";
import InputBlock from "../common/input-block";
import InputBlockAddress from "../common/input-block-address";

function EditAddressForm({ addressId }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userInfo = useUserInfo();

  const userLoading = useUserLoading();
  const userError = useUserError();
  const userSuccess = useUserSuccess();
  const userToken = useUserToken();
  const userAddresses = getUserAddresses(userInfo);
  const selectedAddress = findAddressById(userAddresses, addressId);

  const [showSubmit, setShowSubmit] = useState(false);

  const handleSubmit = (values) => {
    dispatch(updateAddress({ ...values, addressId, userToken }));
  }

  const handleDelete = () => {
    dispatch(deleteAddress({ userToken, addressId }));
  }

  useEffect(() => {
    setTimeout(() => {
      setShowSubmit(true);
    }, 200);
  }, []);

  const validate = values => {
    const errors = {};

    if (!values.editAddress) {
      errors.editAddress = t('formValidation.required');
    }

    if (!values.editEntrance) {
      errors.editEntrance = t('formValidation.required');
    }

    if (!values.editFloor) {
      errors.editFloor = t('formValidation.required');
    }

    // if (!values.editFlat) {
    //   errors.editFlat = 'Обязательное поле';
    // }

    return errors;
  }

  const initialValues = {
    editAddress: selectedAddress.street,
    editEntrance: selectedAddress.podezd,
    editIntercom: selectedAddress['kod-domofona'],
    editFloor: selectedAddress.etazh,
    editFlat: selectedAddress.kvartira,
    editComment: selectedAddress.comment
  }

  const submitClassnames = classNames({
    'modal__submit': true,
    'modal__submit--visible': showSubmit,
    'modal__submit--appear-animation': showSubmit
  });

  const formClassnames = classNames({
    'modal__wrapper': true,
    'edit-address__form': true
  });

  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': !userError
  });

  return (
      <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          validateOnMount
          enableReinitialize>
        {
          props => {
            const {touched, isValid, errors, values} = props;
            const submitDisabled = !isValid;

            return (
                <form className={formClassnames} onSubmit={props.handleSubmit}>
                  <div className='edit-address__inputs'>
                    <div className='edit-address__big-input edit-address__big-input--with-margin'>
                      <InputBlockAddress
                          name='editAddress'
                          label={t('page.account.address')}
                          onAddressChange={ (value) => { props.setFieldValue('editAddress', value) } }
                      />
                    </div>
                    <div className='edit-address__small-input'>
                      <div className='small-inputs'>
                        <InputBlock
                            name='editEntrance'
                            label={`${t('page.account.entrance')}`}
                        />

                        <InputBlock
                            name='editIntercom'
                            label={`${t('page.account.intercom')} *`}
                        />

                        <InputBlock
                            name='editFloor'
                            label={`${t('page.account.floor')} *`}
                        />

                        <InputBlock
                            name='editFlat'
                            label={t('page.account.apartment')}
                        />
                      </div>
                    </div>
                    <div className='edit-address__big-input'>
                      <InputBlock
                          name='editComment'
                          label={`${t('page.account.comment')}`}
                      />
                    </div>
                    <div onClick={handleDelete} className='edit-address__delete edit-address__delete--mobile base-text-red effect'>
                      <span><TrashIcon /></span> {t('page.account.deleteAddress')}
                    </div>
                    <div className={formErrorClassnames}>
                      {
                        userError ? <span>{t('common.sww')}</span> : null
                      }
                    </div>
                    <div className={submitClassnames}>
                      <button className='button effect2' type='submit' disabled={ userLoading || !props.isValid }>{t('page.apply')}</button>
                      <div onClick={handleDelete} className='edit-address__delete edit-address__delete--desktop base-text-red effect'>
                        <span><TrashIcon /></span> {t('page.account.deleteAddress')}
                      </div>
                    </div>
                  </div>
                </form>
            )
          }
        }
      </Formik>
  )
}

export default EditAddressForm
