import React, { useEffect, useRef } from 'react';

function PaymentForm({ paymentData }) {
    const formEl = useRef();
    const { action_url, form_fields } = paymentData;
    const { cart,
        client_email,
        client_phone,
        clientid,
        orderid,
        pstype,
        service_name,
        sign,
        sum,
        user_result_callback
    } = form_fields;

    useEffect(() => {
        if (formEl.current && formEl.current.submit) {
            formEl.current.submit();
        }
    });

    return (
        <form id='paymentForm' ref={formEl} className='payment-form' method='POST' action={action_url}>
            <input type='text' name='cart' value={cart} /> <br />
            <input type='text' name='client_email' value={client_email} /> <br />
            <input type='text' name='client_phone' value={client_phone} /> <br />
            <input type='text' name='clientid' value={clientid} /> <br />
            <input type='text' name='orderid' value={orderid} /> <br />
            <input type='text' name='pstype' value={pstype} /> <br />
            <input type='text' name='service_name' value={service_name} /> <br />
            <input type='text' name='sign' value={sign} /> <br />
            <input type='text' name='sum' value={sum} /> <br />
            <input type='text' name='user_result_callback' value={user_result_callback} /> <br />
            <input type='submit' value='Перейти к оплате' />
        </form>
    )
}

export default PaymentForm;
