import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useAuthorized, useUserError, useUserLoading } from '../../ducks/auth';
import { ReactComponent as UserIcon } from '../../svg/user.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { login } from '../../ducks/auth';
import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";

function LoginFormPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();

  const isAuthorized = useAuthorized();
  const userLoading = useUserLoading();
  const userError = useUserError();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  /* Form animation */
  const formClassnames = classNames({
    'form': true,
    'form--login': true,
    'form--login-visible': showModal
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (isAuthorized) {
      setShowModal(false);
      setTimeout(()=> {
        navigate(-1);
      }, 200);
    }
  }, [isAuthorized]);

  const handleRecover = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/forgot');
    }, 200)
  }

  const handleRegister = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/register');
    }, 200)
  }

  const handleSubmit = async ({ email, password }) => {
    dispatch(login({ email, password }));
    await storeCredential(email, password);
  }

  /*global PasswordCredential a*/
  const storeCredential = async (username, password) => {
    if (window.PasswordCredential) {
      const c = new PasswordCredential({ password: password, id: username });
      return navigator.credentials.store(c);
    }
  }

  const validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = t('formValidation.required');
    }
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //   errors.email = 'Некорректный адрес';
    // }

    if (!values.password) {
      errors.password = t('formValidation.required');
    } else if (values.password.length < 7) {
      errors.password = t('formValidation.minlength7');
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const emailInputClassnames = classNames({
    'input-block__input': true,
    'input-block__input--grey': true,
    'input-block__input--loading': userLoading,
    'input-block__input--error': formik.errors.email && formik.touched.email,
  });

  const emailErrorClassnames = classNames({
    'input-block__error': true,
    'input-block__error--visible': formik.errors.email && formik.touched.email,
  });

  const pwdInputClassnames = classNames({
    'input-block__input': true,
    'input-block__input--grey': true,
    'input-block__input--loading': userLoading,
    'input-block__input--error': formik.errors.password && formik.touched.password,
  });

  const pwdErrorClassnames = classNames({
    'input-block__error': true,
    'input-block__error--visible': formik.errors.password && formik.touched.password,
  });

  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': userError
  });

  return (
      <form className={formClassnames} onSubmit={formik.handleSubmit}>
        <div className='form__form form__form--login'>
          <div className='form__top-icon'>
            <UserIcon title='Аккаунт' />
          </div>
          <div className='form__inputs'>
            <div className='input-block'>
              <div className='input-block__label'>
                <label htmlFor='login-email'>{t('page.auth.login')}</label>
              </div>
              <div className={emailInputClassnames}>
                <input
                    id='email'
                    name='email'
                    type='text'
                    placeholder='pizza@mail.ru'
                    autoComplete='username email'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={userLoading}
                />
              </div>
              <span className={emailErrorClassnames}>{formik.errors.email}</span>
            </div>
            <div className='input-block'>
              <div className='input-block__label'>
                <label htmlFor='login-pwd'>{t('page.auth.password')}</label>
              </div>
              <div className={pwdInputClassnames}>
                <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    disabled={userLoading}
                />
              </div>
              <span className={pwdErrorClassnames}>{formik.errors.password}</span>
            </div>
          </div>
          <div className={formErrorClassnames}>
            {
              userError ? <span>{t('page.auth.incorrectValues')}</span> : null
            }
          </div>
          <div className='form__login-bottom'>
            <div className='form__create-account'>
              <button className='effect' type='button' onClick={userLoading ? null : handleRegister}>
                {t('page.auth.create')}
              </button>
            </div>
            <div className='form__submit form__submit--login'>
              <button className='button effect' type='submit' disabled={userLoading}>
                {t('page.auth.enter')}
              </button>
            </div>
            <div className='form__forgot'>
              <button className='effect' type='button' onClick={userLoading ? null : handleRecover}>
                {t('page.auth.passwordLost')}
              </button>
            </div>
          </div>
        </div>
        <div className='form__close'>
          <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal}>
            <CloseIcon title='Закрыть'/> {t('page.close')}
          </button>
        </div>
      </form>
  );
}

export default LoginFormPage;
