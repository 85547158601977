import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { useFormik } from 'formik';
import {
  restorePwd,
  useUserLoading,
  useUserSuccess,
  useUserError,
  useUserCustomError,
  resetUserSuccess,
  resetPasswordByHash, useSuccessMessage, useUserErrorMessage, resetUserErrors, useChannel, useAsset
} from './../../ducks/auth';
import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";

function ForgotFormPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const userLoading = useUserLoading();
  const userSuccess = useUserSuccess();
  const userError = useUserError();
  const userErrorMessage = useUserErrorMessage();
  const userSuccessMessage = useSuccessMessage();
  const recoverChannel = useChannel();
  const recoverAsset = useAsset();

  const formClassnames = classNames({
    'form': true,
    'form--login': true,
    'form--login-visible': showModal,
    'form--register': true
  });

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetUserSuccess());
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    dispatch(resetUserSuccess());

    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (userError) {
      setTimeout(() => {
        dispatch(resetUserErrors());
      }, 3000);
    }
  }, [userError]);

  useEffect(() => {
    if (recoverChannel === 'sms' && recoverAsset) {
      setShowModal(false);
      dispatch(resetUserSuccess());
      setTimeout(()=> {
        navigate('/new-password/sms');
      }, 200)
    }
  }, [recoverChannel, recoverAsset]);

  const handleSubmit = (values) => {
    dispatch(restorePwd({ login: values.forgotValue, locale: i18n.language }));
  }

  const validate = values => {
    const errors = {};

    if (!values.forgotValue) {
      errors.forgotValue = t('formValidation.required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.forgotValue)) {
      if (!/^\+?[0-9]{11}$/i.test(values.forgotValue)) {
        errors.forgotValue = t('formValidation.incorrectEmailOrPhone');
      }
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      forgotValue: ''
    },
    validate,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  useEffect(() => {
    if (showModal) {
      formik.resetForm();
    }
  }, [showModal]);

  const emailInputClassnames = classNames({
    'input-block__input': true,
    'input-block__input--grey': true,
    'input-block__input--loading': userLoading,
    'input-block__input--error': formik.errors.forgotValue && formik.touched.forgotValue,
  });

  const emailErrorClassnames = classNames({
    'input-block__error': true,
    'input-block__error--visible': formik.errors.forgotValue && formik.touched.forgotValue,
  });

  const submitClassnames = classNames({
    'form__submit': true,
    'form__login-bottom': true,
    'form__submit--forgot': true,
    'form__submit--success': userSuccess
  });

  const forgotSuccessClassnames = classNames({
    'form__forgot-success': true,
    'form__forgot-success--visible': userSuccess
  });

  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': userError
  });

  return (
    <form className={formClassnames} onSubmit={formik.handleSubmit}>
      <div className='form__form form__form--forgot'>
        <div className='form__header form__header--forgot'>
          <button type='button' onClick={userLoading ? null : handleBack } className='form__header-left base-header effect'>
            <ArrowLeft /> {t('page.auth.recoverPassword')}
          </button>
        </div>
        <div className='form__inputs'>
          <div className='input-block'>
            <div className='input-block__label'>
              <label htmlFor='forgotValue'>{t('page.auth.emailOrPhone')}*</label>
            </div>
            <div className={emailInputClassnames}>
              <input
                id='forgotValue'
                name='forgotValue'
                type='text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.forgotValue}
              />
            </div>
            <span className={emailErrorClassnames}>{formik.errors.forgotValue}</span>
          </div>
        </div>
        <div className={forgotSuccessClassnames}>
          <span className='base-text'>
            {userSuccessMessage}
          </span>
        </div>
        <div className={formErrorClassnames}>
          {
            userError ? <span>{userErrorMessage}</span> : null
          }
        </div>
        <div className={submitClassnames}>
          <button className='button effect' type='submit' disabled={userLoading}>{t('page.auth.recover')}</button>
        </div>
      </div>
      <div className='form__close'>
        <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal }>
          <CloseIcon title='Закрыть'/> {t('page.close')}
        </button>
      </div>
    </form>
  );
}

export default ForgotFormPage;
