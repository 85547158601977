import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";

function PaymentConditionsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const conditionsClassnames = classNames({
    'conditions modal': true,
    'modal--visible': showModal
  });

  const headerClassnames = classNames({
    'modal-header': true,
    'modal-header--visible': showModal
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  return (
    <div className={conditionsClassnames}>
      <div className={headerClassnames}>
        <div className='modal-header__back'>
          <button type='button' onClick={ handleBack } className='base-header effect'>
            <ArrowLeft /> {t('page.conditions.paymentsConditions')}
          </button>
        </div>
        <div className='modal-header__mobile-close'>
          <button type='button' className='effect' onClick={ handleCloseModal }>
            <CloseSmallIcon title='Закрыть' />
          </button>
        </div>
        <div className='modal-header__close'>
          <button type='button' className='effect' onClick={ handleCloseModal }>
            <CloseIcon title='Закрыть'/> {t('page.close')}
          </button>
        </div>
      </div>
      <div className='modal__wrapper'>
        <div className='conditions__part'>
          <div className='conditions__header-one base-header'>
            {t('page.conditions.paymentsOptions')}
          </div>
          <ul className='base-text'>
            <li>
              {t('page.conditions.byCard')}
            </li>
            <li>
              {t('page.conditions.byCash')}
            </li>
          </ul>
          <div>
            <span className='base-text-grey'>{t('page.conditions.paymentText')}</span> <span className='base-text'>VISA Inc, MasterCard WorldWide, МИР</span>.
          </div>
        </div>

        <div className='conditions__part'>
          <div className='conditions__header-two base-header'>
            {t('page.conditions.dataTransfer')}
          </div>
          <div className='base-text-grey'>
            {t('page.conditions.paymentText2')}
          </div>
        </div>

        <div className='conditions__part'>
          <div className='conditions__header-three base-text'>
            {t('page.conditions.paymentProcess')}
          </div>
          <div className='base-text-grey'>
            {t('page.conditions.paymentText3')}
          </div>
        </div>

        <div>
          <div className='conditions__header-four base-text'>
            {t('page.conditions.orderCancellation')}
          </div>
          <div className='base-text-grey'>
            {t('page.conditions.paymentText4')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentConditionsPage;
