import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import {ReactComponent as CloseSmallIcon} from "../../svg/close-36.svg";
import {ReactComponent as CloseIcon} from "../../svg/close-48.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    deliveryTimeSelector, deliveryZoneSelector, geoJsonSelector,
    getDeliveryTime, getPolygons,
    resetDeliveryTime,
} from "../../ducks/map";
import { withYMaps, YMaps } from "react-yandex-maps";
import { Formik, Field } from 'formik';
import {ReactComponent as ArrowLeft} from "../../svg/arrow-left-small.svg";
import InputBlock from "../common/input-block";

import {
    addNewAddress, getProfile, resetUserSuccess,
    updateAddress,
    useUserError,
    useUserErrorMessage,
    useUserLoading,
    useUserSuccess, useUserToken
} from "../../ducks/auth";
import {useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";

function validateAddress(precision) {

    let error;
    let hint;
    switch (precision) {
        case 'exact':
            break;
        case 'number':
        case 'near':
        case 'range':
            error = 'formValidation.notPrecisionAddress';
            hint = 'formValidation.specifyHouseNumber';
            break;
        case 'street':
            error = 'formValidation.notFullAddress';
            hint = 'formValidation.specifyHouseNumber';
            break;
        case 'other':
        default:
            error = 'formValidation.notPrecisionAddress';
            hint = 'formValidation.specifyAddress';
    }

    if (error) {
        return {
            success: false,
            message: [error, hint]
        };
    }

    return {
        success: true
    };
}

function MapAddressSearchComponent(props) {
    const { t } = useTranslation();
    const { ymaps, result } = props;
    const [resultMessage, setResultMessage] = useState('');

    const highlightAddress = (value) => {
        ymaps.geocode(value).then(function (res)
        {
            let obj = res.geoObjects.get(0);
            const validationResult = validateAddress(obj.properties.get('metaDataProperty.GeocoderMetaData.precision'));
            if (obj && validationResult.success)
            {
                setResultMessage('');
                result(obj);
            } else {
                setResultMessage(`${t(validationResult.message[0])} ${t(validationResult.message[1])}`);
            }
        });
    };

    const clearMessage = () => {
        setResultMessage('');
    }

    useEffect(() => {
        const suggestView = new ymaps.SuggestView('suggest', {
            provider: {
                suggest: (request, options) => ymaps.suggest(`Москва, ${request}`)
            },
            results: 3
        });

        suggestView.events.add('select', (e) => {
            highlightAddress(e.get('item').value);
        });
    }, [ymaps.SuggestView]);

    const inputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--grey': true,
        'input-block__input--error': false,
    });

    const resultClassnames = classNames({
        'account__address--map-search-block-result': true,
        'account__address--map-search-block-result-appear': !!resultMessage,
    });

    return (
        <div className='account__address--map-search-block'>
            <div className={inputClassnames}>
                <input id='suggest' onClick={clearMessage} placeholder={t('page.delivery.typeAddress')}/>
            </div>
            <div className={resultClassnames}>
                { resultMessage }
            </div>
        </div>
    )
}

function MapAddressComponent(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { ymaps, setStreet, suggest } = props;
    const deliveryTime = useSelector(deliveryTimeSelector);
    const deliveryZone = useSelector(deliveryZoneSelector);
    const [resultMessage, setResultMessage] = useState('');
    const [addressValid, setAddressValid] = useState(false);
    let [yandexMap, setYandexMap] = useState(null);
    let [placemark, setPlacemark] = useState(null);

    const addPlaceMark = (coords) => {
        const iconContentLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="account__address--map-placemark">$[properties.iconContent]</div>'
        );

        return new Promise((resolve) => {
            let placeMark = new ymaps.Placemark(coords, {
                hintContent: 'Подвинь меня!',
                iconContent: t('common.loading')
            }, {
                draggable: true,
                iconLayout: 'default#imageWithContent',
                iconImageHref: '/img/icon-placemark.png',
                iconImageSize: [100, 107],
                iconImageOffset: [-50, -107],
                iconContentLayout: iconContentLayout
            });
            resolve(placeMark);
        })
    }

    function setData(obj, coords) {
        const validationResult = validateAddress(obj.properties.get('metaDataProperty.GeocoderMetaData.precision'));
        if (obj && validationResult.success)
        {
            let address = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');
            if (address.trim() === '') {
                address = obj.getAddressLine();
            }
            setStreet(address);
            setResultMessage('');
            setAddressValid(true);
            dispatch(getDeliveryTime(coords));
        } else {
            setStreet('');
            setResultMessage(`${t(validationResult.message[0])} ${t(validationResult.message[1])}`);
            setAddressValid(false);
        }
    }

    useEffect(() => {
        if (placemark) {
            // console.log(deliveryZone, deliveryTime, addressValid)
            if (deliveryTime && deliveryZone) {
                const text = deliveryTime? t('page.auth.time', { time: deliveryTime } ) : t('common.loading' );
                placemark.properties.set('iconContent', text);
            }
        }
    }, [placemark, deliveryTime]);

    useEffect(() => {
        if (yandexMap) {
            props.setMap(yandexMap);
        }
    }, [yandexMap]);

    /* received suggest result */
    useEffect(() => {
        if (suggest && yandexMap) {
            let coords = suggest.geometry.getCoordinates();

            setData(suggest);

            addPlaceMark(coords).then((point) => {
                yandexMap.geoObjects.removeAll();
                yandexMap.geoObjects.add(point);

                /* Pan Map */
                yandexMap.panTo(coords, {flying: true});
                setTimeout(() => {
                    yandexMap.setZoom(18);
                }, 1000);

                dispatch(getDeliveryTime(coords));

                /* place mark events */
                point.events.add('dragstart', function (e)
                {
                    point.properties.set('iconContent', t('page.delivery.dragMe'));
                    dispatch(resetDeliveryTime());
                    setStreet('');
                });

                point.events.add('dragend', function (e)
                {
                    point.properties.set('iconContent', t('common.loading'));

                    let coords = e.get('target').geometry.getCoordinates();
                    dispatch(getDeliveryTime(coords));

                    ymaps.geocode(coords, {results: 1}).then(function (res) {
                        let obj = res.geoObjects.get(0);
                        setData(obj);
                    });
                });

                setPlacemark(point);
            });
        }
    }, [suggest]);

    useEffect(() => {
        let map = new ymaps.Map("address-map", {
            center: [55.753220, 37.622513],
            zoom: 10,
            controls: ['geolocationControl'],
        }, {
                searchControlProvider: 'yandex#search',
                yandexMapDisablePoiInteractivity: true
            }
        );

        const zoomControl = new ymaps.control.ZoomControl({
            options: {
                size: 'small',
                float: 'none',
                position: {
                    bottom: 200,
                    right: 10
                }
            }
        });
        map.controls.add(zoomControl);

        map.events.add('click', function (e) {
            let coords = e.get('coords');

            addPlaceMark(coords).then((point) => {
                map.geoObjects.removeAll();
                map.geoObjects.add(point);

                ymaps.geocode(coords, {results: 1}).then(function (res) {
                    let obj = res.geoObjects.get(0);
                    setData(obj);
                });

                /* Pan Map */
                map.setZoom(18);
                setTimeout(() => {
                    map.panTo(coords, {flying: true});
                }, 600);

                dispatch(getDeliveryTime(coords));

                /* place mark events */
                point.events.add('dragstart', function (e)
                {
                    point.properties.set('iconContent', t('page.delivery.dragMe'));
                    dispatch(resetDeliveryTime());
                    props.setStreet('');
                });

                point.events.add('dragend', function (e)
                {
                    point.properties.set('iconContent', t('common.loading'));

                    let coords = e.get('target').geometry.getCoordinates();
                    ymaps.geocode(coords, {results: 1}).then(function (res) {
                        let obj = res.geoObjects.get(0);
                        setData(obj, coords);
                    });
                });

                setPlacemark(point);
            });
        });

        setYandexMap(map);
    }, [ymaps.Map]);

    return (
        <div>
            <div id="address-map" className='account__address_map'></div>
        </div>
    );
}

function AddAddressWithMapPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [hideChild, setHideChild]= useOutletContext();
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const userLoading = useUserLoading();
    const userError = useUserError();
    const userSuccess = useUserSuccess();
    const userToken = useUserToken();

    const deliveryZone = useSelector(deliveryZoneSelector);
    const [address, setAddress] = useState('');
    const [suggestAddress, setSuggestAddress] = useState('');
    const [addressValid, setAddressValid] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);
    const [yaMap, setYaMap] = useState({});

    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/');
        }, 200)
    }

    const handleBackModal = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate(-1);
        }, 200)
    }

    const handleStreetName = (name) => {
        setAddress(name);
    }

    const handleSuggestResult = (result) => {
        setSuggestAddress(result);
    }

    const mapLoaded = (map) => {
        setYaMap(map);
    }

    const handleSelectAddress = () => {
        setAddressSelected(true);
    }

    useEffect(() => {
        setAddressValid(deliveryZone > 0);
    }, [deliveryZone]);

    useEffect(() => {
        if (location) {
            setTimeout(()=> {
                setShowModal(true);
            }, 200)
        }
    }, [location]);

    useEffect(() => {
        if (hideChild) {
            handleCloseModal();
            setHideChild(false);
        }
    }, [hideChild]);

    useEffect(() => {
        if (userSuccess) {
            handleBackModal();
            dispatch(resetUserSuccess());
            dispatch(getProfile());
        }
    }, [userSuccess]);

    const modalClassnames = classNames({
        'account__col--map-modal': true,
        'account__col--map-modal-visible': showModal
    });

    const MapComponent = useMemo(() => {
        return withYMaps(MapAddressComponent, true, [
            'Map',
            'Event',
            'control.ZoomControl',
            'control.GeolocationControl',
            'SuggestView',
            'suggest',
            'GeoObject',
            'layout.ImageWithContent',
            'templateLayoutFactory',
            'geoQuery',
            'geocode',
            'Polygon',
            'geoObject.addon.balloon',
            'geoObject.addon.hint',
            'GeoObjectCollection',
            'Placemark'
        ]);
    }, []);

    const MapSearchComponent = useMemo(() => {
        return withYMaps(MapAddressSearchComponent, true, [
            'SuggestView',
        ]);
    }, [yaMap]);

    const submitClassnames = classNames({
        'modal__submit': true,
        'modal__submit--visible': addressSelected,
        'modal__submit--appear-animation': addressSelected
    });

    const formClassnames = classNames({
        'form': true,
        'form--full-page': true,
        'form--full-page-visible': addressSelected,
    });

    const formErrorClassnames = classNames({
        'form__error': true,
        'form__error--visible': userError
    });

    const mapClassnames = classNames({
        'account__address-map-disabled': addressSelected,
    });

    const addressTextClassnames = classNames({
        'account__address--map-address-text': true,
        'account__address--map-address-text-active': deliveryZone !== null && (address || !addressValid),
    });

    const saveButtonClassnames = classNames({
        'account__address--map-save': true,
        'account__address--map-save-visible' : addressValid && address
    });

    const initialValues = {
        addAddress: address,
        addEntrance: '',
        addIntercom: '',
        addFloor: '',
        addFlat: '',
        addComment: ''
    };

    const validate = values => {
        const errors = {};

        if (!values.addAddress) {
            errors.addAddress = t('formValidation.required');
        }

        if (!values.addIntercom) {
            errors.addIntercom = t('formValidation.required');
        }

        if (!values.addFloor) {
            errors.addFloor = t('formValidation.required');
        }

        if (!values.addFlat) {
            errors.addFlat = t('formValidation.required');
        }

        return errors;
    }

    const handleSubmit = (values) => {
        dispatch(addNewAddress({ ...values, userToken }));
    }

    return (
        <div className={ modalClassnames }>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={handleSubmit}
                validateOnMount
                enableReinitialize>
                {
                    props => {
                        const {touched, isValid, errors, values} = props;
                        const submitDisabled = !isValid;

                        return (
                            <form className={formClassnames} onSubmit={props.handleSubmit}>
                                <div className='form__form--add-address-with-map'>
                                    <div className='form__header form__header--register'>
                                        <button className='form__header-left base-header effect' type='button'
                                                onClick={userLoading ? null : handleBackModal}>
                                            <ArrowLeft/> {t('common.back')}
                                        </button>
                                    </div>
                                    <div className='edit-address__inputs'>
                                        <div className='edit-address__big-input edit-address__big-input--with-margin'>
                                            <InputBlock
                                                name='addAddress'
                                                disabled
                                                label={`${t('page.account.address')} *`}
                                            />
                                        </div>

                                        <div className='edit-address__small-input'>
                                            <div className='small-inputs'>
                                                <InputBlock
                                                    name='addEntrance'
                                                    label={`${t('page.account.entrance')}`}
                                                />

                                                <InputBlock
                                                    name='addIntercom'
                                                    label={`${t('page.account.intercom')} *`}
                                                />

                                                <InputBlock
                                                    name='addFloor'
                                                    label={`${t('page.account.floor')} *`}
                                                />

                                                <InputBlock
                                                    name='addFlat'
                                                    label={`${t('page.account.apartment')} *`}
                                                />
                                            </div>
                                        </div>

                                        <div className='edit-address__big-input'>
                                            <InputBlock
                                                name='addComment'
                                                label={`${t('page.account.comment')}`}
                                            />
                                        </div>

                                        <div className='edit-address__big-input'>
                                            <div className={formErrorClassnames}>
                                                {
                                                    userError ? <span>{t('page.auth.registerError')}</span> : null
                                                }
                                            </div>
                                        </div>

                                        <div className={submitClassnames}>
                                            <button className='button effect' type='submit'
                                                    disabled={userLoading || submitDisabled}>{t('page.apply')}</button>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className='form__close'>*/}
                                {/*    <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal}>*/}
                                {/*        <CloseIcon title='Закрыть'/> {t('page.close')}*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </form>
                        )
                    }
                }
            </Formik>

            <div className={mapClassnames}>
                <div className='account__address--map-container'>
                    <YMaps query={{
                        apikey: 'c59f88c5-098c-4fea-8988-e89a93712b5c',
                        suggest_apikey: '181d31c5-3415-4b0c-a767-c50289e8de24' }}>
                        <MapComponent
                            setStreet={ handleStreetName }
                            setMap={ mapLoaded }
                            suggest={ suggestAddress }
                        />
                        <MapSearchComponent map={ yaMap } result={ handleSuggestResult } />
                    </YMaps>
                </div>

                <div className='account__address--map-close-mobile'>
                    <button className='effect' onClick={ handleBackModal }>
                        <CloseSmallIcon title='Закрыть' />
                    </button>
                </div>
                <div className='account__address--map-close'>
                    <button className='effect' onClick={ handleBackModal }>
                        <CloseIcon title='Закрыть' />
                    </button>
                </div>
                <div className='account__address--map-address'>
                    <div className={addressTextClassnames}>
                        { addressValid? address : t('page.delivery.notAvailable') }
                    </div>
                </div>
                <div className={saveButtonClassnames}>
                    <button className='effect button'  onClick={ handleSelectAddress } disabled={!addressValid || address === ''}>
                        {t('page.auth.address_save_btn')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddAddressWithMapPage
