import { put, takeEvery, all, delay, call, select } from 'redux-saga/effects';
import { PRODUCTS, TOPPINGS, checkIfTenPercentDicsountIsActive, collectOrderParamsForAmplitude } from './../utils'
import apiService from '../services';
import { orderSelector } from './order'
import { tokenSelector } from './auth'
import amplitude from 'amplitude-js';
import ReactPixel from "react-facebook-pixel";
import ym from "react-yandex-metrika";

function sendAmplitudeEvent(event, params) {
  if (params) {
    amplitude.getInstance().logEvent(event, params);
  } else {
    amplitude.getInstance().logEvent(event);
  }
}

function sendFBPixelEvent(event, params) {
  ReactPixel.trackCustom(event, params);
}

function sendYMEvent(event) {
  ym('reachGoal', event);
}

/**
 * Constants
 * */
export const moduleName = 'cart';
// const prefix = `${appName}/${moduleName}`;
const prefix = 'cart';

export const IS_LOADING_START = `${prefix}/IS_LOADING_START`;
export const IS_LOADING_FINISH = `${prefix}/IS_LOADING_FINISH`;
export const ADD_DISH_REQUEST = `${prefix}/ADD_DISH_REQUEST`;
export const ADD_DISH_START = `${prefix}/ADD_DISH_START`;
export const ADD_DISH_SUCCESS = `${prefix}/ADD_DISH_SUCCESS`;
export const ADD_DISH_FINISH = `${prefix}/ADD_DISH_FINISH`;
export const REMOVE_DISH_REQUEST = `${prefix}/REMOVE_DISH_REQUEST`;
export const REMOVE_DISH_START = `${prefix}/REMOVE_DISH_START`;
export const REMOVE_DISH_SUCCESS = `${prefix}/REMOVE_DISH_SUCCESS`;
export const REMOVE_DISH_FINISH = `${prefix}/REMOVE_DISH_FINISH`;
export const ADD_ADDITION_REQUEST = `${prefix}/ADD_ADDITION_REQUEST`;
export const ADD_ADDITION = `${prefix}/ADD_ADDITION`;
export const REMOVE_ADDITION_REQUEST = `${prefix}/REMOVE_ADDITION_REQUEST`;
export const REMOVE_ADDITION = `${prefix}/REMOVE_ADDITION`;
export const ADD_DISH_WITH_ADDITION_REQUEST = `${prefix}/ADD_DISH_WITH_ADDITION_REQUEST`;
export const ADD_DISH_WITH_ADDITION = `${prefix}/ADD_DISH_WITH_ADDITION`;
export const INCREASE_CUTLERY_REQUEST = `${prefix}/INCREASE_CUTLERY_REQUEST`;
export const DECREASE_CUTLERY_REQUEST = `${prefix}/DECREASE_CUTLERY_REQUEST`;
export const INCREASE_CUTLERY = `${prefix}/INCREASE_CUTLERY`;
export const DECREASE_CUTLERY = `${prefix}/DECREASE_CUTLERY`;
export const CLEAR_CART_REQUEST = `${prefix}/CLEAR_CART_REQUEST`;
export const CLEAR_CART = `${prefix}/CLEAR_CART`;
export const ADD_DISHES_FROM_ORDER_REQUEST = `${prefix}/ADD_DISHES_FROM_ORDER_REQUEST`;
export const ADD_DISHES_FROM_ORDER = `${prefix}/ADD_DISHES_FROM_ORDER`;
export const INCREASE_DISH_REQUEST = `${prefix}/INCREASE_DISH_REQUEST`;
export const DECREASE_DISH_REQUEST = `${prefix}/DECREASE_DISH_REQUEST`;
export const INCREASE_DISH = `${prefix}/INCREASE_DISH`;
export const DECREASE_DISH = `${prefix}/DECREASE_DISH`;

/**
 * Reducer
 * */

const defaultState = {
  entities: JSON.parse(localStorage.getItem('cart')) || [],
  cutlery: 1,
  loading: false
}

export default function reducer(state = defaultState, action) {
  const { type, payload } = action;

  const saveInLocalStorage = (c) => {
    localStorage.setItem('cart', JSON.stringify(c));
  }

  switch (type) {
    case IS_LOADING_START:
      return { ...state, loading: true };
    case IS_LOADING_FINISH:
      return { ...state, loading: false };
    case ADD_DISH_START:
      return { ...state, loading: true,  };
    case ADD_DISH_SUCCESS:
      const entitiesWithNew = state.entities.slice();
      entitiesWithNew.push(payload);
      saveInLocalStorage(entitiesWithNew);

      return { ...state, entities: entitiesWithNew };
    case ADD_DISH_FINISH:
      return { ...state, loading: false };
    case INCREASE_DISH:
      let entitiesIncreasedAmount = state.entities.slice();
      if (entitiesIncreasedAmount[payload] !== undefined) {
        entitiesIncreasedAmount[payload].amount++;
      }
      saveInLocalStorage(entitiesIncreasedAmount);

      return { ...state, entities: entitiesIncreasedAmount };
    case DECREASE_DISH:
      let entitiesDecreasedAmount = state.entities.slice();

      if (entitiesDecreasedAmount[payload] !== undefined) {
        if (entitiesDecreasedAmount[payload].amount > 1) {
          entitiesDecreasedAmount[payload].amount--;
        } else {
          entitiesDecreasedAmount.splice(payload, 1);
        }
      }

      saveInLocalStorage(entitiesDecreasedAmount);

      return { ...state, entities: entitiesDecreasedAmount };
    case REMOVE_DISH_START:
      return { ...state, loading: true };
    case REMOVE_DISH_SUCCESS:
      const entitiesWithout = [];

      state.entities.forEach((entity) => {
        if (entity.id === payload.id) {
          entity.amount--;
          if (entity.amount > 0) {
            entitiesWithout.push(entity);
          }
        } else {
          entitiesWithout.push(entity);
        }
      });

      saveInLocalStorage(entitiesWithout);

      return { ...state, entities: entitiesWithout };
    case REMOVE_DISH_FINISH:
      return { ...state, loading: false };
    case ADD_DISHES_FROM_ORDER:
      const dishesFromOrder = payload.slice();

      saveInLocalStorage(dishesFromOrder);

      return { ...state, entities: dishesFromOrder };
    case ADD_DISH_WITH_ADDITION:
      const entitiesWithNewAddition = state.entities.slice();
      entitiesWithNewAddition.push(payload);

      saveInLocalStorage(entitiesWithNewAddition);

      return { ...state, entities: entitiesWithNewAddition };
    case ADD_ADDITION:
      const entitiesWithAddition = [];

      state.entities.forEach((dish, idx) => {
        const { additions } = dish;
        const newAddition = {
          additionId: payload.additionId,
          additionPrice: payload.additionPrice,
          additionName: payload.additionName,
          additionNameEn: payload.additionNameEn
        };
        if (idx === payload.cart_item) {
          if (additions) {
            dish.additions = [newAddition].concat(additions);
          } else {
            dish.additions = [newAddition];
          }
        }
        entitiesWithAddition.push(dish);
      })

      saveInLocalStorage(entitiesWithAddition);

      return { ...state, entities: entitiesWithAddition };
    case REMOVE_ADDITION:
      let entitiesWithoutAddition = [];
      const additionId = payload.additionId;

      state.entities.forEach((dish, idx) => {
        const { additions } = dish;
        const newAdditions = [];

        if (idx === payload.cart_item && additions) {
          additions.forEach((t) => {
            if (t.additionId !== additionId) {
              newAdditions.push(t);
            }
          })
        } else if (additions) {
          additions.forEach((t) => {
            newAdditions.push(t);
          })
        }

        dish.additions = newAdditions;
        entitiesWithoutAddition.push(dish);
      });
      saveInLocalStorage(entitiesWithoutAddition);

      return { ...state, entities: entitiesWithoutAddition };
    case INCREASE_CUTLERY:
      return { ...state, cutlery: state.cutlery + 1 };
    case DECREASE_CUTLERY:
      let newC = state.cutlery - 1;
      if (newC < 0) newC = 0;
      return { ...state, cutlery: newC };
    case CLEAR_CART:
      return { entities: [], cutlery: 1, loading: false };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const cartSelector = (state) => state[moduleName].entities;
export const cutlerySelector = (state) => state[moduleName].cutlery;
export const loadingSelector = (state) => state[moduleName].loading;
export const priceSelector = (state) => {
  let price = 0;

  if (state[moduleName].entities.length) {
    state[moduleName].entities.forEach((entity) => {
      let entityPrice = entity.price;
      if (entity.additions) {
        entity.additions.forEach((t) => {
          entityPrice += t.additionPrice;
        })
      }
      price += entityPrice * entity.amount;
    })
  }

  return price;
}

export const excludedPriceSelector = (state) => {
  let price = 0;

  if (state[moduleName].entities.length) {
    state[moduleName].entities.forEach((entity) => {
      if (entity.category_id === '12' || entity.category_id === '9') {
        let entityPrice = entity.price;
        if (entity.additions) {
          entity.additions.forEach((t) => {
            entityPrice += t.additionPrice;
          })
        }
        price += entityPrice * entity.amount;
      }
    })
  }

  return price;
}

/**
 * Action Creators
 * */

export const addDish = (dish) => ({
 type: ADD_DISH_REQUEST,
 payload: dish
});

export const removeDish = (dish) => ({
 type: REMOVE_DISH_REQUEST,
 payload: dish
});

export const increaseDish = (idx) => ({
  type: INCREASE_DISH_REQUEST,
  payload: idx
});

export const decreaseDish = (idx) => ({
  type: DECREASE_DISH_REQUEST,
  payload: idx
});

export const addAddition = (data) => ({
 type: ADD_ADDITION_REQUEST,
 payload: data
});

export const removeAddition = (data) => ({
 type: REMOVE_ADDITION_REQUEST,
 payload: data
});

export const addDishWithAddition = (data) => ({
 type: ADD_DISH_WITH_ADDITION_REQUEST,
 payload: data
});

export const increaseCutlery = () => ({
 type: INCREASE_CUTLERY_REQUEST
});

export const decreaseCutlery = () => ({
 type: DECREASE_CUTLERY_REQUEST
});

export const addDishesFromOrder = (dishes) => ({
 type: ADD_DISHES_FROM_ORDER_REQUEST,
 payload: dishes
});

export const clearCart = () => ({
 type: CLEAR_CART_REQUEST
});

/**
 * Sagas
 * */

export const addDishSaga = function* ({ payload }) {
  if (payload.extra) {
    yield call(sendAmplitudeEvent, 'button_cart_add_dish', {
      dish_name: PRODUCTS[payload.id]
    });
    yield call(sendFBPixelEvent, 'button_cart_add_dish', {
      dish_name: PRODUCTS[payload.id]
    });
    yield call(sendYMEvent, 'add_extra');
  } else {
    yield call(sendAmplitudeEvent, 'button_menu_add_cart', {
      dish_name: PRODUCTS[payload.id]
    });
    yield call(sendFBPixelEvent, 'button_menu_add_cart', {
      dish_name: PRODUCTS[payload.id]
    });
    yield call(sendYMEvent, 'add_dish');
  }

  yield put({
   type: ADD_DISH_START
  });

  yield put({
   type: ADD_DISH_SUCCESS,
   payload: payload,
  });

  yield delay(1000);

  yield put({
   type: ADD_DISH_FINISH
  });
};

export const removeDishSaga = function* ({ payload }) {
  yield call(sendAmplitudeEvent, 'button_cart_delete', {
    dish_name: PRODUCTS[payload.id]
  });
  yield call(sendFBPixelEvent, 'button_cart_delete', {
    dish_name: PRODUCTS[payload.id]
  });

  yield put({
   type: REMOVE_DISH_START
  });

  yield put({
   type: REMOVE_DISH_SUCCESS,
   payload: payload,
  });

  yield delay(200);

  yield put({
   type: REMOVE_DISH_FINISH
  });
};

export const increaseDishSaga = function* ({ payload }) {
  const cart = yield select(cartSelector);
  const dish = cart[payload];

  if (dish !== undefined) {
    yield call(sendAmplitudeEvent, 'button_increase_dish', {
      dish_name: PRODUCTS[dish.id]
    });
    yield call(sendFBPixelEvent, 'button_increase_dish', {
      dish_name: PRODUCTS[dish.id]
    });

    yield put({
      type: IS_LOADING_START
    });

    yield put({
      type: INCREASE_DISH,
      payload: payload,
    });

    yield delay(200);

    yield put({
      type: IS_LOADING_FINISH
    });
  }
};

export const decreaseDishSaga = function* ({ payload }) {
  const cart = yield select(cartSelector);
  const dish = cart[payload];

  if (dish !== undefined) {
    yield call(sendAmplitudeEvent, 'button_decrease_dish', {
      dish_name: PRODUCTS[dish.id]
    });
    yield call(sendFBPixelEvent, 'button_decrease_dish', {
      dish_name: PRODUCTS[dish.id]
    });

    yield put({
      type: IS_LOADING_START
    });

    yield put({
      type: DECREASE_DISH,
      payload: payload,
    });

    yield delay(200);

    yield put({
      type: IS_LOADING_FINISH
    });
  }
};

export const addAdditionSaga = function* ({ payload }) {
  yield call(sendAmplitudeEvent, 'menu_open_dish_add_extra', {
    dish_name: PRODUCTS[payload.id],
    extra_name: TOPPINGS[payload.additionId]
  });

  yield call(sendFBPixelEvent, 'menu_open_dish_add_extra', {
    dish_name: PRODUCTS[payload.id],
    extra_name: TOPPINGS[payload.additionId]
  });

  yield put({
    type: ADD_ADDITION,
    payload: payload,
  });
};

export const removeAdditionSaga = function* ({ payload }) {
  yield call(sendAmplitudeEvent, 'bottom_cart_delete', {
    dish_name: PRODUCTS[payload.id],
    extra_name: TOPPINGS[payload.additionId]
  });

  yield call(sendFBPixelEvent, 'bottom_cart_delete', {
    dish_name: PRODUCTS[payload.id],
    extra_name: TOPPINGS[payload.additionId]
  });

  yield put({
    type: REMOVE_ADDITION,
    payload: payload,
  });
};

export const addDishWithAdditionSaga = function* ({ payload }) {
  yield call(sendAmplitudeEvent, 'menu_open_dish_add_extra', {
    dish_name: PRODUCTS[payload.id],
    extra_name: TOPPINGS[payload.additions[0].additionId]
  });

  yield call(sendFBPixelEvent, 'menu_open_dish_add_extra', {
    dish_name: PRODUCTS[payload.id],
    extra_name: TOPPINGS[payload.additions[0].additionId]
  });

  yield put({
    type: ADD_DISH_WITH_ADDITION,
    payload: payload,
  });
};

export const addDishesFromOrderSaga = function* ({ payload }) {
  yield put({
    type: ADD_DISHES_FROM_ORDER,
    payload: payload,
  });
};

export const increaseCutlerySaga = function* () {
 yield put({
   type: INCREASE_CUTLERY
 });
};

export const decreaseCutlerySaga = function* () {
 yield put({
   type: DECREASE_CUTLERY
 });
};

export const clearCartSaga = function* () {
  const order = yield select(orderSelector);
  const items = yield select(cartSelector);
  const token = yield select(tokenSelector);

  const apmlitudeParams = yield call(collectOrderParamsForAmplitude, order, items);
  const saleTime = yield call(checkIfTenPercentDicsountIsActive);
  apmlitudeParams.order_position_number = items.length;
  apmlitudeParams.order_time = saleTime ? 'sale_time' : 'regular';

  if (token) {
    const request = yield call(apiService.getOrders, { token });
    apmlitudeParams.order_numbers = request.data && request.data.orders_total ? +request.data.orders_total : 1;
  } else {
    apmlitudeParams.order_numbers = 1;
  }

  yield call(sendAmplitudeEvent, 'order_success', apmlitudeParams);
  yield call(sendFBPixelEvent, 'order_success', apmlitudeParams);
  yield call(sendYMEvent, 'order_success');

  yield put({
    type: CLEAR_CART
  });
};

export const saga = function* () {
  yield all(
    [
      takeEvery(ADD_DISH_REQUEST, addDishSaga),
      takeEvery(REMOVE_DISH_REQUEST, removeDishSaga),
      takeEvery(INCREASE_DISH_REQUEST, increaseDishSaga),
      takeEvery(DECREASE_DISH_REQUEST, decreaseDishSaga),
      takeEvery(ADD_ADDITION_REQUEST, addAdditionSaga),
      takeEvery(REMOVE_ADDITION_REQUEST, removeAdditionSaga),
      takeEvery(ADD_DISH_WITH_ADDITION_REQUEST, addDishWithAdditionSaga),
      takeEvery(INCREASE_CUTLERY_REQUEST, increaseCutlerySaga),
      takeEvery(DECREASE_CUTLERY_REQUEST, decreaseCutlerySaga),
      takeEvery(ADD_DISHES_FROM_ORDER_REQUEST, addDishesFromOrderSaga),
      takeEvery(CLEAR_CART_REQUEST, clearCartSaga),
    ]
  );
};
