import React from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as PickupIcon } from '../../svg/pickup.svg';
import { ReactComponent as CiaoIcon } from '../../svg/ciao-picup.svg';
import { ReactComponent as TopIcon } from '../../svg/about-pickup-top-icon.svg';
import { ReactComponent as MidIcon } from '../../svg/about-pickup-mid-icon.svg';
import { ReactComponent as BottomIcon } from '../../svg/about-pickup-bottom-icon.svg';
import { useTranslation } from "react-i18next";

function AboutPickupPage({ show, closeAll }) {
  const { t } = useTranslation();

  const aboutClassnames = classNames({
    'page-sheet': true,
    'page-sheet--visible': show
  });

  const handleCloseClick = () => {
    closeAll(false);
  };

  return (
    <div className={aboutClassnames}>
      <div className='page-sheet__wrapper'>
        <div className='about-pickup'>

          <div className='about-pickup__banner'>
            <div className="about-pickup__banner-column">
              <div className='about-pickup__banner-left'>
                <CiaoIcon></CiaoIcon>
              </div>
              <div className='about-pickup__banner-right'>
                <PickupIcon></PickupIcon>
              </div>
            </div>
            <div className='about-pickup__banner-bottom-text'>
              * Маэстрелло Пик-ап Поинт
            </div>
          </div>

          <div className='about-pickup__container'>
            <div className='about-pickup__text-fullwidth'>
              <p>
                {t('page.pickupPoint.header')}
              </p>
            </div>
          </div>

          <div className='about-pickup__guide'>
            <div className='about-pickup__guide-left-column'>
              <TopIcon></TopIcon>
              <MidIcon></MidIcon>
              <BottomIcon></BottomIcon>
            </div>
            <div className='about-pickup__divider'></div>
            <div className='about-pickup__guide-right-column'>
              <div className='about-pickup__guide-stage'>
                <span className='base-text'>1. {t('page.pickupPoint.stage1')}</span>
              </div>
              <div className='about-pickup__guide-stage'>
                <span className='base-text'>2. {t('page.pickupPoint.stage2')}</span>
              </div>
              <div className='about-pickup__guide-stage'>
                <span className='base-text'>3. {t('page.pickupPoint.stage3')}</span>
              </div>
              <div className='about-pickup__guide-stage'>
                <span className='base-text'>4. {t('page.pickupPoint.stage4')}</span>
              </div>
              <div className='about-pickup__guide-stage'>
                <span className='base-text'>5. {t('page.pickupPoint.stage5')}</span>
              </div>
              <div className='about-pickup__guide-stage'>
                <span className='base-text'>6. {t('page.pickupPoint.stage6')}</span>
              </div>
            </div>
          </div>

          <div className='about-pickup__bottom-text-center'>
            <p className='base-text'>
              {t('page.pickupPoint.footer')}
            </p>
          </div>
        </div>
      </div>
      <div className='page-sheet--close '>
        <button className='effect' onClick={handleCloseClick}>
          <CloseIcon title='Закрыть'/> {t('page.close')}
        </button>
      </div>
    </div>
  );
}

export default AboutPickupPage;
