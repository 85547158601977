import { put, takeEvery, all, call } from 'redux-saga/effects';
import { useSelector } from 'react-redux';
import apiService from '../services';
import {CATEGORIES, GIFT_CATEGORIES} from '../utils';

/**
 * Constants
 * */
export const moduleName = 'dishes';
// const prefix = `${appName}/${moduleName}`;
const prefix = 'dishes';

export const GET_DISHES_REQUEST = `${prefix}/GET_DISHES_REQUEST`;
export const GET_DISHES_START = `${prefix}/GET_DISHES_START`;
export const GET_DISHES_SUCCESS = `${prefix}/GET_DISHES_SUCCESS`;
export const GET_DISHES_FAIL = `${prefix}/GET_DISHES_FAIL`;

/**
 * Reducer
 * */

const initialState = {
  loading: true,
  entities: [],
  giftEntities: []
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DISHES_START:
      return {...state, loading: true };
    case GET_DISHES_SUCCESS:
      return {...state, entities: payload.dishList, giftEntities: payload.gifDishList, loading: false };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const dishListSelector = (state) => state[moduleName].entities;
export const dishesLoadingSelector = (state) => state[moduleName].loading;

export const additionalDishesSelector = (state) => {
  return state[moduleName].entities.filter((dish => dish.category_id !== '1'));
}

export const valentineDishesSelector = (state) => {
  return state[moduleName].entities.filter((dish => dish.category_id === '12'));
}

export const dishGiftListSelector = (state) => state[moduleName].giftEntities;
export const desertGiftListSelector = (state) => {
  return state[moduleName].giftEntities.filter((dish => dish.category_id === '10'));
}
export const pizzaGiftListSelector = (state) => {
  return state[moduleName].giftEntities.filter((dish => dish.category_id === '11'));
}
/**
 * Custom Hooks
 * */

export const useDishObjects = () => {
  const dishes = useSelector(dishListSelector);
  const dishObj = {};
  dishes.forEach((entity) => {
    dishObj[entity.id] = entity
  })
  return dishObj;
}

/**
 * Action Creators
 * */

export const getDishes = () => ({
  type: GET_DISHES_REQUEST,
});

/**
 * Sagas
 * */

 export const getDishesSaga = function* () {
   try {
     yield put({
       type: GET_DISHES_START
     });

     const request = yield call(apiService.getDishes);
     const dishList = [];
     const alreadyAddedCategories = [];

     CATEGORIES.forEach((category) => {
       const { id, anchor } = category;
       let firstItemInCategory = false;

       if (!alreadyAddedCategories.includes(id)) {
         alreadyAddedCategories.push(id);
         firstItemInCategory = true;
       }

       request.data.products.forEach((product) => {
         if (product.category_id === id) {
           if (firstItemInCategory) {
             product.categoryAnchor = anchor;
             firstItemInCategory = false;
           }
           dishList.push(product)
         }
       })
     });

     const gifDishList = [];

     GIFT_CATEGORIES.forEach((category) => {
       const { id } = category;

       request.data.products.forEach((product) => {
         if (product.category_id === id) {
           gifDishList.push(product)
         }
       })
     });

     yield put({
       type: GET_DISHES_SUCCESS,
       payload: { dishList, gifDishList }
     });
   } catch (error) {
     // TODO: handle error
   }
 };

export const saga = function* () {
  yield all(
    [takeEvery(GET_DISHES_REQUEST, getDishesSaga)]
  );
};
