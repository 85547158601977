import React from "react";
import {
    getDateString,
    getItemsFromOrder,
    makeItemsArrayFromObject,
    makeOrdersItemsArrayWithCatagory
} from "../../utils";
import {ReactComponent as RepeatIcon} from "../../svg/repeat.svg";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useDishObjects} from "../../ducks/dishes";
import {useUserOrders} from "../../ducks/auth";
import {addDishesFromOrder} from "../../ducks/cart";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";

function AccountOrdersBlock() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userOrders = useUserOrders();
    const dishesListObject = useDishObjects();

    const lastThreeOrders = userOrders.slice(0, 3);

    const copyOrder = (data) => {
        const dishes = getItemsFromOrder(data.items, dishesListObject);

        dispatch(addDishesFromOrder(dishes));
        navigate('/cart');
    };

    return (
        <div className='account__orders'>
            {
                lastThreeOrders.map((order, i) => {
                    const { id, create_datetime, total, items } = order;
                    const dateStr = getDateString(create_datetime);
                    const itemsArray = makeItemsArrayFromObject(items);
                    const itemsToRender = makeOrdersItemsArrayWithCatagory(itemsArray.filter((item) => item.type === 'product'), dishesListObject);

                    return (
                        <div key={id} className='account__order--row'>
                            <div className='account__order-top'>
                                <div className='base-text account__order-top-date'>
                                    {+total} ₽ - {dateStr}
                                </div>
                                <div className='account__edit-info base-text'>
                                    <span onClick={() => copyOrder(order)} className='account__order-copy-btn effect3 no-flick'>
                                        <span className='account__order-copy-btn-icon'>
                                            <RepeatIcon />
                                        </span>
                                        {t('page.account.repeatOrder')}
                                    </span>
                                </div>
                            </div>

                            <div className='account__order--column-items'>
                                {
                                    itemsToRender.map((item, i) => {
                                        const { product_id, category_id } = item;
                                        let name = '';
                                        let name_en = '';
                                        let img = '';
                                        let weight = 0;
                                        if (product_id === '53') {
                                            name = 'Мини пицца Маргарита';
                                            name_en = 'Margherita';
                                            img = 'https://pizzamaestrello.com/img/menu/product_8.png';
                                            weight = 200;
                                        } else if (dishesListObject[product_id]) {
                                            name = dishesListObject[product_id].name;
                                            name_en = dishesListObject[product_id].name_en;

                                            if (name.length > 30) {
                                                name = name.substring(0, 30) + '...';
                                            }
                                            if (name_en.length > 30) {
                                                name_en = name_en.substring(0, 30) + '...';
                                            }

                                            img = `https://pizzamaestrello.com/img/menu/product_${product_id}.png`;
                                            weight = dishesListObject[product_id].features !== undefined? dishesListObject[product_id].features.weight:0;
                                        }

                                        const altImageLayout = category_id === '1' ||
                                            category_id === '2' ||
                                            category_id === '6' ||
                                            category_id === '11';

                                        const itemImageClassnames = classNames({
                                            'account__orders--item-image': !altImageLayout,
                                            'account__orders--item-image-alt': altImageLayout,
                                        });

                                        return (
                                            <div key={i} className='account__orders--item'>
                                                <div className={itemImageClassnames}>
                                                    <img src={img} height='auto' alt={`${name_en} / ${name}`} />
                                                </div>
                                                <div className='account__orders--item-name-wrapper'>
                                                    <span className='base-text account__orders--item-name'>
                                                        {
                                                            i18n.language === 'ru'? name : name_en
                                                        }
                                                    </span>
                                                    {
                                                        weight? <span className='base-text account__orders--item-weight'>{weight} {t('dishes.g')}</span> : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    )
                })
            }
        </div>
    );
}

export default AccountOrdersBlock;
