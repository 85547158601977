import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as MargheritaIcon } from '../../svg/promo-margherita-icon.svg';
import {toggleAboutPickupModal, orderSelector} from '../../ducks/order';
import { useTranslation } from "react-i18next";

function PromoBlockMargherita() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);
  const dispatch = useDispatch();
  const orderData = useSelector(orderSelector);

  const thirdBlockClassnames = classNames({
    'promo__block': true,
    'promo__block--touched': tapped
  });

  const handleClick = () => {
    dispatch(toggleAboutPickupModal(true));
  }

  return (
    <div className={thirdBlockClassnames}>
      <div className='promo__block-inner'>
        <div className='promo__block-first'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockMargherita.front_title0')}<br/>
              {t('promo.blockMargherita.front_title1')}<br/>
              {t('promo.blockMargherita.front_title2')}
            </div>
            <div className='promo__block-text-small promo__block-text-margherita'>
              {t('promo.blockMargherita.back_subtitle0')}<br/>
              {t('promo.blockMargherita.back_subtitle1')}<br/>
              {t('promo.blockMargherita.back_subtitle2')}
            </div>
          </div>
          <div className='promo__block-icon promo__block-icon-margherita'>
            <MargheritaIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockMargherita;
