import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

function InputBlock(props) {
    const [field, meta] = useField(props);
    const validationFailed = !!meta.touched && !!meta.error;

    const inputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--grey': true,
        'input-block__input--error': validationFailed,
    });

    const errorClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': validationFailed,
    });

    return (
        <div className='input-block'>
            <div className='input-block__label'>
                <label htmlFor={props.name}>{props.label}</label>
            </div>
            <div className={inputClassnames}>
                <input
                    id={props.name}
                    {...props}
                    {...field}
                />
            </div>
            <span className={errorClassnames}>{meta.error}</span>
        </div>
    )
}

export default InputBlock;
