import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDishes, dishListSelector, dishesLoadingSelector } from './../../ducks/dishes';
import DishListItem from './dish-list-item';

function DishList({ selectDishFunc }) {
  const dishes = useSelector(dishListSelector);
  const dishesLoading = useSelector(dishesLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDishes());
  }, [dispatch]);

  if (dishesLoading) return null;
  
  return (
    <div className='dishes'>
      {
        dishes.map((dish, i) => <DishListItem key={dish.id} dish={dish} selectDishFunc={selectDishFunc} />)
      }
    </div>
  );
}

export default DishList;
