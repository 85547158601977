import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { cartSelector, priceSelector } from './../../ducks/cart';
import { useAuthorized } from '../../ducks/auth';
import { ReactComponent as UserIcon } from '../../svg/user.svg';
import { ReactComponent as CloseSmallIcon } from '../../svg/right-36.svg';
import { ReactComponent as CartWhiteIcon } from '../../svg/cart-white.svg';
import { ReactComponent as ExternalIcon } from '../../svg/external.svg';
import amplitude from 'amplitude-js';
import { useTranslation } from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router";

function MobileNav({ handleClickMenu, closeDish }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const cart = useSelector(cartSelector);
  const totalPrice = useSelector(priceSelector);
  const isAuthorized = useAuthorized();

  const [selectedLanguage, setLanguage] = useState('ru');

  const handleClickCatering = (e) => {
    e.preventDefault();
    const href = e.target.href;
    amplitude.getInstance().logEvent('button_main_catering', null, function() {
      window.open(href, '_blank');
    });
  }

  const handleClickLanguage = (e) => {
    e.preventDefault();
    if (i18n.language === 'en') {
      i18n.changeLanguage('ru');
    } else {
      i18n.changeLanguage('en');
    }
    setLanguage(i18n.language);
    amplitude.getInstance().logEvent('button_main_language', i18n.language);
  }

  const handleOpenCart = () => {
    closeDish();
    setTimeout(() => {
      navigate('/cart');
    }, 200);
  }

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n]);

  return (
    <nav className='mobile-nav'>
      <ul className='mobile-nav__list'>
        <li className='mobile-nav__menu-item'>
          <Link to={ isAuthorized? '/account' : '/login' } state={{ background: location }} className='mobile-nav__menu-link mobile-nav__menu-link--account effect3'>
            <span>{isAuthorized ?  t('common.account') : t('common.login')}</span>
            <div className='icon-wrapper-32'>
              <UserIcon title={isAuthorized ? t('common.account') : t('common.login')} />
            </div>
          </Link>
        </li>
        <li className='mobile-nav__menu-item'>
          <Link to='/' className='mobile-nav__menu-link effect3'>
            <span>{t('common.delivery_menu')}</span> <CloseSmallIcon title='Перейти' />
          </Link>
        </li>
        <li className='mobile-nav__menu-item'>
          <Link to='/about' className='mobile-nav__menu-link effect3'>
            <span>{t('common.aboutUs')}</span> <CloseSmallIcon title='Перейти' />
          </Link>
        </li>
        <li className='mobile-nav__menu-item'>
          <Link to='/delivery' className='mobile-nav__menu-link effect3'>
            <span>{t('common.delivery')}</span> <CloseSmallIcon title='Перейти' />
          </Link>
        </li>
        <li className='mobile-nav__menu-item'>
          <a href='https://catery.ru/caterer/maestrello' onClick={handleClickCatering} className='mobile-nav__menu-link effect3' target='_blank' rel='noreferrer'>
            <span>{t('common.catering')}</span> <ExternalIcon title='Перейти' />
          </a>
        </li>
        <li className='mobile-nav__menu-item'>
          <Link to='/legal' className='mobile-nav__menu-link effect3'>
            <span>{t('common.legal')}</span> <CloseSmallIcon title='Перейти' />
          </Link>
        </li>
        <li className='mobile-nav__menu-item'>
          <button onClick={handleClickLanguage} className='mobile-nav__menu-link effect3'>
            <span>{t('common.language')}</span>
            {
              selectedLanguage === 'en' ? <img src="/img/icon-en-v2.png" alt="EN" className='mobile-nav__menu-link--lang'/> : <img src="/img/icon-ru-v2.png" alt="RU" className='mobile-nav__menu-link--lang'/>
            }
          </button>
        </li>
        {
          cart.length ?
          <li className='mobile-nav__menu-item mobile-nav__menu-item--last'>
            <button onClick={ handleOpenCart } className='cart-link effect2'>
              <CartWhiteIcon className='cart-link__icon'/> <span>{t('common.cart')}</span> <span className='cart-link__price'>{totalPrice} ₽</span>
            </button>
          </li> :
          <li className='mobile-nav__menu-item mobile-nav__menu-item--last'>
            <a onClick={ handleClickMenu } href='/' className='cart-link effect2'>
              {t('common.delivery_menu')}
            </a>
          </li>
        }
      </ul>
    </nav>
  );
}

export default MobileNav;
