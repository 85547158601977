import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { checkDishInCart } from '../../utils';
import { useDispatch, useSelector} from "react-redux";
import { additionalDishesSelector } from "../../ducks/dishes";
import { addDish, cartSelector, removeDish } from "../../ducks/cart";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckMark } from '../../svg/check-mark.svg';

function ExtraDishBlock() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const cartItems = useSelector(cartSelector);
    const additionalDishes = useSelector(additionalDishesSelector);

    const handleExtraDish = (d) => {
        const dishInCart = checkDishInCart(cartItems, d.id);
        if (dishInCart) {
            dispatch(removeDish(d));
        } else {
            dispatch(addDish({...d, extra: true }));
        }
    }

    return (
        <div className='cart__col mt-2 cart__col-item-full-tablet'>
            <div className='cart__col-item cart__col-item-full cart__extra'>
                <div className='cart__col-item--header'>
                    <span className='cart__col--text-header'>{t('page.cart.addToOrder')}</span>
                </div>
                <div className='mt-2'>
                    <div className='cart__extra-list'>
                        {
                            additionalDishes.map((extra, i) => {
                                const {id, name, name_en, price, features} = extra;
                                const weight = features ? features.weight : '';
                                const volume = features ? features.volume : '';

                                // const img = 'cart-extra-dish-1.png';
                                const img = `https://pizzamaestrello.com/img/menu/product_${id}.png`;
                                const checked = checkDishInCart(cartItems, id);

                                const iconClassnames = classNames({
                                    'dish__addition-check': true,
                                    'dish__addition-check--visible': checked
                                });

                                const itemClassnames = classNames({
                                    'cart__addition': true,
                                    'effect3': true,
                                    'cart__addition--selected': checked
                                });

                                return (
                                    <div key={i} className={itemClassnames}
                                         onClick={() => handleExtraDish({...extra, amount: 1})}>
                                        <div className='cart__addition-top'>
                                            <div className='cart__addition-img'>
                                                <div className={iconClassnames}>
                                                    { checked ? <CheckMark /> : null }
                                                </div>
                                                <img src={img} width='50' alt={name}/>
                                            </div>
                                        </div>
                                        <div className='cart__addition-name'>
                                          <span>
                                            {
                                                i18n.language === 'en' ? name_en : name
                                            }
                                          </span>
                                            <span>{price} ₽</span>
                                        </div>
                                        <div className='cart__addition-params'>
                                            {
                                                weight ?
                                                    <div className='cart__addition-weight'>
                                                        {weight} {t('dishes.g')}
                                                    </div> : null
                                            }
                                            {
                                                volume ?
                                                    <div className='cart__addition-weight'>
                                                        {volume} {t('dishes.ml')}
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExtraDishBlock;
