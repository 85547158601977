import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  resetPasswordByHash,
  useAuthorized,
  useUserErrorMessage,
  useUserLoading,
  useUserError, useUserSuccess, resetUserErrors, useChannel, useAsset, resetRecove
} from '../../ducks/auth';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { useTranslation } from "react-i18next";
import { Formik } from 'formik';
import InputBlock from "../common/input-block";
import { useNavigate, useOutletContext } from "react-router";
import { useLocation, useParams } from "react-router-dom";

function NewPasswordFormPage()  {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [hideChild, setHideChild]= useOutletContext();

  const isAuthorized = useAuthorized();
  const userLoading = useUserLoading();
  const userError = useUserError();
  const userSuccess = useUserSuccess();
  const userErrorMessage = useUserErrorMessage();
  const recoverChannel = useChannel();
  const recoverAsset = useAsset();

  const [showModal, setShowModal] = useState(false);

  /* Referral program */
  let { hash } = useParams();

  const formClassnames = classNames({
    'form': true,
    'form--login': true,
    'form--login-visible': showModal,
    'form--register': true
  });

  const handleSubmit = (values) => {
    if (recoverChannel === 'sms' && recoverAsset) {
      dispatch(resetPasswordByHash({
        locale: i18n.language,
        code: values.recoverCode,
        asset: recoverAsset,
        password: values.recoverPassword
      }));
    } else {
      dispatch(resetPasswordByHash({
        locale: i18n.language,
        hash: hash,
        password: values.recoverPassword
      }));
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetRecove());
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    dispatch(resetRecove());
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  const handleLogin = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/login');
    }, 200)
  }

  useEffect(() => {
    if (location) {
      dispatch(resetUserErrors());
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (isAuthorized) {
      setShowModal(false);
      setTimeout(()=> {
        navigate('/');
      }, 200);
    }
  }, [isAuthorized]);


  useEffect(() => {
    if (userSuccess) {
      setTimeout(()=> {
        setShowModal(false);
        setTimeout(()=> {
          navigate('/login');
        }, 200);
      }, 1000);
    }
  }, [userSuccess]);

  const validate = values => {
    const errors = {};

    if (!values.recoverPassword) {
      errors.recoverPassword = t('formValidation.required');
    }

    if  (!/^(?=.{8,})/i.test(values.recoverPassword)) {
      errors.recoverPassword = t('formValidation.passwordLong');
    }

    if (!values.recoverPasswordCopy) {
      errors.recoverPasswordCopy = t('formValidation.required');
    } else if (values.recoverPassword !== values.recoverPasswordCopy) {
      errors.recoverPasswordCopy = t('formValidation.notExact');
    }

    if (recoverChannel === 'sms' && !values.recoverCode) {
      errors.recoverCode = t('formValidation.required');
    }

    return errors;
  }

  const initialValues = {
    recoverCode: '',
    recoverPassword: '',
    recoverPasswordCopy: '',
  };

  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': userError
  });

  return (
      <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          validateOnMount
          enableReinitialize>
        {
          props => {
            const { isValid } = props;
            const submitDisabled = !isValid;

            return (
                <form className={formClassnames} onSubmit={props.handleSubmit}>
                  <div className='form__form form__form--register'>
                    <div className='form__header form__header--register'>
                      <button className='form__header-left base-header effect' type='button'
                              onClick={userLoading ? null : handleBack}>
                        <ArrowLeft/> {t('page.auth.newPassword')}
                      </button>
                    </div>
                    <div className='form__inputs'>

                      {
                        recoverChannel === 'sms'?
                            <InputBlock
                                name='recoverCode'
                                type='text'
                                label={`${t('page.auth.smsCode')} *`}
                            /> : null
                      }


                      <InputBlock
                          name='recoverPassword'
                          type='password'
                          autocomplete='new-password'
                          label={`${t('page.auth.newPassword')} *`}
                      />

                      <InputBlock
                          name='recoverPasswordCopy'
                          type='password'
                          label={`${t('page.auth.passwordCopy')} *`}
                      />

                    </div>
                    {
                      userSuccess?
                          <div className='base-text mt-2'>
                            {t('page.auth.changePasswordSuccess')}
                          </div> : null
                    }
                    <div className={formErrorClassnames}>
                      {
                        userError ? userErrorMessage : null
                      }
                    </div>
                    <div className='form__submit form__login-bottom form__submit--register'>
                      <button className='button effect' type='submit'
                              disabled={userLoading || submitDisabled}>{t('page.auth.changePassword')}</button>
                    </div>
                  </div>
                  <div className='form__close'>
                    <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal}>
                      <CloseIcon title='Закрыть'/> {t('page.close')}
                    </button>
                  </div>
                </form>
            )
          }
        }
      </Formik>
  )
}

export default NewPasswordFormPage;
