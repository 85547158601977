import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  addDish,
  cartSelector,
  addAddition,
  removeAddition,
  addDishWithAddition,
  increaseDish, decreaseDish
} from './../../ducks/cart';
import { getAdditions, additionsListSelector, additionsLoadingSelector } from './../../ducks/additions';
import { ReactComponent as CardBg } from '../../svg/card-bg.svg';
import { ReactComponent as CardBgSmall } from '../../svg/card-bg-small.svg';
import { ReactComponent as PlusIcon } from '../../svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../svg/minus.svg';
import { ReactComponent as CheckMark } from '../../svg/check-mark.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as NewIcon } from '../../svg/new.svg';
import { ReactComponent as SantaIcon } from '../../svg/santa.svg';
import { ReactComponent as CloseSmallIcon } from "../../svg/close-36.svg";
import { FEATURE_NAMES, FEATURE_NAMES_EN, checkDishInCart, additionIsSelected } from '../../utils';
import { useTranslation } from "react-i18next";

function Dish({ dish, closeDish, selectDishFunc }) {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const cart = useSelector(cartSelector);
  const additions = useSelector(additionsListSelector);
  const additionsLoading = useSelector(additionsLoadingSelector);

  const [is_new, setNew] = useState(-1);
  const [addedPrice, setAddedPrice] = useState(0);

  let { id, name, name_en, summary, summary_en, price, toppings, type_id, features, cart_item } = dish;

  const feature = features ? features.feature : '';
  const allergens = features ? features.allergens : '';
  const calorie = features ? features.calorie : '';
  const weight = features ? features.weight : '';
  const volume = features ? features.volume : '';
  const selectedAmount = checkDishInCart(cart, cart_item);
  const protein = features ? features.protein : '';
  const fat = features ? features.fat : '';
  const carbohydrates = features ? features.carbohydrates : '';
  const img = id ? `https://pizzamaestrello.com/img/menu/product_${id}.png` : 'https://pizzamaestrello.com/img/media/card-empty-content--desktop.png';

  const nutritional = () => {
    let string = calorie? calorie + " " + t('dishes.kcal') + " " : null;
    string += protein? t('dishes.protein') + protein + " " + t('dishes.g') + " " : null;
    string += fat? t('dishes.fat') + fat + " " + t('dishes.g') + " ": null;
    string += carbohydrates? t('dishes.carbohydrates') + carbohydrates + " " + t('dishes.g') + " " : null;
    return string;
  };

  const showNutritional = protein || fat || carbohydrates;

  const dishEl = useRef(null);

  const handleAdd = (d) => {
    if (!selectedAmount) {
      dispatch(addDish(d));
      setNew(cart.length);
    } else {
      dispatch(increaseDish(cart_item));
    }
  };

  const handleRemove = () => {
    dispatch(decreaseDish(cart_item));
  };

  const handleAddDishWithAddition = (d) => {
    dispatch(addDishWithAddition(d));
    if (cart_item === undefined) {
      setNew(cart.length);
    }
  };
  const handleAddAddition = (d) => {
    dispatch(addAddition(d));
  };
  const handleRemoveAddition = (d) => {
    dispatch(removeAddition(d));
  };

  // useEffect(() => {
  //   if (dish.cart_item === undefined) {
  //     setNew(-1);
  //   }
  // }, [dish]);

  useEffect(() => {
    if (cart.length && cart_item === undefined && is_new !== -1) {
      selectDishFunc(cart[is_new], is_new);
    } else {
      setNew(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dish, cart, cart_item, is_new]);


  useEffect(() => {
    if (cart.length && cart_item !== undefined) {
      const calcAddicionsCost = (cart_id) => {
        if (cart[cart_id] !== undefined && cart[cart_id].additions !== undefined) {
          if (cart[cart_id].additions.length) {
            let total = cart[cart_id].additions.reduce((p, c) => p + c.additionPrice, 0);
            setAddedPrice(total);
          } else {
            setAddedPrice(0);
          }
        } else {
          setAddedPrice(0);
        }
      }

      calcAddicionsCost(cart_item);
    }
  }, [cart, cart_item]);

  useEffect(() => {
    if (toppings) {
      dispatch(getAdditions(type_id));
    }
  }, [toppings, type_id, dispatch]);

  useEffect(() => {
    if (dish) {
      dishEl.current.scrollTop = 0;
    }
    if (cart_item === undefined) {
      setAddedPrice(0);
    }
  }, [dish, cart_item]);

  const handleChange = (e) => {
    const additionId = e.target.value;
    let additionPrice = 0;
    let additionName = '';
    let additionNameEn = '';

    additions.forEach((t) => {
      if (t.variant_id === additionId) {
        additionPrice = +t.price;
        additionName = t.name;
        additionNameEn = t.name_en
      }
    });

    if (selectedAmount) {
      const additionAdded = additionIsSelected(cart, cart_item, additionId);

      if (additionAdded) {
        handleRemoveAddition({ cart_item, additionId });
      } else {
        handleAddAddition({ cart_item, additionId, additionPrice, additionName, additionNameEn });
      }
    } else {
      handleAddDishWithAddition({
        ...dish,
        amount: 1,
        additions: [{additionId, additionPrice, additionName}]
      });
    }
  }

  const dishClassnames = classNames({
    'dish': true,
    'dish--visible': !dish.empty
  });

  const featureClass = classNames({
    'dish__feature': true,
    'dish__feature--spicy': feature === 'spicy',
    'dish__feature--vegetarian': feature === 'vegetarian',
    'dish__feature--vegan': feature === 'vegan',
    'dish__feature--fish': feature === 'fish',
    'dish__feature--new': feature === 'new',
    'dish__feature--santa': feature === 'santa',
    'card__feature--smstretching': feature === 'smstretching',
    'card__feature--windpower': feature === 'windpower',
    'card__feature--back-to-school': feature === 'back-to-school'
  });

  const cardBgClassName = classNames({
    'dish__left': true,
    'dish__left--selected': selectedAmount
  });

  const addClassnames = classNames({
    'dish__add': true,
    'dish__add--activated': selectedAmount
  });

  const minusClassnames = classNames({
    'dish__add-minus': true,
    'dish__add-minus--visible': selectedAmount,
    'no-flick': true
  });

  const amountClassnames = classNames({
    'dish__add-amount': true,
    'dish__add-amount--visible': selectedAmount
  });

  const featureIcon = () => {
    if (feature === 'new') {
      return <span className={featureClass}><NewIcon/></span>
    } else if (feature === 'santa') {
      return <span className={featureClass}><SantaIcon/></span>
    } else if (feature === 'smstretching') {
      return <span className={featureClass}>x SMSTRETCHING</span>
    } else if (feature === 'windpower') {
      return <span className={featureClass}>СИЛА >>> ВЕТРА</span>
    } else if (feature === 'back-to-school') {
      return <span className={featureClass}>Back to school</span>
    } else {
      return i18n.language === 'en' ? <span className={featureClass}>{FEATURE_NAMES_EN[feature]}</span> : <span className={featureClass}>{FEATURE_NAMES[feature]}</span>
    }
  }

  return (
    <div className={dishClassnames} ref={dishEl}>
      <div className='dish__before_top'>
        <div className='dish__mobile-close'>
          <button className='effect' onClick={closeDish}>
            <CloseSmallIcon title='Закрыть'/>
          </button>
        </div>
      </div>
      <div className='dish__top'>
        <h3 className='dish__name'>
          {
            i18n.language === 'en'? name_en : name
          }
        </h3>
        <div className='dish__price'>
          {price + addedPrice} ₽
        </div>
      </div>
      <div className='dish__info'>
        <div className={cardBgClassName}>
          <img className='dish__content-img' src={img} width='170' alt={`${name} / ${name_en}`} />
          <img className='dish__empty-img' src='https://pizzamaestrello.com/img/media/card-empty-content--desktop.png' width='170' alt='.' />
          <CardBg className='dish__bg'/>
          <div className={addClassnames} onClick={selectedAmount ? null : () => handleAdd({ ...dish, amount: 1 })}>
            <button className={minusClassnames} onClick={() => handleRemove()}>
              <MinusIcon title='Удалить' />
            </button>
            {
              !selectedAmount ? null : <span className={amountClassnames}>
                {selectedAmount}
              </span>
            }
            <span className='dish__add-in-cart'>
              {t('dishes.addToCart')}
            </span>
            <button className='dish__add-plus no-flick' onClick={selectedAmount ? () => handleAdd({ ...dish, amount: 1 }) : null}>
              <PlusIcon title='Добавить' />
            </button>
          </div>
          {
            !feature ? null : featureIcon()
          }
        </div>
        <div className='dish__right'>
          <h3 className='dish__name dish__name--hidden-on-mobile'>
            {
              i18n.language === 'en' ? name_en : name
            }
          </h3>
          <div className='dish__ingridients'>
            {
              i18n.language === 'en' ? summary_en : summary
            }
          </div>
          <div className='dish__price dish__price--hidden-on-mobile'>
            {price + addedPrice} ₽
          </div>
          <div className='dish__params'>
            {
              weight ?
              <div>
                {t('dishes.weight')}: {weight} {t('dishes.g')}
              </div> : null
            }
            {
              volume ?
              <div>
                {volume} {t('dishes.ml')}
              </div> : null
            }
            {
              showNutritional? nutritional() : null
            }
            {
              allergens ?
                  <div>
                    {t('dishes.allergens')} {allergens}
                  </div> : null
            }
          </div>
        </div>
      </div>
      {
        !additions.length || !toppings || additionsLoading ? null :
        <div className='dish__additional'>
          <div className='dish__additional-header'>
            {t('dishes.addToPizza')}
          </div>
          <div className='dish__additions'>
            {
              additions.map((v, i) => {
                const { name, name_en, price, description } = v;
                const additionId = v.variant_id;
                const img = `https://pizzamaestrello.com/img/toppings/topping_${additionId}.png`;
                const checked = additionIsSelected(cart, cart_item, additionId);

                const iconClassnames = classNames({
                  'dish__addition-check': true,
                  'dish__addition-check--visible': checked
                });

                return (
                  <div key={i} className='dish__addition'>
                    <input type='checkbox' id={additionId} value={additionId} onChange={handleChange} checked={checked} />
                    <label htmlFor={additionId}>
                      <div className='dish__addition-top'>
                        <div className='dish__addition-img'>
                          <img src={img} width='50' alt={name} />
                        </div>
                        <div className={iconClassnames}>
                          { checked ? <CheckMark /> : null }
                        </div>
                      </div>
                      <div className='dish__addition-name'>
                        {
                          i18n.language === 'en' ? name_en : name
                        }
                      </div>
                      <div className='dish__addition-params'>
                        <div className='dish__addition-weight'>
                          {
                            description ?
                              <span>{description} {t('dishes.g')}</span> : null
                          }
                        </div>
                        <div className='dish__addition-price'>
                          +{+price}  ₽
                        </div>
                      </div>
                      <CardBgSmall className='dish__addition-bg'/>
                    </label>
                  </div>
                )
            })}
          </div>
        </div>
      }
      <div className='dish__close '>
        <button className='effect' onClick={closeDish}>
          <CloseIcon title='Закрыть'/> {t('dishes.close')}
        </button>
      </div>
    </div>
  );
}

export default Dish;
