import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { cartSelector, priceSelector, loadingSelector } from './../../ducks/cart';
import { ReactComponent as UserIcon } from '../../svg/user.svg';
import { ReactComponent as CartWhiteIcon } from '../../svg/cart-white.svg';
import classNames from 'classnames';
import { useAuthorized } from '../../ducks/auth';
import amplitude from 'amplitude-js';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

function DesktopNav({ closeDish }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isAuthorized = useAuthorized();
  const cartArray = useSelector(cartSelector);
  const totalPrice = useSelector(priceSelector);
  const loading = useSelector(loadingSelector);
  const cartIsEmpty = !cartArray.length;

  const [selectedLanguage, setLanguage] = useState('ru');

  const cartClassname = classNames({
    'desktop-nav__cart': true,
    'desktop-nav__cart--is-not-empty': !cartIsEmpty,
    'desktop-nav__cart--loading': loading
  });

  const menuLinkClassnames = classNames({
    'desktop-nav__link': true,
    'desktop-nav__link--selected': location.pathname === '/',
    'effect': true
  });

  const aboutLinkClassnames = classNames({
    'desktop-nav__link': true,
    'desktop-nav__link--selected': location.pathname === '/about',
    'effect': true
  });

  const legalLinkClassnames = classNames({
    'desktop-nav__link': true,
    'desktop-nav__link--selected': location.pathname === '/legal',
    'effect': true
  });

  const deliveryLinkClassnames = classNames({
    'desktop-nav__link': true,
    'desktop-nav__link--selected': location.pathname === '/delivery',
    'effect': true
  });

  const handleClickCatering = (e) => {
    e.preventDefault();
    const href = e.target.href;
    amplitude.getInstance().logEvent('button_main_catering', null, function() {
      window.open(href, '_blank');
    });
  }

  const handleClickLanguage = (e) => {
    e.preventDefault();
    if (i18n.language === 'en') {
      i18n.changeLanguage('ru');
    } else {
      i18n.changeLanguage('en');
    }
    setLanguage(i18n.language);
    amplitude.getInstance().logEvent('button_main_language', i18n.language);
  }

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n]);

  const handleOpenCart = () => {
    closeDish();
    setTimeout(() => {
      navigate('/cart');
    }, 200);
  }

  return (
    <ul className='desktop-nav'>
      <li className='desktop-nav__item'>
        <Link to='/' className={menuLinkClassnames}>
          {t('common.delivery_menu')}
        </Link>
      </li>
      <li className='desktop-nav__item'>
        <Link to='/about' className={aboutLinkClassnames}>
          {t('common.aboutUs')}
        </Link>
      </li>
      <li className='desktop-nav__item'>
        <Link to='/delivery' className={deliveryLinkClassnames}>
          {t('common.delivery')}
        </Link>
      </li>
      <li className='desktop-nav__item'>
        <a href='https://catery.ru/caterer/maestrello' onClick={handleClickCatering} className='desktop-nav__link desktop-nav__link--catering effect' target='_blank' rel='noreferrer'>
          {t('common.catering')}
        </a>
      </li>
      <li className='desktop-nav__item'>
        <Link to='/legal' className={legalLinkClassnames}>
          {t('common.legal')}
        </Link>
      </li>
      <li className='desktop-nav__item'>
        <button onClick={handleClickLanguage} className='desktop-nav__link desktop-nav__link--account effect no-flick'>
          {
            selectedLanguage === 'en' ? <img src="/img/icon-en-v2.png" alt="EN" className='desktop-nav__link--lang'/> : <img src="/img/icon-ru-v2.png" alt="RU" className='desktop-nav__link--lang'/>
          }
        </button>
      </li>
      <li className='desktop-nav__item'>
        <Link to={ isAuthorized? '/account' : '/login' } state={{ background: location }} className='desktop-nav__link desktop-nav__link--account effect no-flick'>
          <UserIcon title={isAuthorized ? t('common.account') : t('common.login')} />
        </Link>
      </li>
      <li className={cartClassname}>
        {
          <button className='effect no-flick' onClick={handleOpenCart}>
            <span className='desktop-nav__loader'>
              <span className='loader'>{t('common.loading')}</span>
            </span>
            <CartWhiteIcon title='Корзина' />
            {
              cartIsEmpty ? null : <span className='desktop-nav__price'>{totalPrice} ₽ · {t('common.make_order')}</span>
            }
          </button>
        }
      </li>
    </ul>
  );
}

export default DesktopNav;
