import React, { useEffect } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

function InputBlockTime(props) {
  const [field, meta] = useField(props);
  const validationFailed = !!meta.touched && !!meta.error;
  let timeMask = 'gh:lm';

  let timeFormatChars = {
    'g': '[1-2]',
    'h': '[0-9]',
    'l': '[0-5]',
    'm': '[0-9]'
  };

  let beforeTimeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;

    if (value.startsWith('2')) {
      timeFormatChars['h'] = '[0-1]';
    }

    return { value, selection: newState.selection };
  };

  const inputClassnames = classNames({
    'input-block__input': true,
    'input-block__input--grey': true,
    'input-block__input--error': validationFailed,
  });
  const errorClassnames = classNames({
    'input-block__error': true,
    'input-block__error--visible': validationFailed,
  });

  // return (
  //   <div className='input-block'>
  //     <div className='input-block__label'>
  //       <label htmlFor={props.name}>{props.label}</label>
  //     </div>
  //     <div className={inputClassnames}>
  //       <input
  //         id={props.name}
  //         {...props}
  //         {...field}
  //       />
  //     </div>
  //     <span className={errorClassnames}>{meta.error}</span>
  //   </div>
  // )

  return (
    <div className='input-block'>
      <div className='input-block__label'>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
      <div className={inputClassnames}>
        <InputMask
          {...props}
          {...field}
          mask={timeMask}
          alwaysShowMask={false}
          formatChars={timeFormatChars}
          beforeMaskedValueChange={beforeTimeMaskedValueChange}
          placeholder='12:00'
        />
        {/*<input*/}
        {/*  id={props.name}*/}
        {/*  {...props}*/}
        {/*  {...field}*/}
        {/*  type='tel'*/}
        {/*/>*/}
      </div>
      <span className={errorClassnames}>{meta.error}</span>
    </div>
  )
}

export default InputBlockTime;
