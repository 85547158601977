import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from '../../svg/404.svg';
import { useTranslation } from "react-i18next";

function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <div className='not-found'>
      <div className='not-found__inner'>
        <div className='not-found__icon'>
          <NotFoundIcon />
        </div>
        <div className='not-found__text'>
          {t('common.sww404')}
        </div>
        <div className='not-found__link'>
          <NavLink to='/'>
            {t('common.menuLink')}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
