import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../svg/copy.svg';
import { useTranslation } from "react-i18next";
import { useUserInfo } from "../../ducks/auth";

function AccountOfferBlockFreePoints() {
    const { t, i18n } = useTranslation();
    const userInfo = useUserInfo();
    const [isCopied, setIsCopied] = useState(false);

    const copyTextToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const linkText = () => {
        return isCopied? t('offer.link_copied') : t('offer.link');
    }

    const handleCopyClick = async () => {
        try {
            const text = i18n.language === 'ru'? 'Вступайте в программу лояльности Maestrello – дарим вам 300 приветственных баллов!' : 'Join the Maestrello Loyalty Program - we give you 300 welcome points!'
            await copyTextToClipboard(`${text} https://pizzamaestrello.com/register/${userInfo.id}`);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className='offer__container'>
            <div className='offer__col offer__col-text'>
                <div className='offer__col-label'>{t('offer.label')}</div>
                <div className='offer__col-header-title'>{t('offer.header')}</div>
                <div className='offer__col-header-description'>{t('offer.description')}</div>
                <div className='offer__col-header-link effect' onClick={handleCopyClick}>
                    <CopyIcon/> {linkText()}
                </div>
            </div>
            <div className='offer__col offer__col-image'>
                <div className='offer__col-image-link' onClick={handleCopyClick}>
                    {linkText()}
                </div>
            </div>
        </div>
    );
}

export default AccountOfferBlockFreePoints;
