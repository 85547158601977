import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import { addDish, removeDish, cartSelector } from './../../ducks/cart';
import { ReactComponent as CardBg } from '../../svg/card-bg.svg';
import { ReactComponent as PlusIcon } from '../../svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../svg/minus.svg';
import { ReactComponent as NewIcon } from '../../svg/new.svg';
import { ReactComponent as SantaIcon } from '../../svg/santa.svg';
import { FEATURE_NAMES, FEATURE_NAMES_EN } from '../../utils';
import { useTranslation } from "react-i18next";

function DishListItem({ dish, selectDishFunc }) {
  const { t, i18n } = useTranslation();

  const cart = useSelector(cartSelector);
  const dispatch = useDispatch();

  const handleAdd = (dish) => {
    dispatch(addDish(dish));
  };

  const handleRemove = (dish) => {
    dispatch(removeDish(dish));
  };

  function isSelected(cart, id) {
    let amount = 0;
    cart.forEach((item) => {
      if (item.id === id) amount = item.amount;
    })
    return amount;
  }

  const { id, name, name_en, summary, summary_en, price, categoryAnchor, features, count } = dish;
  const feature = features ? features.feature : '';
  const allergens = features ? features.allergens : '';
  const weight = features ? features.weight : '';
  const volume = features ? features.volume : '';
  const calorie = features ? features.calorie : '';
  const protein = features ? features.protein : '';
  const fat = features ? features.fat : '';
  const carbohydrates = features ? features.carbohydrates : '';
  const img = `https://pizzamaestrello.com/img/menu/product_${id}.png`;

  const nutritional = () => {
    let string = calorie? calorie + " " + t('dishes.kcal') + " " : null;
    string += protein? t('dishes.protein') + protein + " " + t('dishes.g') + " " : null;
    string += fat? t('dishes.fat') + fat + " " + t('dishes.g') + " ": null;
    string += carbohydrates? t('dishes.carbohydrates') + carbohydrates + " " + t('dishes.g') + " " : null;
    return string;
  };

  const showNutritional = protein || fat || carbohydrates;

  const featureClass = classNames({
    'card__feature': true,
    'card__feature--spicy': feature === 'spicy',
    'card__feature--vegetarian': feature === 'vegetarian',
    'card__feature--vegan': feature === 'vegan',
    'card__feature--fish': feature === 'fish',
    'dish__feature--new': feature === 'new',
    'dish__feature--santa': feature === 'santa',
    'card__feature--smstretching': feature === 'smstretching',
    'card__feature--windpower': feature === 'windpower',
    'card__feature--back-to-school': feature === 'back-to-school',
    'card__feature--sold-out': parseInt(count) === 0
  });

  const selectedAmount = false; //isSelected(cart, id);

  const selectAvailableDish = (dish) => {
    if (parseInt(count) !== 0)
      selectDishFunc(dish);
  }

  const addAvailableDish = () => {
    if (parseInt(count) !== 0)
    {
      return (
          <div className={addClassnames} onClick={() => selectDishFunc(dish)}>
              <span className='card__add-in-cart'>
                {t('dishes.addToCart')}
              </span>
            <button className='card__add-plus no-flick' onClick={() => selectDishFunc(dish)}>
              <PlusIcon title='Добавить' />
            </button>
          </div>
      )
    }
  }

  const cardBgClassName = classNames({
    'card__left': true,
    'card__left--selected': selectedAmount
  });

  const addClassnames = classNames({
    'card__add': true,
    'card__add--activated': selectedAmount
  })

  const minusClassnames = classNames({
    'card__add-minus': true,
    'card__add-minus--visible': selectedAmount,
    'no-flick': true
  });

  const amountClassnames = classNames({
    'card__add-amount': true,
    'card__add-amount--visible': selectedAmount
  });

  const featureIcon = () => {
    if (parseInt(count) === 0) {
      return <span className={featureClass}>Sold out!</span>
    } else if (feature === 'new') {
      return <span className={featureClass}><NewIcon/></span>
    } else if (feature === 'santa') {
      return <span className={featureClass}><SantaIcon/></span>
    } else if (feature === 'smstretching') {
      return <span className={featureClass}>x SMSTRETCHING</span>
    } else if (feature === 'windpower') {
      return <span className={featureClass}>СИЛА >>> ВЕТРА</span>
    } else if (feature === 'back-to-school') {
      return <span className={featureClass}>Back to school</span>
    } else {
      return i18n.language === 'en' ? <span className={featureClass}>{FEATURE_NAMES_EN[feature]}</span> : <span className={featureClass}>{FEATURE_NAMES[feature]}</span>
    }
  }

  return (
      <div key={id} className='card' id={categoryAnchor}>
        <div className='card__top'>
          <h3 className='card__name'>
            {name_en} <span className='card__separator'>/</span>
            <div className='card__nameru'>{name}</div>
          </h3>
          <div className='card__price'>
            {price} ₽
          </div>
        </div>
        <div className='card__info'>
          <div className={cardBgClassName}>
            <LazyLoad once offset={100}>
              <img onClick={() => selectAvailableDish(dish)} className='card__content-img' src={img} width='170' alt={`${name} / ${name_en}`} />
            </LazyLoad>
            <CardBg className='card__bg'/>
            {/*<div className={addClassnames} onClick={() => handleAdd({ ...dish, amount: 1 })}>*/}
            {addAvailableDish()}
            {
              !feature ? null : featureIcon()
            }
          </div>
          <div className='card__right'>
            <div className='card__top card__top--desktop'>
              <h3 className='card__name'>
                {
                  i18n.language === 'en' ? name_en : name
                }
              </h3>
              <div className='card__price'>
                {price} ₽
              </div>
            </div>
            <div className='card__ingridients'>
              {
                i18n.language === 'en' ? summary_en : summary
              }
            </div>
            <div className='card__addition'>
              {
                weight ?
                <div>
                  {t('dishes.weight')}: {weight} {t('dishes.g')}
                </div> : null
              }
              {
                showNutritional? nutritional() : null
              }
              {
                allergens ?
                  <div>
                    {t('dishes.allergens')} {allergens}
                  </div> : null
              }
              {
                volume ?
                <div>
                  {volume} {t('dishes.ml')}
                </div> : null
              }
            </div>
          </div>
        </div>
      </div>
  )
}

export default DishListItem;
