import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
// import { ReactComponent as InstaLogo } from '../../svg/instagram.svg';
// import { ReactComponent as FBLogo } from '../../svg/facebook.svg';
import { ReactComponent as ReadIcon } from '../../svg/read-grey.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router";
import { useLocation } from "react-router-dom";

function LegalPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const legalClassnames = classNames({
    'legal modal modal--no-padding': true,
    'modal--visible': showModal
  });

  const headerClassnames = classNames({
    'modal-header': true,
    'modal-header--visible': showModal
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  /*
  * - Реквизиты
- Пользовательское соглашение
- Политика конфиденциальности обработки персональных данных
- Согласие на обработку персональных данных
- Согласие на получение рекламной рассылки
- Документ по cookies
* */
  const links = [
    {
      text: 'Реквизиты',
      link: 'https://pizzamaestrello.com/pdf/requisites.pdf',
    },
    {
      text: 'Пользовательское соглашение',
      link: 'https://pizzamaestrello.com/pdf/terms_of_use.pdf',
    },
    {
      text: 'Политика конфиденциальности обработки персональных данных',
      link: 'https://pizzamaestrello.com/pdf/processing_policy.pdf',
    },
    {
      text: 'Согласие на обработку персональных данных',
      link: 'https://pizzamaestrello.com/pdf/agreement_data_processing.pdf',
    },
    {
      text: 'Согласие на получение рекламной рассылки',
      link: 'https://pizzamaestrello.com/pdf/agreement_marketing.pdf',
    },
    {
      text: 'Cookies',
      link: 'https://pizzamaestrello.com/pdf/cookies.pdf',
    },
  ];

  return (
    <div className={legalClassnames}>
      <div className={headerClassnames}>
        <div className='modal-header__back base-header'>
          {t('common.legal')}
        </div>
        <div className='modal-header__mobile-close'>
          <button className='effect' onClick={handleCloseModal}>
            <CloseSmallIcon title='Закрыть' />
          </button>
        </div>
        <div className='modal-header__close'>
          <button className='effect' onClick={handleCloseModal}>
            <CloseIcon title='Закрыть'/> {t('page.close')}
          </button>
        </div>
      </div>
      <div className='modal__wrapper'>
        <div className='legal__top-info'>
          <div className='legal__links'>
            {
              links.map((l, i) => {
                const { text, link } = l

                return (
                  <div key={i} className='legal__full-block base-text'>
                    <a href={link} className='legal__social-link base-text effect3 no-flick' target='_blank' rel='noreferrer'>
                      <div className='legal__press-header'>
                        <div className='legal__press-link'>
                        <span className='effect3 base-text no-flick'>
                          <ReadIcon title='Читать' /> <span>{t(text)}</span>
                        </span>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegalPage;
