import { combineReducers } from 'redux';
import authReducer, { moduleName as authModule } from '../ducks/auth';
import cartReducer, { moduleName as cartModule } from '../ducks/cart';
import dishesReducer, { moduleName as dishesModule } from '../ducks/dishes';
import additionsReducer, { moduleName as additionsModule } from '../ducks/additions';
import orderReducer, { moduleName as orderModule } from '../ducks/order';
import mapReducer, { moduleName as mapModule } from '../ducks/map';
import santaReducer, { moduleName as santaModule } from '../ducks/santa';
import phoneReducer, { moduleName as phoneModule } from '../ducks/phone';
import paymentReducer, { moduleName as paymentModule } from '../ducks/payment';

export default combineReducers({
  [authModule]: authReducer,
  [cartModule]: cartReducer,
  [dishesModule]: dishesReducer,
  [additionsModule]: additionsReducer,
  [orderModule]: orderReducer,
  [mapModule]: mapReducer,
  [santaModule]: santaReducer,
  [phoneModule]: phoneReducer,
  [paymentModule]: paymentReducer
});
