import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {
  resetUserSuccess,
  useUserError,
  useUserInfo,
  useUserLoading,
  useUserSuccess,
  useUserToken
} from '../../ducks/auth';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate, useOutletContext } from "react-router";
import EditAddressForm from './edit-address-form';
import {useDispatch} from "react-redux";
import {getUserAddresses} from "../../utils";

function EditAddressPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);
  let { id } = useParams();
  id = parseInt(id);

  const dispatch = useDispatch();
  const userInfo = useUserInfo();

  const userLoading = useUserLoading();
  const userError = useUserError();
  const userSuccess = useUserSuccess();
  const userToken = useUserToken();
  const userAddresses = getUserAddresses(userInfo);
  const [addressItem, setAddressItem] = useState({});

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (userAddresses.length) {
      setAddressItem(userAddresses[id]);
    }
  }, [userAddresses]);

  useEffect(() => {
    if (userSuccess) {
      dispatch(resetUserSuccess());
      handleBack();
    }
  }, [userSuccess]);

  const editAccountClassnames = classNames({
    'edit-address modal': true,
    'modal--visible': showModal
  });

  const headerClassnames = classNames({
    'modal-header': true,
    'modal-header--visible': showModal
  });

  return (
    <div className={editAccountClassnames}>
      <div className={headerClassnames}>
        <div className='modal-header__back'>
          <button type='button' onClick={ userLoading ? null : handleBack } className='base-header effect'>
            <ArrowLeft /> {t('common.back')}
          </button>
        </div>
        <div className='modal-header__mobile-close'>
          <button className='effect' type='button' onClick={ userLoading ? null : handleCloseModal }>
            <CloseSmallIcon title='Закрыть' />
          </button>
        </div>
        <div className='modal-header__close'>
          <button className='effect' type='button' onClick={ userLoading ? null : handleCloseModal }>
            <CloseIcon title='Закрыть'/> {t('page.close')}
          </button>
        </div>
      </div>
      {
        addressItem.data?
        <EditAddressForm addressId={ id }/> : null
      }
    </div>
  )
}

export default EditAddressPage;
