import {Navigate, useLocation} from "react-router-dom";

export const ProtectedRoute = ({ user, children, redirectPath = '/login' }) => {
    const location = useLocation();
    if (!user) {
        return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }

    return children;
};
