import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';

const CategoryBtn = ({ anchor, name, anchorSelf, selectedCategory, decoration }) => {
  const [anchorTarget, setAnchorTarget] = useState(null);
  const buttonSelected = anchorSelf === selectedCategory;

  useEffect(() => {
    setAnchorTarget(document.getElementById(anchor));
  }, [anchor]);

  const handleClick = event => {
    event.preventDefault();
    elementScrollIntoView(anchorTarget, { behavior: 'smooth', block: 'start' });
  };

  const itemClassnames = classNames({
    'categories__button': true,
    'categories__button--selected': buttonSelected,
    'categories__button--styled': decoration,
    'effect': true
  });

  return (
    <a href={anchor}
       onClick={handleClick}
       className={itemClassnames}
       id={anchorSelf}>
      {name}
    </a>
  );
};

export default CategoryBtn;
