import React, { useEffect, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOrderStatus, orderSelector} from "../../ducks/order";
import {useTranslation} from "react-i18next";

function PaymentWidget({ paymentData }) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const orderData = useSelector(orderSelector);

    const { widget_params } = paymentData;

    let language = i18n.language === 'ru'? 'ru-RU' : 'en-GB';

    const pay = () =>  {
        /* eslint no-undef: "off"*/
        let widget = new cp.CloudPayments({
            language: language
        })

        widget.pay('charge',
            widget_params,
            {
                onSuccess: function (options) {
                    dispatch(getOrderStatus({ orderId: orderData.success, locale: i18n.language }))
                },
                onFail: function (reason, options) {
                    dispatch(getOrderStatus({ orderId: orderData.success, locale: i18n.language }))
                },
                onComplete: function (paymentResult, options) {
                    dispatch(getOrderStatus({ orderId: orderData.success, locale: i18n.language }))
                }
            }
        );
    };



    useEffect(() => {
        dispatch(getOrderStatus({ orderId: orderData.success, locale: i18n.language }))

        setTimeout(() => {
            pay();
        }, 1000);
    }, [paymentData]);

    return <></>
}

export default PaymentWidget;
