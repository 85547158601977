import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useUserInfo, useUserOrders} from "../../ducks/auth";
import {useDishObjects} from "../../ducks/dishes";
import { ReactComponent as PlusIcon } from '../../svg/yellow-plus.svg';
import { ReactComponent as LockIcon } from '../../svg/lock.svg';
import { ReactComponent as LockOpenIcon } from '../../svg/lock-open.svg';
import { ReactComponent as LockOpenActiveIcon } from '../../svg/lock-open-active.svg';

import classNames from "classnames";

function AccountLevelsBlock() {
    const { t } = useTranslation();
    const userOrders = useUserOrders();
    const userInfo = useUserInfo();
    const [userLevel, setUserLevel] = useState(0);

    useEffect(() => {
        if (userInfo) {
            if (userInfo.loyalty_level > 0) {
                setUserLevel(userInfo.loyalty_level);
            }
        }
    }, [userInfo]);

    const statusClassnames = classNames({
        'levels__block--item-status': true,
        'levels__block--item-status-active': true
    });

    const levels = [
        {
            label: t('levels.one.label'),
            label_alt: t('levels.one.label_alt'),
            head_text: t('levels.one.head_text'),
            bonus: t('levels.one.bonus'),
            info: false
        },
        {
            label: t('levels.two.label'),
            label_alt: t('levels.two.label_alt'),
            head_text: t('levels.two.head_text'),
            bonus: t('levels.two.bonus'),
            info: t('levels.two.info')
        },
        {
            label: t('levels.three.label'),
            label_alt: t('levels.three.label_alt'),
            head_text: t('levels.three.head_text'),
            bonus: t('levels.three.bonus'),
            info: t('levels.three.info')
        },
    ]

    const statusIcon = (level, index) => {
        if (level === 0) {
            return ( <LockIcon/> )
        } else if (level === (index + 1)) {
            return ( <LockOpenActiveIcon/> )
        } else if (level < (index + 1)) {
            return ( <LockIcon/> )
        } else if (level > (index + 1)) {
            return ( <LockOpenIcon/> )
        }
    };

    return (
        <div className='levels__block--row'>
            {
                levels.map((level, i) => {
                    const { label, head_text, bonus, bonus_plus, info } = level;

                    return (
                        <div key={i} className='base-text levels__block--item'>
                            <div className='levels__block--item-top'>
                                <span className='levels__block--item-label'>{label}</span>
                                <div className='levels__block--item-status'>
                                    {statusIcon(userLevel, i)}
                                </div>
                            </div>
                            <span className='levels__block--item-head-text'>{head_text}</span>
                            <div className='levels__block--item-bonus'>
                                {
                                    bonus && i !== 0?
                                        <>
                                            <div className='levels__block--item-bonus-icon'>
                                                <PlusIcon/>
                                            </div>
                                            <span>{bonus}</span>
                                        </> : null
                                }
                                {
                                    i === 0? <span>{bonus}</span> : null
                                }
                            </div>
                            <div className='levels__block--item-bonus-info'>
                                {
                                    info? <span>{info}</span> : null
                                }
                            </div>

                        </div>
                    )
                })

            }
        </div>
    )
}

export default AccountLevelsBlock;
