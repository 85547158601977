import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getSantaSales, santaSalesSelector} from "../../ducks/santa";
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as SantaIcon } from '../../svg/santa-banner-text.svg';
import { ReactComponent as Snow1 } from '../../svg/snow1.svg';
import { ReactComponent as Snow2 } from '../../svg/snow2.svg';
import { ReactComponent as Snow3 } from '../../svg/snow3.svg';
import { useTranslation } from "react-i18next";

function SantaPage({ show, closeAll }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalSales = useSelector(santaSalesSelector);
  const [resultMessage, setResultMessage] = useState({ 'one': 0, 'two': 0, 'three': 0 });

  const aboutClassnames = classNames({
    'page-sheet': true,
    'page-sheet--visible': show
  });

  const handleCloseClick = () => {
    closeAll(false);
  };

  useEffect(() => {
    if (show) {
      dispatch(getSantaSales());
    }
  }, [show]);

  useEffect(() => {
    const total = Array.from(totalSales.toString());

    while (total.length < 3) {
      total.unshift('0');
    }

    setResultMessage({
      one: total[2],
      two: total[1],
      three: total[0]
    })
  }, [totalSales]);

  return (
    <div className={aboutClassnames}>
      <div className='page-sheet__wrapper'>
        <div className='santa-page'>

          <div className='santa-page__banner'>
            <div className="santa-page__banner-column">
              <div className='base-text'>{t('promo.blockSanta.first_title2')}</div>
              <div className="santa-page__banner--bg"></div>
            </div>
          </div>

          <div className='santa-page__container'>
            <div className='santa-page__counter'>
              <div className='santa-page__counter-item-one base-text'>{resultMessage.three}</div>
              <div className='santa-page__counter-item-two base-text'>{resultMessage.two}</div>
              <div className='santa-page__counter-item-three base-text'>{resultMessage.one}</div>
            </div>
            <div className='santa-page__counter-title base-text'>{t('page.santa.counter')}</div>

            <div className='santa-page__text-fullwidth'>
              <p>
                {t('page.santa.header')}
              </p>
              <p>
                {t('page.santa.subheader')}
              </p>
            </div>

            <div className='santa-page__guide'>
              <div className='santa-page__guide-item base-text'>{t('page.santa.stage1')}</div>
              <div className='santa-page__guide-item base-text'>{t('page.santa.stage2')}</div>
              <div className='santa-page__guide-item base-text'>{t('page.santa.stage3')}</div>
              <div className='santa-page__guide-item base-text'>{t('page.santa.stage4')}</div>
              <div className='santa-page__guide-item base-text'>{t('page.santa.stage5')}</div>
            </div>
          </div>

        </div>
      </div>
      <div className='page-sheet--close '>
        <button className='effect' onClick={handleCloseClick}>
          <CloseIcon title='Закрыть'/> {t('page.close')}
        </button>
      </div>
    </div>
  );
}

export default SantaPage;
