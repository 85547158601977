import React, {useState, useEffect } from 'react';
import classNames from 'classnames';
import {Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {cartSelector, excludedPriceSelector, priceSelector} from '../../ducks/cart';
import { useAuthorized, useUserInfo, useUserToken } from '../../ducks/auth';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { ReactComponent as ReadIcon } from '../../svg/read-grey.svg';
import {
  saveOrderData,
  orderSelector,
  createOrder,
  createOrderReg,
  checkPromo, resetPromo, getOrderDiscounts,
} from '../../ducks/order';
import {
  clearPhoneCodeErrors,
  phoneValidatorSelector, resetPhoneCodeTimeout,
  setPhoneValid
} from '../../ducks/phone'
import {
  getUserEditedPhone,
  getUserEmail,
  checkIfTenPercentDicsountIsActive,
  checkIfFivePercentDiscountIsActive,
  roundPrice,
  paymentMethods, paymentMethodsOptions, checkIfOrderDataValid, calcDiscountWithoutSpecialProducts
} from '../../utils';
import { useTranslation } from "react-i18next";
import InputBlock from "../common/input-block";
import InputBlockPhone from "../common/input-block-phone";
import {useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";
import InputBlockPromoCode from "../common/input-block-promo-code";

function PaymentPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const [paymentMethod, selectPaymentMethod] = useState(paymentMethods.CARD);
  const [paymentMethodOption, selectPaymentMethodOption] = useState(paymentMethodsOptions.EMPTY);

  const isAuthorized = useAuthorized();
  const userInfo = useUserInfo();
  const userToken = useUserToken();
  const orderData = useSelector(orderSelector);
  const orderLoading = orderData.loading;
  const orderError = orderData.error;
  const pickUpSelected = orderData.pickup;
  const hideDeliveryTime = orderData.day && orderData.month && orderData.year;
  const loyaltyCard = userInfo ? userInfo.loyalty_card : false;

  const deliveryCost = orderData.deliveryCost;
  const deliveryMin = orderData.deliveryMin;
  const itemsPrice = useSelector(priceSelector);
  const excludedPrice = useSelector(excludedPriceSelector);
  const totalPrice = itemsPrice + deliveryCost;

  const cart = useSelector(cartSelector);

  const orderDiscount = orderData.discount;
  const writeOffAvailable = orderData.write_off_available;

  const [accumulatePoints, setAccumulatePoints] = useState(0);
  const [writeOffPrice, setWriteOffPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);

  const orderDataValid = checkIfOrderDataValid(orderData);

  const phoneValidatorData = useSelector(phoneValidatorSelector);
  const phoneCodeCheckEnabled = phoneValidatorData.phoneCodeCheckEnabled;
  const phoneCodeValid = phoneValidatorData.phoneCodeValid;

  let userName = '';
  if (orderData.name) {
    userName = orderData.name;
  } else if (isAuthorized) {
    userName = userInfo ? userInfo.name : '';
  }

  let userPhone = '';
  if (orderData.phone) {
    userPhone = orderData.phone;
  } else if (isAuthorized) {
    userPhone = getUserEditedPhone(userInfo);
  }

  let userEmail = '';
  if (orderData.email) {
    userEmail = orderData.email;
  } else if (isAuthorized) {
    userEmail = getUserEmail(userInfo);
  }

  let agreementProcessing = 0;
  let agreementMarketing = 0;
  if (isAuthorized) {
    agreementProcessing = 1;
    agreementMarketing = userInfo ? userInfo.agreement_marketing : 0;
  }

  let cashback = 0;
  let maxWriteOff = 0;
  let balance = 0;
  if (isAuthorized) {
    balance = orderData.balance;
    cashback = userInfo.loyalty_cashback;
    maxWriteOff = userInfo.max_percent_bonuses_write_off;
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  const handleOpenConditions = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/payment/conditions');
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
        dispatch(resetPromo());
        dispatch(checkPromo({
          userId: userInfo ? userInfo.id : '',
          code: ""
        }));
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (!orderDataValid) {
      setShowModal(false);
      setTimeout(()=> {
        navigate('/order');
      }, 200)
    }
  }, [orderDataValid]);

  useEffect(() => {
    if (pickUpSelected) {
      selectPaymentMethod(paymentMethods.CARD);
    }
  }, [pickUpSelected]);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(setPhoneValid());
      if (balance > 0) {
        selectPaymentMethodOption(paymentMethodsOptions.WRITE_OFF_POINTS);
      } else {
        selectPaymentMethodOption(paymentMethodsOptions.ADD_POINTS);
      }
    }
  }, [isAuthorized]);

  useEffect(() => {
    setFinalPrice(totalPrice - orderDiscount - writeOffPrice);
  }, [totalPrice, writeOffPrice, orderDiscount]);

  useEffect(() => {
    let points = (totalPrice - deliveryCost - excludedPrice) * (cashback / 100);
    points = parseFloat(points.toFixed(2));
    setAccumulatePoints(points);
  }, [finalPrice]);

  useEffect(() => {
    if (paymentMethodOption === paymentMethodsOptions.WRITE_OFF_POINTS && writeOffAvailable)
    {
      setWriteOffPrice(writeOffAvailable);
    } else {
      setWriteOffPrice(0);
    }
  }, [paymentMethodOption, writeOffAvailable]);

  const validate = values => {
    const errors = {};

    if (!values.name) {
      errors.name = t('formValidation.required');
    }

    if (!values.phone) {
      errors.phone = t('formValidation.required');
    } else if (values.phone.includes('_')) {
      errors.phone = t('formValidation.incorrectNumber');
    // }
    // else if (!/^\+7\ \([0-9]{3}\)\ [0-9]{3}-[0-9]{2}-[0-9]{2}/i.test(values.phone)) {
    //   console.log(`incorrect phone: ${values.phone.length} ${values.phone}`);
    }

    /* SMS code phone validation, reset error */
    if (phoneCodeCheckEnabled && values.code && !phoneCodeValid) {
      dispatch(clearPhoneCodeErrors());
    }

    if (!values.email) {
      errors.email = t('formValidation.required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t('formValidation.incorrectAddress');
    }

    if ((paymentMethod === paymentMethods.CASH) && !values.change) {
      errors.change = t('formValidation.required');
    }

    if (!values.agreement_processing) {
      errors.agreement_processing = t('formValidation.required');
    }

    return errors;
  }

  const initialValues = {
    name: userName,
    phone: userPhone,
    code: '',
    email: userEmail,
    promo: '',
    change: '',
    comment: orderData.comment,
    agreement_processing: agreementProcessing,
    agreement_marketing: agreementMarketing
  };

  const handleSubmit = (values) => {
    let order = { ...values };

    switch (paymentMethod) {
      case paymentMethods.CARD:
        order.paymentMethod = paymentMethods.CARD;
        order.change = '';
        break;

      case paymentMethods.CASH:
        order.paymentMethod = paymentMethods.CASH;
        break;

      case paymentMethods.INVOICE:
        order.paymentMethod = paymentMethods.INVOICE;
        break;

      case paymentMethods.TEST:
        order.paymentMethod = paymentMethods.TEST;
        break;

      default: break;
    }

    order.paymentMethodOption = paymentMethodOption;

    if (paymentMethodOption === paymentMethodsOptions.WRITE_OFF_POINTS) {
      order.writeOffPoints = writeOffPrice;
    }

    dispatch(saveOrderData(order));

    if (isAuthorized) {
      dispatch(createOrderReg({ token: userToken, userId: userInfo.id, order: orderData }));
    } else {
      dispatch(createOrder());
    }
  }

  const promocodeChecked = orderData.successPromo;
  const promocodeError = orderData.errorPromo;

  // const promocodeFilledAndNotSavedInStore = formik.values.promo && formik.values.promo !== orderData.promo;

  const paymentClassnames = classNames({
    'payment modal': true,
    'modal--visible': showModal
  });

  const headerClassnames = classNames({
    'modal-header': true,
    'modal-header--visible': showModal
  });

  const submitClassnames = classNames({
    'modal__submit': true,
    'modal__submit--visible': showModal
  });

  const methodCardClassnames = classNames({
    'payment__method': true,
    'payment__method--active': paymentMethod === paymentMethods.CARD,
    'effect5': true
  });

  const methodCardTestClassnames = classNames({
    'payment__method': true,
    'payment__method--active': paymentMethod === paymentMethods.TEST,
    'effect5': true
  });

  const methodCashClassnames = classNames({
    'payment__method': true,
    'payment__method--active': paymentMethod === paymentMethods.CASH,
    'payment__method--disabled': pickUpSelected,
    'effect5': true
  });

  const methodInvoiceClassnames = classNames({
    'payment__method divider': true,
    'payment__method--active': paymentMethod === paymentMethods.INVOICE,
    'payment__method--disabled': pickUpSelected,
    'effect5': true
  });

  const handleCheckPromo = (code) => {
    dispatch(checkPromo({
      userId: userInfo ? userInfo.id : '',
      code: code
    }));
  }

  return (
      <Formik
          initialValues={initialValues}
          validate={ validate }
          onSubmit={ handleSubmit }
          validateOnMount
          enableReinitialize>
        {
          props => {
            const {touched, isValid, errors, values} = props;
            const submitDisabled = !isValid || (phoneCodeCheckEnabled && !phoneCodeValid);
            const promocodeFilledAndSavedInStore = values.promo && values.promo === orderData.promo;
            const promocodeFilledAndNotSavedInStore = values.promo && values.promo !== orderData.promo;

            const promoBtnVisible = promocodeFilledAndNotSavedInStore;
            // && !promocodeChecked && !promocodeError;

            let promoMessage = '';
            if (promocodeChecked && promocodeFilledAndSavedInStore) {
              promoMessage = t('page.promo.promoActivated');
            } else if (promocodeError && promocodeFilledAndSavedInStore) {
              promoMessage = t('page.promo.promoInvalid');
            }

            const changeInputBlockClassnames = classNames({
              'input-block': true,
              'input-block--hidden': paymentMethod !== paymentMethods.CASH,
              'input-block__input--error': errors.change && touched.change,
            });

            const agreementProcessingCheckboxClassnames = classNames({
              'input-block__checkbox--error': errors.agreement_processing && touched.agreement_processing,
            });

            const agreementProcessingErrorClassnames = classNames({
              'input-block__error': true,
              'input-block__error--visible': errors.agreement_processing && touched.agreement_processing,
            });

            const agreementClassnames = classNames({
              'input-block': true,
              'input-block--hidden': !!isAuthorized,
            });

            const formErrorClassnames = classNames({
              'form__error': true,
              'form__error--visible': orderError
            });

            const deliveryTimeClasses = classNames({
              'base-text-white base-text-with-place': true,
              'not-visible': hideDeliveryTime
            });

            const priceClassnames = classNames({
              'text-line-through': orderDiscount || writeOffPrice
            });

            const optionAddPointsClassnames = classNames({
              'payment__method': true,
              'payment__method--active': paymentMethodOption === paymentMethodsOptions.ADD_POINTS,
              'payment__method--disabled': !isAuthorized,
              'effect5': true
            });

            const optionWriteOffPointsClassnames = classNames({
              'payment__method': true,
              'payment__method--active': paymentMethodOption === paymentMethodsOptions.WRITE_OFF_POINTS,
              'payment__method--disabled': !isAuthorized || balance === 0,
              'effect5': true
            });

            return (
                <form className={paymentClassnames} onSubmit={props.handleSubmit}>
                  <div className={headerClassnames}>
                    <div className='modal-header__back'>
                      <button type='button' onClick={orderLoading ? null : handleBack} className='base-header effect'>
                        <ArrowLeft /> {t('page.payment.name')}
                      </button>
                    </div>
                    <div className='modal-header__mobile-close'>
                      <button type='button' className='effect' onClick={orderLoading ? null : handleCloseModal}>
                        <CloseSmallIcon title='Закрыть' />
                      </button>
                    </div>
                    <div className='modal-header__close'>
                      <button type='button' className='effect' onClick={orderLoading ? null : handleCloseModal}>
                        <CloseIcon title='Закрыть'/> {t('page.close')}
                      </button>
                    </div>
                  </div>
                  <div className='modal__wrapper'>

                    <div className='payment__inputs'>
                      <InputBlock
                          name='name'
                          label={`${t('page.auth.name')} *`}
                          readOnly={ isAuthorized ? 1 : undefined }
                      />

                      <InputBlockPhone
                          name='phone'
                          label={`${t('page.auth.phone')} *`}
                          skipvalidation={ isAuthorized ? 1 : undefined }
                          readOnly={ isAuthorized ? 1 : undefined }
                      />

                      <InputBlock
                          name='email'
                          label={`${t('page.auth.email')} *`}
                          readOnly={ isAuthorized ? 1 : undefined }
                      />
                    </div>

                    <div className='payment__pay'>
                      <div className='payment__pay-header'>
                        <div className='base-header'>
                          {t('page.payment.options')}
                        </div>
                        <div onClick={ handleOpenConditions } className='payment__pay-terms effect3'>
                          <ReadIcon/> <span>{t('page.payment.conditions')}</span>
                        </div>
                      </div>
                      <div className='payment__methods'>
                        <div onClick={orderLoading ? null : () => selectPaymentMethod(paymentMethods.CARD)} className={methodCardClassnames}>
                          <div className='payment__method-name'>
                            {t('page.payment.byCard')}
                          </div>
                          <div className='payment__method-bottom'>
                            <img src='https://pizzamaestrello.com/img/media/pay-systems2.png' width='152' alt='Платёжные Системы' />
                          </div>
                        </div>
                        {/*<div onClick={orderLoading ? null : () => selectPaymentMethod(paymentMethods.TEST)} className={methodCardTestClassnames}>*/}
                        {/*  <div className='payment__method-name'>*/}
                        {/*    {t('page.payment.byCardTest')}*/}
                        {/*  </div>*/}
                        {/*  <div className='payment__method-bottom'>*/}
                        {/*    <img src='https://pizzamaestrello.com/img/media/pay-systems2.png' width='152' alt='Платёжные Системы' />*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div onClick={orderLoading || pickUpSelected ? null : () => selectPaymentMethod(paymentMethods.CASH)} className={methodCashClassnames}>
                          <div className='payment__method-name'>
                            {t('page.payment.byCash')}
                          </div>
                          <div className='payment__method-bottom'>
                            {t('page.payment.byCashSecondLine')}
                          </div>
                        </div>
                        <div onClick={orderLoading || pickUpSelected ? null : () => selectPaymentMethod(paymentMethods.INVOICE)} className={methodInvoiceClassnames}>
                          <div className='payment__method-name'>
                            {t('page.payment.byInvoice')}
                          </div>
                          <div className='payment__method-bottom'>
                            {t('page.payment.byInvoiceSecondLine')}
                          </div>
                        </div>

                        {/* Payment option discount */}
                        {
                          isAuthorized?
                              loyaltyCard?
                                  <>
                                    {/* Payment option accumulate */}
                                    <div onClick={orderLoading ? null : () => selectPaymentMethodOption(paymentMethodsOptions.ADD_POINTS)} className={optionAddPointsClassnames}>
                                      <div className='payment__method-name'>
                                        {t('page.payment.optionSavePoints')}
                                      </div>
                                      <div className='payment__method-bottom'>
                                        {t('page.payment.point', { count: accumulatePoints})}
                                      </div>
                                    </div>
                                    {/* Payment option write off */}
                                    <div onClick={orderLoading || (balance <= 0)? null : () => selectPaymentMethodOption(paymentMethodsOptions.WRITE_OFF_POINTS)} className={optionWriteOffPointsClassnames}>
                                      <div className='payment__method-name'>
                                        {t('page.payment.optionWriteOffPoints')} ({writeOffAvailable})
                                      </div>
                                      <div className='payment__method-bottom'>
                                       {t('page.payment.writeOffDescription', { count: maxWriteOff })}
                                      </div>
                                    </div>
                                  </> : null
                              : null
                        }
                      </div>
                    </div>

                    <div className='payment__inputs'>
                      <div className={changeInputBlockClassnames}>
                        <InputBlock
                            name='change'
                            label={t('page.payment.change')}
                        />
                      </div>

                      <InputBlockPromoCode
                          name='promo'
                          success={{ show: orderData.successPromo, message: t('page.payment.promoActivated') }}
                          error={{ show: orderData.errorPromo, message: t('page.payment.promoInvalid') }}
                          label={t('page.payment.discount')}
                      />

                      <InputBlock
                          name='comment'
                          label={t('page.account.comment')}
                      />

                      <div className={agreementClassnames}>
                        <div className='input-block__checkbox'>
                          <label htmlFor='orderAgreementProcessing' className={agreementProcessingCheckboxClassnames}>
                            {t('common.agreement_processing')}
                            <input
                                id='orderAgreementProcessing'
                                name='agreement_processing'
                                type='checkbox'
                                onChange={event => props.setFieldValue('agreement_processing', event.target.checked)}
                                onBlur={props.handleBlur}
                                checked={props.values.agreement_processing}
                            />
                            <span className='checkmark'></span>
                          </label>
                        </div>
                        <span className={agreementProcessingErrorClassnames}>{props.errors.agreement_processing}</span>
                      </div>
                      <div className='input-block'>
                        <div className='input-block__checkbox'>
                          <label htmlFor='orderAgreementMarketing'>
                            {t('common.agreement_marketing')}
                            <input
                                id='orderAgreementMarketing'
                                name='agreement_marketing'
                                type='checkbox'
                                onChange={event => props.setFieldValue('agreement_marketing', event.target.checked)}
                                onBlur={props.handleBlur}
                                checked={props.values.agreement_marketing}
                            />
                            <span className='checkmark'></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={formErrorClassnames}>
                      {
                        orderError && !orderLoading ? <span>Что-то пошло не так</span> : null
                      }
                    </div>
                    <div className={submitClassnames}>
                      {
                        promoBtnVisible ?
                            <button type='button' onClick={() => handleCheckPromo(props.values.promo)} disabled={orderLoading} className='button effect2'>
                              <span>{t('page.payment.checkPromo')}</span>
                            </button> :
                                <button type='submit' disabled={orderLoading || submitDisabled} className='button button--multiple-children effect2'>
                                  <span className={deliveryTimeClasses}>{t('page.order.min', { minutes: deliveryMin })}</span>
                                  <span>{paymentMethod === paymentMethods.CARD ? t('page.payment.pay') : t('page.payment.checkout')}</span>
                                  <span className='base-text-white'>
                                    <span className={priceClassnames}>{totalPrice} ₽</span>
                                    {orderDiscount || writeOffPrice? <span>{`${roundPrice(finalPrice)} ₽`}</span> : null}
                                  </span>
                                </button>
                      }
                    </div>
                  </div>
                </form>
            )
          }
        }
      </Formik>
  )
}

export default PaymentPage;
