import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as LoyaltyIcon } from '../../svg/promo-loyalty-steps-icon.svg';
import {toggleAboutPickupModal, orderSelector} from '../../ducks/order';
import { useTranslation } from "react-i18next";
import amplitude from "amplitude-js";

function PromoBlockPickup() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);

  const thirdBlockClassnames = classNames({
    'promo__block': true,
    'promo__block--touched': tapped
  });

  useEffect(() => {
    if (tapped) {
      amplitude.getInstance().logEvent('buttom_main_special_offer', {
        offer_name: 'sale_delivery'
      });
    }
  }, [tapped]);

  return (
    <div className={thirdBlockClassnames} onClick={() => toggleTap(!tapped)}>
      <div className='promo__block-inner'>
        <div className='promo__block-first'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockLoyalty.front_title0')}<br/>
              {t('promo.blockLoyalty.front_title1')}<br/>
              {t('promo.blockLoyalty.front_title2')}<br/>
              {t('promo.blockLoyalty.front_title3')}
            </div>
          </div>
          <div className='promo__block-icon promo__block-icon-loyalty'>
            <LoyaltyIcon />
          </div>
        </div>
        <div className='promo__block-second'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockLoyalty.front_title0')} {t('promo.blockLoyalty.front_title1')}<br/>
              {t('promo.blockLoyalty.front_title2')} {t('promo.blockLoyalty.front_title3')}
            </div>
            <div className='promo__block-text-small mt-1'>
              <p>
                {t('promo.blockLoyalty.back_subtitle0')}<br/>
                {t('promo.blockLoyalty.back_subtitle00')}
              </p>
              <p>
                {t('promo.blockLoyalty.back_subtitle1')}<br/>
                {t('promo.blockLoyalty.back_subtitle2')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockPickup;
