import React, { useEffect } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

function InputBlockGenderSelect(props) {
    const [field, meta, helpers] = useField(props);
    const validationFailed = !!meta.touched && !!meta.error;
    const { options } = props;
    const { value } = meta;
    const { setValue } = helpers;

    const inputClassnames = classNames({
        'input-block__radio': true,
        'input-block__radio--grey': true,
        'input-block__radio--error': validationFailed,
    });
    const errorClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': validationFailed,
    });

    return (
        <div className='input-block'>
            <div className='input-block__label'>
                <label htmlFor={props.name}>{props.label}</label>
            </div>
            <div className={inputClassnames}>
                {
                    options.map((v, index) => {
                        return (
                            <label key={index} className={ value === v.value? 'checked':'null' }>
                                <input
                                    type="radio"
                                    value={v.value}
                                    checked={value === v.value}
                                    onChange={() => setValue(v.value)}
                                    name={props.name} />
                                {v.name}
                            </label>
                        )
                    })
                }
            </div>
            <span className={errorClassnames}>{meta.error}</span>
        </div>
    )
}

export default InputBlockGenderSelect;
