import { put, takeEvery, all, call } from 'redux-saga/effects';
import apiService from '../services';

/**
 * Constants
 * */
export const moduleName = 'additions';
// const prefix = `${appName}/${moduleName}`;
const prefix = 'additions';

export const GET_ADDITIONS_REQUEST = `${prefix}/GET_ADDITIONS_REQUEST`;
export const GET_ADDITIONS_START = `${prefix}/GET_ADDITIONS_START`;
export const GET_ADDITIONS_SUCCESS = `${prefix}/GET_ADDITIONS_SUCCESS`;
export const GET_ADDITIONS_FAIL = `${prefix}/GET_ADDITIONS_FAIL`;

/**
 * Reducer
 * */

export default function reducer(state = { loading: true, entities: [] }, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADDITIONS_START:
      return {...state, loading: true };
    case GET_ADDITIONS_SUCCESS:
      return {...state, entities: payload, loading: false };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const additionsListSelector = (state) => state[moduleName].entities;
export const additionsLoadingSelector = (state) => state[moduleName].loading;

/**
 * Action Creators
 * */

export const getAdditions = (id) => ({
  type: GET_ADDITIONS_REQUEST,
  payload: { id }
});

/**
 * Sagas
 * */

export const getToppingsSaga = function* ({ payload }) {
  try {
   yield put({
     type: GET_ADDITIONS_START
   });

   const request = yield call(apiService.getToppings, payload.id);

   yield put({
     type: GET_ADDITIONS_SUCCESS,
     payload: request.data.services
   });
  } catch (error) {
   // TODO: handle error
  }
};

export const saga = function* () {
  yield all(
    [takeEvery(GET_ADDITIONS_REQUEST, getToppingsSaga)]
  );
};
