import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUserErrors,
  resetUserSuccessSaga,
  setReferer,
  useAuthorized,
  useUserCustomError,
  useUserErrorMessage
} from '../../ducks/auth';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { register, useUserLoading, useUserError } from '../../ducks/auth';
import { useTranslation } from "react-i18next";
import { clearPhoneCodeErrors, phoneValidatorSelector } from "../../ducks/phone";
import InputBlockPhone from "../common/input-block-phone";
import { Formik, Field } from 'formik';
import InputBlock from "../common/input-block";
import { useNavigate, useOutletContext } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import InputBlockDate from "../common/input-block-date";
import InputBlockSelect from "../common/input-block-select";
import InputBlockGenderSelect from "../common/input-block-gender-select";
import InputBlockCheckbox from "../common/input-block-checkbox";

function RegisterFormPage()  {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();

  const isAuthorized = useAuthorized();
  const userLoading = useUserLoading();
  const userError = useUserError();
  const userErrorMessage = useUserErrorMessage();
  const [showModal, setShowModal] = useState(false);

  /* ToDo refactor, backend controllable */
  const phoneValidatorData = useSelector(phoneValidatorSelector);
  const phoneCodeCheckEnabled = phoneValidatorData.phoneCodeCheckEnabled;
  const phoneCodeValid = phoneValidatorData.phoneCodeValid;
  const phoneCodeError = phoneValidatorData.phoneCodeError;
  const phoneCodeAssetID = phoneValidatorData.phoneCodeAssetID;

  /* Referral program */
  let { referer } = useParams();

  const formClassnames = classNames({
    'form': true,
    'form--login': true,
    'form--login-visible': showModal,
    'form--register': true
  });

  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    dispatch(register({...values, phoneCodeAssetID, locale: i18n.language, referer: referer?? null}));
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleGoToRecover = () => {
    setShowModal(false);
    dispatch(resetUserErrors());
    setTimeout(()=> {
      navigate('/forgot');
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (isAuthorized) {
      setShowModal(false);
      setTimeout(()=> {
        navigate(-1);
      }, 200);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (referer !== undefined) {
      localStorage.setItem('referer', referer);
      dispatch(setReferer(referer));
    }
  }, [referer]);

  const validate = values => {
    const errors = {};

    if (!values.regFirstName) {
      errors.regFirstName = t('formValidation.required');
    }

    if (!values.regLastName) {
      errors.regLastName = t('formValidation.required');
    }

    if (!values.regGender || values.regGender === '0') {
      errors.regGender = t('formValidation.required');
    }

    if (!values.regBirthday) {
      errors.regBirthday = t('formValidation.required');
    } else if (!/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(values.regBirthday)) {
      errors.regBirthday = t('formValidation.incorrectFormat');
    } else if (values.regBirthday.split('.')[0] > 31) {
      errors.regBirthday = t('formValidation.incorrectAddress');
    }

    if (!values.regEmail) {
      errors.regEmail = t('formValidation.required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.regEmail)) {
      errors.regEmail = t('formValidation.incorrectAddress');
    }

    if (!values.regPhone) {
      errors.regPhone = t('formValidation.required');
    } else if (values.regPhone.includes('_')) {
      errors.regPhone = t('formValidation.incorrectPhone');
    }

    if (phoneCodeError) {
      errors.regPhoneCode = t('formValidation.incorrectCode');
    }

    if (!values.regPassword) {
      errors.regPassword = t('formValidation.required');
    }

    if  (!/^(?=.{8,})/i.test(values.regPassword)) {
      errors.regPassword = t('formValidation.passwordLong');
    }

    if (!values.regPasswordCopy) {
      errors.regPasswordCopy = t('formValidation.required');
    } else if (values.regPassword !== values.regPasswordCopy) {
      errors.regPasswordCopy = t('formValidation.notExact');
    }

    if (!values.regAgreementProcessing) {
      errors.regAgreementProcessing = t('formValidation.required');
    }

    return errors;
  }

  const initialValues = {
    regFirstName: '',
    regLastName: '',
    regGender: 0,
    regBirthday: '',
    regPhone: '',
    regPhoneCode: '',
    regEmail: '',
    regPassword: '',
    regPasswordCopy: '',
    regAgreementProcessing: false,
    regAgreementMarketing: false
  };

  useEffect(() => {
    if (showModal) {
      dispatch(clearPhoneCodeErrors());
    }
  }, [showModal]);


  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': userError
  });

  return (
      <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          validateOnMount
          enableReinitialize>
        {
          props => {
            const {touched, isValid, errors, values} = props;
            const submitDisabled = !isValid || (phoneCodeCheckEnabled && !phoneCodeValid);


            return (
                <form className={formClassnames} onSubmit={props.handleSubmit}>
                  <div className='form__form form__form--register'>
                    <div className='form__header form__header--register'>
                      <button className='form__header-left base-header effect' type='button'
                              onClick={userLoading ? null : handleBack}>
                        <ArrowLeft/> {t('page.auth.singUp')}
                      </button>
                    </div>
                    <div className='form__inputs'>
                      <InputBlock
                          name='regFirstName'
                          label={`${t('page.auth.firstName')} *`}
                      />

                      <InputBlock
                          name='regLastName'
                          label={`${t('page.auth.lastName')} *`}
                      />

                      <InputBlockGenderSelect
                          name='regGender'
                          label={`${t('page.auth.gender')} *`}
                          options={[
                            { value: 1, name: t('page.auth.gender_male') },
                            { value: 2, name: t('page.auth.gender_female') }
                          ]}
                      />

                      <InputBlockDate
                          name='regBirthday'
                          label={`${t('page.auth.birthday')} *`}
                      />

                      <InputBlockPhone
                          name='regPhone'я
                          label={`${t('page.auth.phone')} *`}
                      />

                      <InputBlock
                          name='regEmail'
                          label={`${t('page.auth.email')} *`}
                      />

                      <InputBlock
                          name='regPassword'
                          type='password'
                          autocomplete='new-password'
                          label={`${t('page.auth.password')} *`}
                      />

                      <InputBlock
                          name='regPasswordCopy'
                          type='password'
                          label={`${t('page.auth.passwordCopy')} *`}
                      />

                      <InputBlockCheckbox
                          name='regAgreementProcessing'
                          label={`${t('common.agreement_processing')} *`}
                      />

                      <InputBlockCheckbox
                          name='regAgreementMarketing'
                          label={`${t('common.agreement_marketing')} *`}
                      />

                    </div>
                    <div className={formErrorClassnames}>
                      {
                        userError ? <span>
                          {t('page.auth.registerError')} <a onClick={handleGoToRecover} className='effect3 link'>{t('page.auth.recoverPassword')}</a>
                        </span> : null
                      }
                    </div>
                    <div className='form__submit form__login-bottom form__submit--register'>
                      <button className='button effect' type='submit'
                              disabled={userLoading || submitDisabled}>{t('page.auth.createAccount')}</button>
                    </div>
                  </div>
                  <div className='form__close'>
                    <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal}>
                      <CloseIcon title='Закрыть'/> {t('page.close')}
                    </button>
                  </div>
                </form>
            )
          }
        }
      </Formik>
  )
}

export default RegisterFormPage;
