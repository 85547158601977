import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as TenPercent } from '../../svg/promo-discount-icon.svg';
import amplitude from 'amplitude-js';
import { useTranslation } from "react-i18next";

function PromoBlockDiscount() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);

  const firstBlockClassnames = classNames({
    'promo__block': true,
    // 'promo__block--hidden-on-desktop': isAuthorized,
    'promo__block--touched': tapped
  });

  useEffect(() => {
    if (tapped) {
      amplitude.getInstance().logEvent('buttom_main_special_offer', {
        offer_name: 'sale_delivery'
      });
    }
  }, [tapped]);

  return (
    <div className={firstBlockClassnames} onClick={() => toggleTap(!tapped)}>
      <div className='promo__block-inner'>
        <div className='promo__block-first'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockDiscount.front_title0')}<br/>
              {t('promo.blockDiscount.front_title1')}
            </div>
            <div className='promo__block-text-small mt-1'>
              {t('promo.blockDiscount.front_subtitle0')}
            </div>
          </div>
          <div className='promo__block-icon'>
            <TenPercent />
          </div>
        </div>
        <div className='promo__block-second'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockDiscount.front_title0')}<br/>
              {t('promo.blockDiscount.front_title1')}
            </div>
            <div className='promo__block-text-small mt-1'>
              {t('promo.blockDiscount.back_subtitle0')}<br/>
              {t('promo.blockDiscount.back_subtitle1')}<br/>
              {t('promo.blockDiscount.back_subtitle2')}
            </div>
          </div>
          <div className='promo__block-icon'>
            <TenPercent />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockDiscount;
