import React from 'react';
import { ReactComponent as MadLogo } from '../../svg/mad-logo.svg';
import { useSelector } from 'react-redux';
import { dishesLoadingSelector, dishListSelector } from './../../ducks/dishes';

function Footer() {
  const dishesLoading = useSelector(dishesLoadingSelector);
  const dishes = useSelector(dishListSelector);

  if (dishesLoading || !dishes.length) return null;
  
  return (
    <footer className='footer'>
      <a className='effect3' href='https://madebymad.co'><span>Made by</span> <MadLogo/> </a>
      <img src='https://pizzamaestrello.com/img/media/pay-systems2.png' width='152' alt='Платёжные Системы' />
    </footer>
  );
}

export default Footer;
