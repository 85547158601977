import { put, takeEvery, all, call } from 'redux-saga/effects';
import apiService from '../services';

/**
 * Constants
 * */
export const moduleName = 'phone';
const prefix = 'phone';

export const SEND_PHONE_CODE_REQUEST = `${prefix}/SEND_PHONE_CODE_REQUEST`;
export const SEND_PHONE_CODE_START = `${prefix}/SEND_PHONE_CODE_START`;
export const SEND_PHONE_CODE_SUCCESS = `${prefix}/SEND_PHONE_CODE_SUCCESS`;
export const SEND_PHONE_CODE_ERROR = `${prefix}/SEND_PHONE_CODE_ERROR`;
export const SEND_PHONE_CODE_CLEAR_ERRORS = `${prefix}/SEND_PHONE_CODE_CLEAR_ERRORS`;

export const CHECK_PHONE_REQUEST = `${prefix}/CHECK_PHONE_REQUEST`;
export const CHECK_PHONE_START = `${prefix}/CHECK_PHONE_START`;
export const CHECK_PHONE_SUCCESS = `${prefix}/CHECK_PHONE_SUCCESS`;
export const CHECK_PHONE_ERROR = `${prefix}/CHECK_PHONE_ERROR`;
export const RESET_TIMEOUT = `${prefix}/RESET_TIMEOUT`;

/**
 * ReducerRESET_TIMEOUT
 * */

const initialState = {
  loading: true,
  phoneCodeCheckEnabled: true,
  phoneCodeAssetID: null,
  phoneCodeRequested: false,
  phoneCodeValid: false,
  phoneCodeError: false,
  sendPhoneCodeError: false,
  errorMessage: ''
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEND_PHONE_CODE_START:
      return {...state, loading: true, phoneCodeAssetID: null, phoneCodeValid: false, sendPhoneCodeError: false, phoneCodeError: false, errorMessage: '' };
    case SEND_PHONE_CODE_SUCCESS:
      return {...state, loading: false, phoneCodeRequested: true, phoneCodeAssetID: payload.asset, sendPhoneCodeError: false, phoneCodeError: false, errorMessage: '' };
    case SEND_PHONE_CODE_ERROR:
      return {...state, loading: false, phoneCodeAssetID: null, sendPhoneCodeError: true, errorMessage: payload.error };
    case SEND_PHONE_CODE_CLEAR_ERRORS:
      return {...state, phoneCodeValid: false, phoneCodeRequested: false, sendPhoneCodeError: false, phoneCodeError: false, errorMessage: '' };

    case CHECK_PHONE_START:
      return {...state, loading: true, phoneCodeValid: false, phoneCodeError: false, errorMessage: '' };
    case CHECK_PHONE_SUCCESS:
      return {...state, loading: false, phoneCodeRequested: false, phoneCodeValid: true, phoneCodeError: false, errorMessage: '' };
    case CHECK_PHONE_ERROR:
      return {...state, loading: false, phoneCodeValid: false, phoneCodeError: true, errorMessage: payload.error };

    case RESET_TIMEOUT:
      return {...state, phoneCodeRequested: false };

    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const phoneValidatorSelector = (state) => state[moduleName];

/**
 * Action Creators
 * */

export const sendPhoneCode = (payload) => ({
  type: SEND_PHONE_CODE_REQUEST,
  payload: payload
});

export const checkPhoneCode = (payload) => ({
  type: CHECK_PHONE_REQUEST,
  payload: payload
});

export const clearPhoneCodeErrors = (payload) => ({
  type: SEND_PHONE_CODE_CLEAR_ERRORS
});

export const resetPhoneCodeTimeout = (payload) => ({
  type: RESET_TIMEOUT,
  payload: payload
});

export const setPhoneValid = () => ({
  type: CHECK_PHONE_SUCCESS
});

/**
 * Sagas
 * */

export const sendPhoneCodeSaga = function* ({ payload }) {
  try {
    yield put({
      type: SEND_PHONE_CODE_START,
      payload: payload,
    });

    let request = yield call(apiService.sendPhoneCode, payload);

    const error = request.data.error;
    const errorMessage = request.data.error_description;
    const assetId = request.data.asset_id;

    if (error && errorMessage) {
      yield put({
        type: SEND_PHONE_CODE_ERROR,
        payload: { error: errorMessage }
      });
    } else {
      yield put({
        type: SEND_PHONE_CODE_SUCCESS,
        payload: { asset: assetId }
      });
    }


  } catch (error) {
    const cError = error.response.data.error_description;
    yield put({
      type: SEND_PHONE_CODE_ERROR,
      payload: { error: cError }
    });
  }
};

export const checkPhoneSaga = function* ({ payload }) {
  try {
    yield put({
      type: CHECK_PHONE_START,
      payload: payload,
    });

    let request = yield call(apiService.checkPhoneCode, payload);

    const status = request.data.status;
    const details = request.data.details;

    if (status === false) {
      yield put({
        type: CHECK_PHONE_ERROR,
        payload: { error: details.error }
      });
    } else {
      yield put({
        type: CHECK_PHONE_SUCCESS
      });
    }


  } catch (error) {
    yield put({
      type: CHECK_PHONE_ERROR
    });
  }
};

export const saga = function* () {
  yield all(
    [
      takeEvery(SEND_PHONE_CODE_REQUEST, sendPhoneCodeSaga),
      takeEvery(CHECK_PHONE_REQUEST, checkPhoneSaga),
    ],
  );
};
