import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

function InputBlockPromoCode(props) {
    const [field, meta] = useField(props);
    const validationFailed = !!meta.touched && !!meta.error;
    const postValidationSuccess = meta.touched && props.success.show;
    const postValidationError = meta.touched && props.error.show;

    const inputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--grey': true,
        'input-block__input--success': postValidationSuccess,
        'input-block__input--error': validationFailed || postValidationError,
    });

    const successClassnames = classNames({
        'input-block__success': true,
        'input-block__success--visible': postValidationSuccess,
    });

    const errorClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': validationFailed,
    });

    const errorClassnamesPost = classNames({
        'input-block__error': true,
        'input-block__error--visible': postValidationError,
    });

    return (
        <div className='input-block'>
            <div className='input-block__label'>
                <label htmlFor={props.name}>{props.label}</label>
            </div>
            <div className={inputClassnames}>
                <input
                    id={props.name}
                    {...props}
                    {...field}
                />
            </div>
            <span className={successClassnames}>{props.success.message}</span>
            <span className={errorClassnamesPost}>{props.error.message}</span>
            <span className={errorClassnames}>{meta.error}</span>
        </div>
    )
}

export default InputBlockPromoCode;
