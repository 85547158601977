import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as OrderCreatedIcon } from '../../svg/order-created.svg';
import { ReactComponent as OrderErrorIcon } from '../../svg/order-error.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-36.svg';
import { orderSelector, getOrderStatus, createOrderPaymentForm } from '../../ducks/order';
import {joinUser, useRegistered, useUserCustomError, useUserInfo, useUserToken} from '../../ducks/auth';
import { useUserLoading, useUserError, useAuthorized } from '../../ducks/auth'
import { useTranslation } from "react-i18next";
import { notificationType } from "../../utils";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

function NotificationModal() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);

    const [modalType, setModalType] = useState(notificationType.EMPTY);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [textFooter, setTextFooter] = useState('');
    const [link, setLink] = useState('');

    const isAuthorized = useAuthorized();
    const userLoading = useUserLoading();
    const userInfo = useUserInfo();
    const registered = useRegistered();


    const locardsCardUrl = userInfo ? userInfo.locards_card_url : '';
    const locardsLevelChanged = userInfo ? userInfo.locards_level_changed : false;
    const locardsCashback = userInfo ? userInfo.locards_cashback : 0;
    const loCardsLevel = userInfo ? parseInt(userInfo.locards_level) : -1;

    const handleGoToAccount = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/account');
        }, 200)
    }

    const handleGoToAccountEdit = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/account/edit');
        }, 200)
    }

    const handleHide = () => {
        setShowModal(false);
        if (modalType === notificationType.JOIN) {
            // localStorage.setItem('hideNotifyFinishRegistration', '1');
        } else if (modalType === notificationType.LEVEL) {
            // localStorage.setItem('hideNotifyLevel', '1');
        } else if (modalType === notificationType.REGISTERED) {
            localStorage.setItem('hideNotifyRegistered', '1');
        }
    }

    const getText = () => {

        return (
            <>
                <p>{t('notification.level.text1')}</p>
                <p>{t('notification.level.text2', { percent: locardsCashback })}</p>
                <p>{t('notification.level.text3')}</p>
            </>
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isAuthorized) {
                if (userInfo && locardsCardUrl === '') {
                    // const hideNotifyFinishRegistration = localStorage.getItem('hideNotifyFinishRegistration');

                    // if (hideNotifyFinishRegistration !== '1')
                    // {
                    setModalType(notificationType.JOIN);
                    setShowModal(true);
                    setTitle(t('notification.join.title'));
                    setText(t('notification.join.text'));
                    setLink(t('notification.join.link'))

                    setTimeout(() => {
                        localStorage.setItem('hideNotifyFinishRegistration', '1');
                        setShowModal(false);
                    }, 5000);
                    // }
                }
                else if (userInfo && locardsLevelChanged && loCardsLevel > 1)
                {

                    const hideNotifyLevel = localStorage.getItem('hideNotifyLevel');
                    if (hideNotifyLevel !== '1') {
                        setModalType(notificationType.LEVEL);
                        setShowModal(true);
                        setTitle(t('notification.level.title'));
                        setText(getText());
                        setLink(t('notification.level.link'))
                    }
                }
                else if (userInfo && registered)
                {
                    const hideNotifyRegistered = localStorage.getItem('hideNotifyRegistered');
                    if (hideNotifyRegistered !== '1')
                    {
                        setModalType(notificationType.REGISTERED);
                        setShowModal(true);
                        setTitle(t('notification.registered.title'));
                        setText(t('notification.registered.text'));
                        setTextFooter(t('notification.registered.footerText'));
                        setLink('');

                        setTimeout(() => {
                            localStorage.setItem('hideNotifyRegistered', '1');
                            setShowModal(false);
                        }, 8000);
                    }
                }
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [userInfo]);

    const blockClassnames = classNames({
        'notification': true,
        'notification--visible': showModal
    });

    return (
        <div className={blockClassnames}>
            <div className='notification__close-icon'>
                <button onClick={ handleHide } className='effect' >
                    <CloseIcon/>
                </button>
            </div>
            <div className='notification__header'>
                <div className='base-header'>
                    {title}
                </div>
            </div>
            <div className='notification__body mt-2'>
                <div className='base-text'>
                    {text}
                </div>
            </div>
            <div className='notification__footer mt-2'>
                {
                    modalType === notificationType.REGISTERED?
                        <div className='base-text'>{textFooter}</div>
                        :
                        <button onClick={ modalType === notificationType.JOIN? handleGoToAccountEdit : handleGoToAccount } className='button effect' disabled={userLoading}>
                            {link}
                        </button>
                }
            </div>
        </div>
    );
}

export default NotificationModal;
