import React, {useEffect, useState} from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import {
    phoneValidatorSelector,
    clearPhoneCodeErrors,
    sendPhoneCode,
    checkPhoneCode,
    resetPhoneCodeTimeout
} from "../../ducks/phone";
import {useDispatch, useSelector} from "react-redux";
import InputMask from "react-input-mask";
import {useTranslation} from "react-i18next";
import { useFormikContext, Formik, Form, Field } from 'formik';

function InputBlockPhone(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const { skipvalidation } = props;
    const [field, meta, loading] = useField(props);
    const { values, setFieldValue, setFieldError } = useFormikContext();
    const validationFailed = !!meta.touched && !!meta.error;

    const phoneValidatorData = useSelector(phoneValidatorSelector);
    const phoneCodeCheckEnabled = phoneValidatorData.phoneCodeCheckEnabled;
    const phoneCodeRequested = phoneValidatorData.phoneCodeRequested;
    const phoneCodeValid = phoneValidatorData.phoneCodeValid;
    const sendPhoneCodeError = phoneValidatorData.sendPhoneCodeError;
    const phoneCodeError = phoneValidatorData.phoneCodeError;
    const errorMessage = phoneValidatorData.errorMessage;
    const phoneCodeAssetID = phoneValidatorData.phoneCodeAssetID;

    // const phoneCodeErrorMessage = orderData.phoneCodeErrorMessage;

    const [phoneLast, setPhoneLast] = useState("");
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [codeRequestTimeOut, setCodeRequestTimeOut] = useState(false);
    const [timeOut, setTimeOut] = useState(-1);
    const [codeTimerId, setCodeTimerId] = useState(0);

    const inputBlockClassnames = classNames({
        'input-block': true,
        'input-block--hidden': phoneCodeValid,
    });

    const inputBlockDoneClassnames = classNames({
        'input-block': true,
        'input-block--hidden': !phoneCodeValid,
    });

    const phoneInputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--hidden': showCodeInput,
        'input-block__input--grey': true,
        'input-block__input--error': validationFailed,
    });

    const phoneCodeInputClassnames = classNames({
        'input-block__input': true,
        'input-block__input--hidden': !showCodeInput,
        'input-block__input--grey': true,
        'input-block__input--loading': false,
        'input-block__input--error': phoneCodeError,
    });


    const errorClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': validationFailed,
    });

    const errorCodeClassnames = classNames({
        'input-block__error': true,
        'input-block__error--visible': phoneCodeError || sendPhoneCodeError,
    });

    const handleSendNewPhoneCode = (phone) => {

        // const trace = btoa(Date.now());
        const date = new Date();
        const trace = (parseInt(date.getHours()) + 1) * 1500;

        dispatch(sendPhoneCode({ phone, locale: i18n.language, trace: trace }));
    }

    const handleCodeInput = (event) => {
        const code = event.target.value;
        const len = code.toString().length;

        setFieldValue(`${props.name}Code`, code);

        if (len === 4)
        {
            /* send code */
            dispatch(checkPhoneCode({
                phone: phoneLast,
                code: code,
                id: phoneCodeAssetID
            }))
        }

    }

    useEffect(() => {
        if (!skipvalidation && !meta.error) {
            if (phoneCodeCheckEnabled) {
                if (phoneLast !== meta.value) {
                    setPhoneLast(meta.value);
                    if (!phoneCodeRequested) {
                        handleSendNewPhoneCode(meta.value);
                    }
                }
            }
        }
    }, [meta]);

    useEffect(() => {
        if (phoneCodeRequested) {
            setTimeOut(60);
            setShowCodeInput(true);
            // if (codeTimerId) {
            //     clearInterval(codeTimerId);
            //     setCodeTimerId(0);
            // }

            // console.log('rerender');
            //
            // const timer = setInterval(() => {
            //     console.log('timeOut', timeOut);
            //     if (timeOut > 0) {
            //         setTimeOut(counter => counter - 1);
            //         console.log('timeOut', timeOut);
            //     }
            // }, 1000);
            //
            // return () => clearInterval(timer); //This is important

            // setCodeTimerId(timer);
        }
    }, [phoneCodeRequested]);

    const timer = () => setTimeOut(timeOut - 1);

    useEffect(() => {
        if (timeOut <= 0) {
            return;
        }

        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    });

    useEffect(() => {
        if (timeOut === 0) {
            dispatch(resetPhoneCodeTimeout());
            clearInterval(codeTimerId);
        }
    }, [timeOut]);

    useEffect(() => {
       dispatch(clearPhoneCodeErrors());
    }, []);

    return (
        <div className='input-block'>
            <div className={inputBlockClassnames}>
                <div className='input-block__label'>
                    {
                        showCodeInput? <label htmlFor='code'>{t('common.checkCode')}</label> : <label htmlFor={props.name}>{props.label}</label>
                    }
                </div>
                <div className={phoneInputClassnames}>
                    <InputMask
                        id={props.name}
                        type='tel'
                        mask='+7 (999) 999-99-99'
                        {...props}
                        {...field}
                    />
                </div>
                <div className={phoneCodeInputClassnames}>
                    <input
                        id={`${props.name}Code`}
                        name={`${props.name}Code`}
                        type='text'
                        onChange={handleCodeInput}
                        // onBlur={formik.handleBlur}
                        // value={formik.values.code}
                    />
                </div>
                <span className={errorCodeClassnames}>
                    {
                        phoneCodeError? t('formValidation.incorrectCode') : errorMessage
                    }
                </span>
                {
                    showCodeInput?
                        timeOut > 0?
                            <span className='base-text-grey mt-1 is-block'>{t('common.checkCodeTimeout', {timeOut: timeOut})}</span>
                            :
                            <a className='input-block__button--link effect3 mt-1' onClick={() => handleSendNewPhoneCode(meta)}> {t('common.repeatCheckCode')}</a>
                        : null
                }
                <span className={errorClassnames}>{meta.error}</span>
            </div>
            <div className={inputBlockDoneClassnames}>
                <div className='input-block__label'>
                    <label htmlFor={props.name}>{t('common.phoneChecked')}</label>
                </div>
                <div className='input-block__input input-block__input--grey'>
                    <input
                        id='fakePhone'
                        name='fakePhone'
                        type='tel'
                        value={meta.value}
                        readOnly
                    />
                </div>
            </div>

        </div>
    )
}

export default InputBlockPhone;
