import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as Certificate } from '../../svg/certificate.svg';
import amplitude from 'amplitude-js';
import { useTranslation } from "react-i18next";

function PromoBlockCertificate() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);

  const firstBlockClassnames = classNames({
    'promo__block': true,
    // 'promo__block--hidden-on-desktop': isAuthorized,
    'promo__block--touched': tapped
  });

  useEffect(() => {
    if (tapped) {
      amplitude.getInstance().logEvent('buttom_main_special_offer', {
        offer_name: 'sale_delivery'
      });
    }
  }, [tapped]);

  return (
    <div className={firstBlockClassnames} onClick={() => toggleTap(!tapped)}>
      <div className='promo__block-inner'>
        <div className='promo__block-first'>
          <div className='promo__block-text'>
            {t('promo.blockCertificate.front_title')}
          </div>
          <div className='promo__block-certificate-icon'>
            <Certificate />
          </div>
        </div>
        <div className='promo__block-second'>
          <div className='promo__block-second-header'>
            {t('promo.blockCertificate.back_title')}
          </div>
          <div className='promo__block-second-text'>
            {t('promo.blockCertificate.back_description')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockCertificate;
