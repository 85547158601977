import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { YMaps, withYMaps, Map, ZoomControl, SearchControl, Placemark } from 'react-yandex-maps';
import {
  getPolygons,
  geoJsonSelector,
  restaurantsSelector,
  deliveryTimeSelector,
  getDeliveryTime,
  resetDeliveryTime
} from '../../ducks/map';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as DeliveryIconEn } from '../../svg/delivery-header-en.svg';
import { ReactComponent as DeliveryIconRu } from '../../svg/delivery-header-ru.svg';
import {useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";


function MapDeliveryComponent(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ymaps } = props;
  const geoJson = useSelector(geoJsonSelector);
  const restaurants = useSelector(restaurantsSelector);
  const deliveryTime = useSelector(deliveryTimeSelector);
  const [resultMessage, setResultMessage] = useState(false);
  let map = null;
  let deliveryZones = null;
  let deliveryPoint = null;

  function createPlaceLayout() {
    return ymaps.templateLayoutFactory.createClass(
      '<div style="color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
    );
  }

  function setData(obj, polygon){
    const layout = createPlaceLayout();
    let address = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');
    if (address.trim() === '') {
      address = obj.getAddressLine();
    }
    let price = polygon.properties.get('description');
    price = price.match(/<strong>(.+)<\/strong>/)[1];
    deliveryPoint.properties.set({
      iconCaption: address,
      balloonContent: address,
      iconContent: price
    });
    deliveryPoint.options.set({
      iconLayout: 'default#imageWithContent',
      iconImageHref: '/img/icon-place.png',
      iconImageSize: [30, 42],
      iconImageOffset: [-15, -15],
      iconContentOffset: [4, 8],
      iconContentLayout: layout
    });
    setResultMessage(t('page.delivery.price', { price: price}));
  }

  const validateAddress = (precision) => {
    let error;
    let hint;
    switch (precision) {
      case 'exact':
        break;
      case 'number':
      case 'near':
      case 'range':
        error = t('formValidation.notPrecisionAddress');
        hint = t('formValidation.specifyHouseNumber');
        break;
      case 'street':
        error = t('formValidation.notFullAddress');
        hint = t('formValidation.specifyHouseNumber');
        break;
      case 'other':
      default:
        error = t('formValidation.notPrecisionAddress');
        hint = t('formValidation.specifyAddress');
    }

    if (error) {
      setResultMessage( error + '. ' + hint);
      return false;
    }
    return true;
  }

  const highlightAddress = (value) => {
    ymaps.geocode(value).then(function (res)
    {
      let obj = res.geoObjects.get(0);
      if (obj && validateAddress(obj.properties.get('metaDataProperty.GeocoderMetaData.precision')))
      {
        let coords = obj.geometry.getCoordinates();
        let polygon = deliveryZones.searchContaining(coords).get(0);
        if (polygon) {
          // deliveryZones.setOptions('fillOpacity', 0.4);
          // polygon.options.set('fillOpacity', 0.8);
          // Перемещаем метку с подписью в переданные координаты и перекрашиваем её в цвет полигона.
          deliveryPoint.geometry.setCoordinates(coords);
          deliveryPoint.options.set('iconColor', polygon.properties.get('fill'));
          // Задаем подпись для метки.
          if (typeof(obj.getThoroughfare) === 'function') {
            setData(obj, polygon);
            dispatch(getDeliveryTime(coords));
          } else {
            // Если вы не хотите, чтобы при каждом перемещении метки отправлялся запрос к геокодеру,
            // закомментируйте код ниже.
            // ymaps.geocode(coords, {results: 1}).then(function (res) {
            //   var obj = res.geoObjects.get(0);
            //   setData(obj);
            // });
          }
        } else {
          // deliveryZones.setOptions('fillOpacity', 0.4);
          deliveryPoint.geometry.setCoordinates(coords);
          // Задаём контент балуна и метки.
          deliveryPoint.properties.set({
            iconCaption: 'Доставка не возможна',
            balloonContent: 'Выберите другой адрес',
            balloonContentHeader: '',
            iconContent: ''
          });
          deliveryPoint.options.set('iconColor', 'red');
          setResultMessage(t('page.delivery.notAvailable'));
          dispatch(resetDeliveryTime());
        }
      } else {
        dispatch(resetDeliveryTime());
      }
    });
  };

  useEffect(() => {
    const suggestView = new ymaps.SuggestView('suggest', {
      provider: {
        suggest: (request, options) => ymaps.suggest(`Москва, ${request}`)
      },
      results: 3
    });

    suggestView.events.add('select', (e) => {
      highlightAddress(e.get('item').value);
    });
  }, [ymaps.SuggestView]);

  useEffect(() => {
    map = new ymaps.Map("map", {
      center: [55.753220, 37.622513],
      zoom: 10,
      controls: [],
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      map.behaviors.disable('drag');
    }

    const zoomControl = new ymaps.control.ZoomControl({
      options: {
        size: 'small',
        float: 'none',
        position: {
          bottom: 200,
          right: 10
        }
      }
    });
    map.controls.add(zoomControl);

    deliveryPoint = new ymaps.GeoObject({
      geometry: {type: 'Point'},
      properties: {
        iconCaption: 'Адрес',
      }
    }, {
      preset: 'default#imageWithContent',
      draggable: false,
      iconCaptionMaxWidth: '215'
    });

    map.geoObjects.add(deliveryPoint);
  }, [ymaps.Map]);

  useEffect(() => {
    if (map && restaurants) {
      restaurants.forEach(v => {
        const name = v.name.split(' ')[0];
        const namePosition = (name.length/2 * -3);
        const item = new ymaps.Placemark([v.lat, v.lon], {
          iconContent: name,
        }, {
          iconLayout: 'default#imageWithContent',
          iconImageHref: '/img/icon-restaurant.png',
          iconImageSize: [30, 30],
          iconImageOffset: [-15, -15],
          iconContentOffset: [namePosition, 30],
        });
        map.geoObjects.add(item);
      });
    }
  }, [restaurants, map]);

  const clearMessage = () => {
    setResultMessage(false);
  }

  useEffect(() => {
    if (geoJson && map && ymaps) {
      deliveryZones = ymaps.geoQuery(geoJson).addToMap(map);
      deliveryZones.each(function (obj) {
        obj.options.set({
          fillColor: obj.properties.get('fill'),
          fillOpacity: obj.properties.get('fill-opacity'),
          strokeColor: obj.properties.get('stroke'),
          strokeWidth: obj.properties.get('stroke-width'),
          strokeOpacity: obj.properties.get('stroke-opacity')
        });
        obj.properties.set('balloonContent', obj.properties.get('description'));
      });
    }
  },[geoJson, map]);

  const inputClassnames = classNames({
    'input-block__input': true,
    'input-block__input--grey': true,
    'input-block__input--error': false,
  });

  const resultClassnames = classNames({
    'delivery__banner-result': true,
    'delivery__banner-result-appear': !!resultMessage,
  });

  return (
    <div>
      <div className='delivery__container-search-input'>
        <div className={inputClassnames}>
          <div className="input-block__label"><label htmlFor="suggest">{t('page.delivery.address')}</label></div>
          <input id='suggest' onClick={clearMessage}/>
        </div>
        <div className={resultClassnames}>
          {resultMessage}
          {
            deliveryTime? t('page.delivery.time', { time: deliveryTime}) : null
          }
        </div>
      </div>
      <div id="map" className='delivery__map'></div>
    </div>
  );
}

function DeliveryPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const geoJson = useSelector(geoJsonSelector);

  useEffect(() => {
    if (showModal) {
      dispatch(getPolygons());
    }
  }, [showModal]);

  const aboutClassnames = classNames({
    'page-sheet': true,
    'page-sheet--visible': showModal
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  const MapComponent = useMemo(() => {
    return withYMaps(MapDeliveryComponent, true, [
      'Map',
      'Event',
      'control.ZoomControl',
      'SuggestView',
      'suggest',
      'Placemark',
      'GeoObject',
      'layout.ImageWithContent',
      'templateLayoutFactory',
      'geoQuery',
      'geocode',
      'Polygon',
      'geoObject.addon.balloon',
      'geoObject.addon.hint'
    ]);
  }, [geoJson]);

  return (
    <div className={aboutClassnames}>
      <div className='page-sheet__wrapper'>
        <div className='delivery'>

          <div className='delivery__banner'>
            <div className="delivery__banner-column">
              <div className='delivery__banner-header'>
                {
                  i18n.language === 'en'? <DeliveryIconEn/>:<DeliveryIconRu/>
                }
              </div>
            </div>
            <div className='delivery__banner-bottom-text'>
              <p>
                {t('page.delivery.deliveryTimeLine1')}
                <br/>
                {t('page.delivery.deliveryTimeLine2')}
                <br/>
                {t('page.delivery.deliveryTimeLine3')}
                <br/>
                <a href='tel:+74958466337' className='base-text effect3 no-flick'>+7 (495) 846-63-37</a>
              </p>
              <p>
                {t('page.delivery.descriptionLine1')}
              </p>
              <p>
                {t('page.delivery.descriptionLine2')}
              </p>
            </div>
          </div>

          <div className='delivery__map-container'>
            <YMaps query={{
              apikey: 'c59f88c5-098c-4fea-8988-e89a93712b5c',
              suggest_apikey: '181d31c5-3415-4b0c-a767-c50289e8de24' }}>
              <MapComponent/>
            </YMaps>
          </div>

        </div>
      </div>
      <div className='page-sheet--close '>
        <button className='effect' onClick={handleCloseModal}>
          <CloseIcon title='Закрыть'/> {t('page.close')}
        </button>
      </div>
    </div>
  );
}

export default DeliveryPage;
