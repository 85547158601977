import apiService from '../services';
import { useSelector } from 'react-redux';
import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { cleanPhone } from './../utils'
import amplitude from 'amplitude-js';
import ReactPixel from "react-facebook-pixel";

function sendAmplitudeEvent(event, params) {
  if (params) {
    amplitude.getInstance().logEvent(event, params);
  } else {
    amplitude.getInstance().logEvent(event);
  }
}

function sendFBPixelEvent(event, params) {
  ReactPixel.trackCustom(event, params);
}

// function getUserFromStorage() {
//   return JSON.parse(localStorage.getItem('authToken'))
// }
//
// function setUserInStorage(user) {
//   localStorage.setItem('user', JSON.stringify(user))
// }
//
// function removeUserFromStorage() {
//   localStorage.removeItem('user')
// }
//
// function getTokenFromStorage() {
//   return JSON.parse(localStorage.getItem('userToken'))
// }
//
// function setTokenFromStorage(user) {
//   localStorage.setItem('userToken', JSON.stringify(user))
// }
//
// function removeTokenFromStorage() {
//   localStorage.removeItem('userToken')
// }

/**
 * Constants
 * */
export const moduleName = 'auth';
// const prefix = `${appName}/${moduleName}`;
const prefix = 'auth';

export const SIGN_IN_REQUEST = `${prefix}/SIGN_IN_REQUEST`;
export const SIGN_IN_START = `${prefix}/SIGN_IN_START`;
export const SIGN_IN_SUCCESS = `${prefix}/SIGN_IN_SUCCESS`;
export const SIGN_IN_ERROR = `${prefix}/SIGN_IN_ERROR`;

export const SIGN_OUT_REQUEST = `${prefix}/SIGN_OUT_REQUEST`;
export const SIGN_OUT_START = `${prefix}/SIGN_OUT_START`;
export const SIGN_OUT_SUCCESS = `${prefix}/SIGN_OUT_SUCCESS`;
export const SIGN_OUT_ERROR = `${prefix}/SIGN_OUT_ERROR`;

export const REGISTER_REQUEST = `${prefix}/REGISTER_REQUEST`;
export const REGISTER_START = `${prefix}/REGISTER_START`;
export const REGISTER_SUCCESS = `${prefix}/REGISTER_SUCCESS`;
export const REGISTER_ERROR = `${prefix}/REGISTER_ERROR`;
export const REGISTER_ERROR_USER_EXIST = `${prefix}/REGISTER_ERROR_USER_EXIST`;

export const JOIN_REQUEST = `${prefix}/JOIN_REQUEST`;
export const JOIN_START = `${prefix}/JOIN_START`;
export const JOIN_SUCCESS = `${prefix}/JOIN_SUCCESS`;
export const JOIN_ERROR = `${prefix}/JOIN_ERROR`;

export const RESTORE_PWD_REQUEST = `${prefix}/RESTORE_PWD_REQUEST`;
export const RESTORE_PWD_START = `${prefix}/RESTORE_PWD_START`;
export const RESTORE_PWD_SUCCESS = `${prefix}/RESTORE_PWD_SUCCESS`;
export const RESTORE_PWD_ERROR = `${prefix}/RESTORE_PWD_ERROR`;

export const RESET_PWD_HASH_REQUEST = `${prefix}/RESET_PWD_HASH_REQUEST`;
export const RESET_PWD_HASH_START = `${prefix}/RESET_PWD_HASH_START`;
export const RESET_PWD_HASH_SUCCESS = `${prefix}/RESET_PWD_HASH_SUCCESS`;
export const RESET_PWD_HASH_ERROR = `${prefix}/RESET_PWD_HASH_ERROR`;

export const GET_PROFILE_REQUEST = `${prefix}/GET_PROFILE_REQUEST`;
export const GET_PROFILE_START = `${prefix}/GET_PROFILE_START`;
export const GET_PROFILE_SUCCESS = `${prefix}/GET_PROFILE_SUCCESS`;
export const GET_PROFILE_ERROR = `${prefix}/GET_PROFILE_ERROR`;

export const UPDATE_PROFILE_REQUEST = `${prefix}/UPDATE_PROFILE_REQUEST`;
export const UPDATE_PROFILE_START = `${prefix}/UPDATE_PROFILE_START`;
export const UPDATE_PROFILE_SUCCESS = `${prefix}/UPDATE_PROFILE_SUCCESS`;
export const UPDATE_PROFILE_ERROR = `${prefix}/UPDATE_PROFILE_ERROR`;

export const GET_USER_ORDERS_REQUEST = `${prefix}/GET_USER_ORDERS_REQUEST`;
export const GET_USER_ORDERS_START = `${prefix}/GET_USER_ORDERS_START`;
export const GET_USER_ORDERS_SUCCESS = `${prefix}/GET_USER_ORDERS_SUCCESS`;
export const GET_USER_ORDERS_ERROR = `${prefix}/GET_USER_ORDERS_ERROR`;

export const GET_ALL_USER_ORDERS_REQUEST = `${prefix}/GET_ALL_USER_ORDERS_REQUEST`;
export const GET_ALL_USER_ORDERS_START = `${prefix}/GET_ALL_USER_ORDERS_START`;
export const GET_ALL_USER_ORDERS_SUCCESS = `${prefix}/GET_ALL_USER_ORDERS_SUCCESS`;
export const GET_ALL_USER_ORDERS_ERROR = `${prefix}/GET_ALL_USER_ORDERS_ERROR`;

export const UPDATE_ADDRESS_REQUEST = `${prefix}/UPDATE_ADDRESS_REQUEST`;
export const UPDATE_ADDRESS_START = `${prefix}/UPDATE_ADDRESS_START`;
export const UPDATE_ADDRESS_SUCCESS = `${prefix}/UPDATE_ADDRESS_SUCCESS`;
export const UPDATE_ADDRESS_ERROR = `${prefix}/UPDATE_ADDRESS_ERROR`;

export const ADD_ADDRESS_REQUEST = `${prefix}/ADD_ADDRESS_REQUEST`;
export const ADD_ADDRESS_START = `${prefix}/ADD_ADDRESS_START`;
export const ADD_ADDRESS_SUCCESS = `${prefix}/ADD_ADDRESS_SUCCESS`;
export const ADD_ADDRESS_ERROR = `${prefix}/ADD_ADDRESS_ERROR`;

export const DELETE_ADDRESS_REQUEST = `${prefix}/DELETE_ADDRESS_REQUEST`;
export const DELETE_ADDRESS_START = `${prefix}/DELETE_ADDRESS_START`;
export const DELETE_ADDRESS_SUCCESS = `${prefix}/DELETE_ADDRESS_SUCCESS`;
export const DELETE_ADDRESS_ERROR = `${prefix}/DELETE_ADDRESS_ERROR`;

export const RESET_USER_SUCCESS_REQUEST = `${prefix}/RESET_USER_SUCCESS_REQUEST`;
export const RESET_USER_SUCCESS_START = `${prefix}/RESET_USER_SUCCESS_START`;

export const SET_REFERER = `${prefix}/SET_REFERER`;
export const RESET_USER_ERRORS = `${prefix}/RESET_USER_ERRORS`;
export const RESET_RECOVER = `${prefix}/RESET_RECOVER`;
/**
 * Reducer
 * */

const initialState = {
  user: JSON.parse(localStorage.getItem('user')),
  token: localStorage.getItem('userToken') !== 'undefined' ? JSON.parse(localStorage.getItem('userToken')) : null,
  orders: [],
  ordersTotal: 0,
  loading: false,
  ordersLoading: false,
  restore: false,
  error: false,
  errorMessage: '',
  successMessage: '',
  customError: '', /* ToDo deprecated */
  success: false,
  referer: localStorage.getItem('referer'),
  registered: false,
  asset: null,
  channel: ''
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGN_IN_START:
      return {...state, loading: true, error: false, errorMessage: '', success: false };
    case SIGN_IN_SUCCESS:
      return {...state, user: payload.user, token: payload.token, loading: false };
    case SIGN_IN_ERROR:
      return {...state, loading: false, error: true, errorMessage: payload.error };
    case SIGN_OUT_START:
      return {...state, loading: true, error: false };
    case SIGN_OUT_SUCCESS:
      return {...state, user: null, token: null, orders: [], ordersTotal: 0, loading: false, ordersLoading: false, restore: false, error: false, success: false };
    case REGISTER_START:
      return {...state, loading: true, success: false, error: false, registered: false };
    case REGISTER_SUCCESS:
      return {...state, registered: true };
    case REGISTER_ERROR:
      return {...state, loading: false, error: true, errorMessage: payload.error };
    case REGISTER_ERROR_USER_EXIST:
      return {...state, loading: false, error: 'userExist' };

    case JOIN_START:
      return {...state, loading: true, success: false, error: false };
    // case JOIN_SUCCESS:
    //   return {...state, loading: true, success: false, error: false };
    case JOIN_ERROR:
      return {...state, loading: false, error: true, customError: payload.customError };

    case RESTORE_PWD_START:
      return {...state, loading: true, error: false, errorMessage: '', success: false };
    case RESTORE_PWD_SUCCESS:
      return {...state, loading: false, successMessage: payload.message, channel: payload.channel, asset: payload.asset, error: false, success: true };
    case RESTORE_PWD_ERROR:
      return {...state, loading: false, errorMessage: payload.error, error: true  };

    case RESET_PWD_HASH_START:
      return {...state, loading: true, error: false, errorMessage: '', successMessage: '', success: false };
    case RESET_PWD_HASH_SUCCESS:
      return {...state, loading: false, successMessage: payload.message, channel: '', asset: null,  success: true };
    case RESET_PWD_HASH_ERROR:
      return {...state, loading: false, error: true, errorMessage: payload.error };

    case GET_PROFILE_START:
      return {...state, loading: true, error: false };
    case GET_PROFILE_SUCCESS:
      return {...state, user: payload.user, loading: false };
    case UPDATE_PROFILE_START:
      return {...state, loading: true, error: false, success: false };
    case UPDATE_PROFILE_SUCCESS:
      return {...state, user: payload.newUserData, loading: false, success: true };
    case UPDATE_PROFILE_ERROR:
      return {...state, loading: false, error: false };
    case GET_USER_ORDERS_START:
      return {...state, ordersLoading: true };
    case GET_USER_ORDERS_SUCCESS:
      return {...state, orders: payload.orders, ordersTotal: payload.ordersTotal, ordersLoading: false };
    case GET_ALL_USER_ORDERS_START:
      return {...state, ordersLoading: true };
    case GET_ALL_USER_ORDERS_SUCCESS:
      return {...state, orders: payload.orders, ordersLoading: false };
    case UPDATE_ADDRESS_START:
      return {...state, loading: true, error: false, success: false };
    case UPDATE_ADDRESS_SUCCESS:
      return {...state, user: payload.newUserData, loading: false, success: true };
    case UPDATE_ADDRESS_ERROR:
      return {...state, loading: false, error: true };
    case ADD_ADDRESS_START:
      return {...state, loading: true, error: false, success: false };
    case ADD_ADDRESS_SUCCESS:
      return {...state, user: payload.newUserData, loading: false, success: true, error: false };
    case ADD_ADDRESS_ERROR:
      return {...state, loading: false, error: true };
    case DELETE_ADDRESS_START:
      return {...state, loading: true, error: false, success: false };
    case DELETE_ADDRESS_SUCCESS:
      return {...state, user: payload.newUserData, loading: false, success: true, error: false };
    case DELETE_ADDRESS_ERROR:
      return {...state, loading: false, error: true };
    case RESET_USER_SUCCESS_START:
      return {...state, success: false, error: false, loading: false };
    case SET_REFERER:
      return {...state, referer: payload };
    case RESET_USER_ERRORS:
      return {...state, success: false, error: false };
    case RESET_RECOVER:
      return {...state, success: false, error: false , loading: false, channel: '', asset: null };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const userSelector = (state) => state[moduleName].user;
export const tokenSelector = (state) => state[moduleName].token;
export const loadingSelector = (state) => state[moduleName].loading;
export const successSelector = (state) => state[moduleName].success;
export const errorSelector = (state) => state[moduleName].error;
export const errorMessageSelector = (state) => state[moduleName].errorMessage;
export const customErrorSelector = (state) => state[moduleName].customError;
export const ordersLoadingSelector = (state) => state[moduleName].ordersLoading;
export const restoreSelector = (state) => state[moduleName].restore;
export const ordersSelector = (state) => state[moduleName].orders;
export const ordersTotalSelector = (state) => state[moduleName].ordersTotal;
export const registeredSelector = (state) => state[moduleName].registered;
export const successMessageSelector = (state) => state[moduleName].successMessage;
export const channelSelector = (state) => state[moduleName].channel;
export const assetSelector = (state) => state[moduleName].asset;

/**
 * Custom Hooks
 * */

export const useUserInfo = () => {
  const user = useSelector(userSelector);
  return user;
};

export const useUserToken = () => {
  const token = useSelector(tokenSelector);
  return token;
}

export const useAuthorized = () => {
  const user = useSelector(userSelector);
  return !!user;
};

export const useUserError = () => {
  const error = useSelector(errorSelector);
  return error;
};

export const useUserErrorMessage = () => {
  const error = useSelector(errorMessageSelector);
  return error;
};

export const useUserCustomError = () => {
  const error = useSelector(customErrorSelector);
  return error;
};

export const useUserSuccess = () => {
  const success = useSelector(successSelector);
  return success;
};

export const useSuccessMessage = () => {
  const message = useSelector(successMessageSelector);
  return message;
};

export const useUserLoading = () => {
  const loading = useSelector(loadingSelector);
  const ordersLoading = useSelector(ordersLoadingSelector);
  return loading || ordersLoading;
};

export const useUserOrders = () => {
  const orders = useSelector(ordersSelector);
  return orders;
};

export const useUserOrdersTotal = () => {
  const orderTotal = useSelector(ordersTotalSelector);
  return orderTotal;
};

export const useRegistered = () => {
  const registered = useSelector(registeredSelector);
  return registered;
};
export const useChannel= () => {
  const channel = useSelector(channelSelector);
  return channel;
};

export const useAsset = () => {
  const asset = useSelector(assetSelector);
  return asset;
};

/**
 * Action Creators
 * */

export const login = (payload) => ({
  type: SIGN_IN_REQUEST,
  payload: payload,
});

export const logout = (payload) => ({
  type: SIGN_OUT_REQUEST,
  payload: payload,
});

export const register = (payload) => ({
  type: REGISTER_REQUEST,
  payload: payload,
});

export const restorePwd = (payload) => ({
  type: RESTORE_PWD_REQUEST,
  payload: payload,
});

export const resetPasswordByHash = (payload) => ({
  type: RESET_PWD_HASH_REQUEST,
  payload: payload,
});

export const getProfile = (payload) => ({
  type: GET_PROFILE_REQUEST,
  payload: payload,
});

export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: payload,
});

export const getOrders = (payload) => ({
  type: GET_USER_ORDERS_REQUEST,
  payload: payload,
});

export const getAllOrders = (payload) => ({
  type: GET_ALL_USER_ORDERS_REQUEST,
  payload: payload,
});

export const updateAddress = (payload) => ({
  type: UPDATE_ADDRESS_REQUEST,
  payload: payload,
});

export const resetUserSuccess = () => ({
  type: RESET_USER_SUCCESS_REQUEST
});

export const resetUserErrors = () => ({
  type: RESET_USER_ERRORS
});

export const addNewAddress = (payload) => ({
  type: ADD_ADDRESS_REQUEST,
  payload: payload,
});

export const deleteAddress = (payload) => ({
  type: DELETE_ADDRESS_REQUEST,
  payload: payload,
});

export const joinUser = (payload) => ({
  type: JOIN_REQUEST,
  payload: payload
});

export const setReferer = (payload) => ({
  type: SET_REFERER,
  payload: payload
});

export const resetRecove = () => ({
  type: RESET_RECOVER
});

/**
 * Sagas
 * */

export const loginSaga = function* ({ payload }) {
  try {
    yield put({
      type: SIGN_IN_START
    });

    const request = yield call(apiService.doLogin, payload);

    yield localStorage.setItem('user', JSON.stringify(request.data));
    yield localStorage.setItem('userToken', JSON.stringify(request.data.token));

    yield call(sendAmplitudeEvent, 'authorization_come_in', {
      'user_e-mail': payload.email
    });

    yield call(sendFBPixelEvent, 'authorization_come_in', {
      'user_e-mail': payload.email
    });

    yield put({
      type: SIGN_IN_SUCCESS,
      payload: { user: request.data, token: request.data.token }
    });
  } catch (error) {
    const cError = error.response.data.error_description;
    yield put({
      type: SIGN_IN_ERROR,
      payload: { error: cError }
    });
  }
};

export const logoutSaga = function* ({ payload }) {
  try {
    yield put({
      type: SIGN_OUT_START
    });

    yield localStorage.removeItem('user');
    yield localStorage.removeItem('userToken');

    yield put({
      type: SIGN_OUT_SUCCESS
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const registerSaga = function* ({ payload }) {
  try {
    yield put({
      type: REGISTER_START
    });

    const { regFirstName, regLastName, regBirthday, regGender, regEmail,
      regPhone, regPhoneCode, regPassword, regAgreementProcessing, regAgreementMarketing,
      locale, phoneCodeAssetID, referer } = payload;

    const dataToRegister = {
      first_name: regFirstName,
      last_name: regLastName,
      gender: regGender,
      birthday: regBirthday,
      email: regEmail,
      phone: cleanPhone(regPhone),
      code: regPhoneCode,
      password: regPassword,
      agreement_processing: regAgreementProcessing,
      agreement_marketing: regAgreementMarketing,
      assets_id: phoneCodeAssetID,
      referer: referer,
      locale: locale
    }

    localStorage.setItem('hideNotifyRegistered', 0);

    const request = yield call(apiService.doRegister, dataToRegister);

    if (request.data.error && request.data.error_description && request.data.error_description === 'User exist!') {
      yield put({
        type: REGISTER_ERROR_USER_EXIST
      });
    } else {
      yield call(sendAmplitudeEvent, 'reg_success', {
        user_first_name: regFirstName,
        user_last_name: regLastName,
        user_cell: cleanPhone(regPhone),
        'user_e-mail': regEmail
      });

      yield call(sendFBPixelEvent, 'reg_success', {
        user_first_name: regFirstName,
        user_last_name: regLastName,
        user_cell: cleanPhone(regPhone),
        'user_e-mail': regEmail
      });

      // const doLogin = yield call(apiService.doLogin, { email: request.data.login, password: request.data.password });

      yield call(sendAmplitudeEvent, 'authorization_come_in', {
        'user_e-mail': regEmail
      });

      yield call(sendFBPixelEvent, 'authorization_come_in', {
        'user_e-mail': regEmail
      });

      yield localStorage.setItem('user', JSON.stringify(request.data));
      yield localStorage.setItem('userToken', JSON.stringify(request.data.token));

      yield put({
        type: REGISTER_SUCCESS
      });

      yield put({
        type: SIGN_IN_SUCCESS,
        payload: { user: request.data, token: request.data.token }
      });
    }
  } catch (error) {
    const cError = error.response.data.error_description;
    yield put({
      type: REGISTER_ERROR,
      payload: { error: cError }
    });
  }
};

export const restorePwdSaga = function* ({ payload }) {
  try {
    yield put({
      type: RESTORE_PWD_START
    });

    const response = yield call(apiService.doRestorePwd, { login: payload.login, locale: payload.locale });

    if (response.data.channel_type === 'sms') {
      yield put({
        type: RESTORE_PWD_SUCCESS,
        payload: {
          channel: response.data.channel_type,
          message: response.data.sent_message,
          asset: response.data.asset_id
        }
      });
    } else {
      yield put({
        type: RESTORE_PWD_SUCCESS,
        payload: {
          channel: response.data.channel_type,
          message: response.data.sent_message
        }
      });
    }
  } catch (error) {
    const cError = error.response.data.error_description;
    yield put({
      type: RESTORE_PWD_ERROR,
      payload: { error: cError }
    });
  }
};

export const resetPwdHashSaga = function* ({ payload }) {
  try {
    yield put({
      type: RESET_PWD_HASH_START
    });

    const response = yield call(apiService.doResetPwdHash, payload);

    yield put({
      type: RESET_PWD_HASH_SUCCESS,
      payload: { message: response.data.message }
    });

  } catch (error) {
    const cError = error.response.data.error_description;
    yield put({
      type: RESET_PWD_HASH_ERROR,
      payload: { error: cError }
    });
  }
};

export const getProfileSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_PROFILE_START
    });

    const request = yield call(apiService.getProfile, payload);

    yield put({
      type: GET_PROFILE_SUCCESS,
      payload: { user: request.data }
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put({
        type: SIGN_OUT_REQUEST
      });
    }
  }
};

export const getOrdersSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_USER_ORDERS_START
    });

    const request = yield call(apiService.getOrders, payload);

    yield put({
      type: GET_USER_ORDERS_SUCCESS,
      payload: { orders: request.data.orders, ordersTotal: request.data.orders_total }
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const getAllOrdersSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_ALL_USER_ORDERS_START
    });

    const request = yield call(apiService.getOrders, payload);

    yield put({
      type: GET_ALL_USER_ORDERS_SUCCESS,
      payload: { orders: request.data.orders }
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const updateProfileSaga = function* ({ payload }) {
  try {
    yield put({
      type: UPDATE_PROFILE_START
    });

    const { userToken } = payload;
    const { editFirstName, editLastName, editGender, editBirthday, editPhone, editEmail } = payload.profile;
    const currentUserData = yield select(userSelector);

    const newUserData = {
      ...currentUserData
    };

    newUserData.first_name = editFirstName;
    newUserData.last_name = editLastName;
    newUserData.gender = editGender;
    newUserData.birthday = editBirthday;
    newUserData.email = currentUserData.email.slice();
    newUserData.phone = currentUserData.phone.slice();
    if (newUserData.email.length) {
      newUserData.email[0].value = editEmail;
    } else {
      newUserData.email.push({value: editEmail})
    }
    if (newUserData.phone.length) {
      newUserData.phone[0].value = cleanPhone(editPhone);
    } else {
      newUserData.phone.push({value: cleanPhone(editPhone)})
    }

    const request = yield call(apiService.updateProfile, { token: userToken, newUserData: newUserData});

    if (request.data.success) {
      yield localStorage.setItem('user', JSON.stringify(newUserData));

      yield put({
        type: UPDATE_PROFILE_SUCCESS,
        payload: { newUserData: newUserData }
      });
    } else {
      yield put({
        type: UPDATE_PROFILE_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_PROFILE_ERROR,
    });
  }
};

export const updateAddressSaga = function* ({ payload }) {
  try {
    yield put({
      type: UPDATE_ADDRESS_START
    });

    const { editAddress, editEntrance, editIntercom, editFloor, editFlat, editComment, addressId, userToken } = payload;

    const currentUserData = yield select(userSelector);

    const newUserData = {
      ...currentUserData
    };

    newUserData.address[addressId].data.street = editAddress;
    // newUserData.address[addressId].data.dom = ;
    newUserData.address[addressId].data.podezd = editEntrance;
    newUserData.address[addressId].data.etazh = editFloor;
    newUserData.address[addressId].data.kvartira = editFlat;
    newUserData.address[addressId].data['kod-domofona'] = editIntercom;
    newUserData.address[addressId].data.comment = editComment;

    yield call(apiService.updateProfile, { token: userToken, newUserData: newUserData});

    yield put({
      type: UPDATE_ADDRESS_SUCCESS,
      payload: { newUserData: newUserData }
    });
  } catch (error) {
    yield put({
      type: UPDATE_ADDRESS_ERROR,
    });
  }
};

export const addNewAddressSaga = function* ({ payload }) {
  try {
    yield put({
      type: ADD_ADDRESS_START
    });

    const { addAddress, addEntrance, addIntercom, addFloor, addFlat, addComment, userToken } = payload;

    const newAddress = [
      {
        data: {
            kvartira: addFlat,
            etazh: addFloor,
            podezd: addEntrance,
            street: addAddress,
            city: 'Москва',
            region: '77',
            country: 'rus'
        },
        ext: 'shipping'
      }
    ];

    if (addIntercom) {
      newAddress[0].data['kod-domofona'] = addIntercom;
    }

    if (addComment) {
      newAddress[0].data.comment = addComment;
    }

    const req = yield call(apiService.addAddress, { token: userToken, newAddress: newAddress });

    const updatedAddresses = req.data.address;
    const currentUserData = yield select(userSelector);
    const newUserData = {
      ...currentUserData,
      address: updatedAddresses
    };

    yield put({
      type: ADD_ADDRESS_SUCCESS,
      payload: { newUserData: newUserData }
    });
  } catch (error) {
    yield put({
      type: ADD_ADDRESS_ERROR,
    });
  }
};

export const deleteAddressSaga = function* ({ payload }) {
  try {
    yield put({
      type: DELETE_ADDRESS_START
    });

    const { userToken, addressId } = payload;

    const req = yield call(apiService.deleteAddress, { token: userToken, addressId: addressId });

    const updatedAddresses = req.data.address;
    const currentUserData = yield select(userSelector);
    const newUserData = {
      ...currentUserData,
      address: updatedAddresses
    };

    yield put({
      type: DELETE_ADDRESS_SUCCESS,
      payload: { newUserData: newUserData }
    });
  } catch (error) {
    yield put({
      type: DELETE_ADDRESS_ERROR,
    });
  }
};

export const resetUserSuccessSaga = function* ({ payload }) {
  try {
    yield put({
      type: RESET_USER_SUCCESS_START
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const joinUserSaga = function* ({ payload }) {
  try {
    yield put({
      type: JOIN_START
    });

    const request = yield call(apiService.doJoinUser, { userId: payload.userId, locale: payload.locale });

    if (request.data && request.data.error && request.data.error === 'user exist, please use recovery password') {
      yield put({
        type: REGISTER_ERROR_USER_EXIST
      });
    } else {
      const login = request.data.logins[0];
      const password = request.data.password;

      const doLogin = yield call(apiService.doLogin, { email: login, password: password });

      yield localStorage.setItem('user', JSON.stringify(doLogin.data));
      yield localStorage.setItem('userToken', JSON.stringify(doLogin.data.token));

      yield put({
        type: SIGN_IN_SUCCESS,
        payload: { user: doLogin.data, token: doLogin.data.token }
      });
    }
  } catch (error) {
    const cError = error.response.data.error_description;
    yield put({
      type: JOIN_ERROR,
      payload: { customError: cError }
    });
  }
};

export const saga = function* () {
  yield all(
    [
      takeEvery(SIGN_IN_REQUEST, loginSaga),
      takeEvery(SIGN_OUT_REQUEST, logoutSaga),
      takeEvery(REGISTER_REQUEST, registerSaga),
      takeEvery(RESTORE_PWD_REQUEST, restorePwdSaga),
      takeEvery(RESET_PWD_HASH_REQUEST, resetPwdHashSaga),
      takeEvery(GET_PROFILE_REQUEST, getProfileSaga),
      takeEvery(GET_USER_ORDERS_REQUEST, getOrdersSaga),
      takeEvery(GET_ALL_USER_ORDERS_REQUEST, getAllOrdersSaga),
      takeEvery(UPDATE_PROFILE_REQUEST, updateProfileSaga),
      takeEvery(UPDATE_ADDRESS_REQUEST, updateAddressSaga),
      takeEvery(ADD_ADDRESS_REQUEST, addNewAddressSaga),
      takeEvery(DELETE_ADDRESS_REQUEST, deleteAddressSaga),
      takeEvery(RESET_USER_SUCCESS_REQUEST, resetUserSuccessSaga),
      takeEvery(JOIN_REQUEST, joinUserSaga)
    ]
  );
};
