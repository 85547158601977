import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useDishObjects } from './../../ducks/dishes';
import { useUserLoading, useUserToken, useUserOrders, getAllOrders } from '../../ducks/auth';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import { ReactComponent as RepeatIcon } from '../../svg/repeat.svg';
import { getDateString, makeItemsArrayFromObject, getOrderStatus, getItemsFromOrder } from './../../utils';
import { addDishesFromOrder } from './../../ducks/cart';
import { useTranslation } from "react-i18next";

function AllOrders({ allOrders, closeAllOrdersFunc, openCartFunc, closeAll }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const userLoading = useUserLoading();
  const userToken = useUserToken();
  const userOrders = useUserOrders();

  const dishesListObject = useDishObjects();

  useEffect(() => {
    if (allOrders && userToken) {
      dispatch(getAllOrders({ token: userToken }));
    }
  }, [allOrders, userToken]);

  const allOrdersClassnames = classNames({
    'all-orders': true,
    'all-orders--visible': allOrders
  });

  const handleAddFromOrder = (data) => {
    const dishes = getItemsFromOrder(data.items, dishesListObject);
    dispatch(addDishesFromOrder(dishes));
    openCartFunc();
  };

  if (userLoading || !userToken) {
    return (
      <div className={allOrdersClassnames}>

      </div>
    )
  }

  return (
    <div className={allOrdersClassnames}>
      <div className='all-orders__header'>
        <button type='button' onClick={closeAllOrdersFunc} className='all-orders__header-left base-header effect'>
          <ArrowLeft /> {t('page.account.allOrders')}
        </button>
        <div className='all-orders__mobile-close'>
          <button className='effect' type='button' onClick={closeAll}>
            <CloseSmallIcon title='Закрыть' />
          </button>
        </div>
      </div>

      <div className='account__right'>
        <div className='account__orders'>
          {
            userOrders.map((order, i) => {
              const { id, create_datetime, total, state_id, items } = order;
              const dateStr = getDateString(create_datetime);
              const itemsArray = makeItemsArrayFromObject(items);
              const itemsToRender = itemsArray.filter((item) => item.type === 'product');

              return (
                <div key={id} className='account__order'>
                  <div className='account__order-top'>
                    <div className='base-text-bold'>
                      {dateStr}, {+total} ₽
                    </div>
                    <div className='account__edit-info base-text'>
                      <span onClick={() => handleAddFromOrder(order)} className='account__edit-info-btn effect3'><span className='account__edit-info-icon no-flick'><RepeatIcon /></span> {t('page.account.repeatOrder')}</span>
                    </div>
                  </div>
                  <div className='account__order-middle base-text-grey'>
                    <div>
                      {getOrderStatus(state_id)}
                    </div>
                    <div>
                      №{id}
                    </div>
                  </div>
                  <div className='account__order-bottom'>
                    {
                      itemsToRender.map((item, i) => {
                        const { quantity, product_id } = item;
                        let name = '';
                        let name_en = '';
                        let img = '';
                        
                        if (product_id === '53') {
                          name = 'Мини пицца Маргарита';
                          name_en = 'Margherita';
                          img = 'https://pizzamaestrello.com/img/menu/product_8.png';
                        } else if (dishesListObject[product_id]) {
                          name = dishesListObject[product_id].name;
                          name_en = dishesListObject[product_id].name_en;
                          img = `https://pizzamaestrello.com/img/menu/product_${product_id}.png`;
                        }

                        return (
                          <div key={i} className='account__order-dish'>
                            <img src={img} width='50' alt={`${name_en} / ${name}`} />
                            <span className='base-text-bold-white'>{quantity}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className='all-orders__close'>
        <button className='effect' type='button' onClick={closeAll}>
          <CloseIcon title='Закрыть'/> {t('page.close')}
        </button>
      </div>
    </div>
  )
}

export default AllOrders;
