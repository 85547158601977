import { spawn } from 'redux-saga/effects';
import { saga as cartSaga } from '../ducks/cart';
import { saga as dishesSaga } from '../ducks/dishes';
import { saga as additionsSaga } from '../ducks/additions';
import { saga as authSaga } from '../ducks/auth';
import { saga as orderSaga } from '../ducks/order';
import { saga as mapSaga } from '../ducks/map';
import { saga as santaSaga } from '../ducks/santa';
import { saga as phoneSaga } from '../ducks/phone';
import { saga as paymentSaga } from '../ducks/payment';

export default function* () {
   yield spawn(cartSaga);
   yield spawn(dishesSaga);
   yield spawn(additionsSaga);
   yield spawn(authSaga);
   yield spawn(orderSaga);
   yield spawn(mapSaga);
   yield spawn(santaSaga);
   yield spawn(phoneSaga);
   yield spawn(paymentSaga);
}
