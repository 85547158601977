import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { checkDishExistInCart, checkDishInCart } from '../../utils';
import { useDispatch, useSelector } from "react-redux";
import { addDish, cartSelector, removeDish } from "../../ducks/cart";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckMark } from "../../svg/check-mark.svg";

function GiftDishBlock( { show, title, dishList, maxItems } ) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const cartItems = useSelector(cartSelector);
    const [maxReached, setMaxReached] = useState(false);
    const [animate, setAnimate] = useState(false);

    const handleGiftDish = (d) => {
        const dishInCart = checkDishExistInCart(cartItems, d.id);
        if (dishInCart) {
            dispatch(removeDish(d));
        } else {
            if (!maxReached) {
                dispatch(addDish({...d, extra: true }));
            }
        }
    }

    useEffect(() => {
        let count = 0;
        for (const dishItem of dishList) {
            count += checkDishExistInCart(cartItems, dishItem.id);
        }
        if ( count < maxItems) {
            setMaxReached(false);
        } else {
            setMaxReached(true);
        }
    }, [cartItems]);

    useEffect(() => {
        if ( show ) {
            setAnimate(false);
            setTimeout(() => {
                setAnimate(true);
            }, 200);
        }
    }, [show]);

    const itemClassnames = classNames({
        'cart__col-item cart__col-item-full cart__extra': true,
        'cart__col-item-animated': true,
        'cart__col-item-visible': animate
    });

    return (
        <div className='cart__col mt-2 cart__col-item-full-tablet'>
            <div className={itemClassnames}>
                <div className='cart__col-item--header'>
                    <span className='cart__col--text-header'>{title}</span>
                </div>
                <div className='mt-2'>
                    <div className='cart__extra-list'>
                        {
                            dishList.map((extra, i) => {
                                const {id, name, name_en, category_id, features} = extra;
                                const weight = features ? features.weight : '';
                                const volume = features ? features.volume : '';

                                const altImageLayout = category_id === '1' ||
                                    category_id === '2' ||
                                    category_id === '6' ||
                                    category_id === '11';

                                const img = `https://pizzamaestrello.com/img/menu/product_${id}.png`;
                                const checked = checkDishExistInCart(cartItems, id);

                                const iconClassnames = classNames({
                                    'cart__gift-addition-check': true,
                                    'cart__gift-addition-check--visible': checked
                                });

                                const itemImageClassnames = classNames({
                                    'cart__gift-addition-img': !altImageLayout,
                                    'cart__gift-addition-img-alt': altImageLayout,
                                });

                                const itemClassnames = classNames({
                                    'cart__gift-addition': true,
                                    'cart__addition--selected': checked,
                                    'cart__addition--disabled': maxReached
                                });

                                return (
                                    <div key={i} className={itemClassnames}
                                         onClick={() => handleGiftDish({...extra, amount: 1})}>
                                        <div className='cart__gift-addition-top'>
                                            <div className={itemImageClassnames}>
                                                <div className={iconClassnames}>
                                                    { checked ? <CheckMark /> : null }
                                                </div>
                                                <img src={img} width='50' alt={name}/>
                                            </div>
                                        </div>
                                        <div className='cart__gift-addition-name'>
                                          <span>
                                            {
                                                i18n.language === 'en' ? name_en : name
                                            }
                                          </span>
                                        </div>
                                        <div className='cart__gift-addition-params'>
                                            {
                                                weight ?
                                                    <div className='cart__gift-addition-weight'>
                                                        {weight} {t('dishes.g')}
                                                    </div> : null
                                            }
                                            {
                                                volume ?
                                                    <div className='cart__gift-addition-weight'>
                                                        {volume} {t('dishes.ml')}
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GiftDishBlock;
