import { put, takeEvery, all, call } from 'redux-saga/effects';
import apiService from '../services';

/**
 * Constants
 * */
export const moduleName = 'map';
const prefix = 'map';

export const GET_MAP_POLYGONS_REQUEST = `${prefix}/GET_MAP_POLYGONS_REQUEST`;
export const GET_MAP_POLYGONS_START = `${prefix}/GET_MAP_POLYGONS_START`;
export const GET_MAP_POLYGONS_SUCCESS = `${prefix}/GET_MAP_POLYGONS_SUCCESS`;
export const GET_MAP_POLYGONS_FAIL = `${prefix}/GET_MAP_POLYGONS_FAIL`;

export const GET_DELIVERY_TIME_REQUEST = `${prefix}/GET_DELIVERY_TIME_REQUEST`;
export const GET_DELIVERY_TIME_START = `${prefix}/GET_DELIVERY_TIME_START`;
export const GET_DELIVERY_TIME_SUCCESS = `${prefix}/GET_DELIVERY_TIME_SUCCESS`;
export const GET_DELIVERY_TIME_FAIL = `${prefix}/GET_DELIVERY_TIME_FAIL`;
export const RESET_DELIVERY_TIME_REQUEST = `${prefix}/RESET_DELIVERY_TIME_REQUEST`;
export const RESET_DELIVERY_TIME = `${prefix}/RESET_DELIVERY_TIME`;

/**
 * Reducer
 * */

const initialState = {
  loading: true,
  zones: null,
  restaurants: null,
  delivery_time: null,
  delivery_zone: null
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MAP_POLYGONS_START:
      return {...state, loading: true };
    case GET_MAP_POLYGONS_SUCCESS:
      return {...state, zones: payload.zones, restaurants: payload.restaurants, loading: false };
    case GET_DELIVERY_TIME_START:
      return {...state, loading: true };
    case GET_DELIVERY_TIME_SUCCESS:
      return {...state, delivery_time: parseInt(payload.delivery_time), delivery_zone: parseInt(payload.zone), loading: false };
    case RESET_DELIVERY_TIME:
      return {...state, delivery_time: null, delivery_zone: null };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const geoJsonSelector = (state) => state[moduleName].zones;
export const restaurantsSelector = (state) => state[moduleName].restaurants;
export const geoJsonLoadingSelector = (state) => state[moduleName].loading;
export const deliveryTimeSelector = (state) => state[moduleName].delivery_time;
export const deliveryZoneSelector = (state) => state[moduleName].delivery_zone;
/**
 * Action Creators
 * */

export const getPolygons = () => ({
  type: GET_MAP_POLYGONS_REQUEST
});

export const getDeliveryTime = (payload) => ({
  type: GET_DELIVERY_TIME_REQUEST,
  payload: payload
});

export const resetDeliveryTime = () => ({
  type: RESET_DELIVERY_TIME_REQUEST
});

/**
 * Sagas
 * */

export const getPolygonsSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_MAP_POLYGONS_START
    });

    const request = yield call(apiService.getDeliveryPolygons);

    yield put({
      type: GET_MAP_POLYGONS_SUCCESS,
      payload: request.data
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const getDeliveryTimeSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_DELIVERY_TIME_START
    });

    const coords = {
      "lat": payload[0],
      "lon": payload[1]
    };

    const request = yield call(apiService.getDeliveryTime, {coords});

    yield put({
      type: GET_DELIVERY_TIME_SUCCESS,
      payload: request.data
    });
  } catch (error) {
    // TODO: handle error
  }
};

export const resetDeliveryTimeSaga = function* ({ payload }) {
  yield put({
    type: RESET_DELIVERY_TIME
  });
};

export const saga = function* () {
  yield all(
    [
      takeEvery(GET_MAP_POLYGONS_REQUEST, getPolygonsSaga),
      takeEvery(GET_DELIVERY_TIME_REQUEST, getDeliveryTimeSaga),
      takeEvery(RESET_DELIVERY_TIME_REQUEST, resetDeliveryTimeSaga)
    ],
  );
};
