import React from 'react';
import classNames from 'classnames';
import { ReactComponent as CartWhiteIcon } from '../../svg/cart-white.svg';
import { useSelector } from 'react-redux';
import { cartSelector, priceSelector, loadingSelector } from './../../ducks/cart';
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router";
import {useAuthorized, useUserInfo} from "../../ducks/auth";
import {useLocation} from "react-router-dom";

function CartLink({ closeDish }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isAuthorized = useAuthorized();
  const userInfo = useUserInfo();

  const loCardsLevel = userInfo ? parseInt(userInfo.locards_level) : -1;

  const cart = useSelector(cartSelector);
  const totalPrice = useSelector(priceSelector);
  const loading = useSelector(loadingSelector);

  const linkClassname = classNames({
    'cart-link': true,
    'cart-link--outside': true,
    'cart-link--outside-visible': cart.length && location.pathname === '/',
    'cart-link--loading': loading
  });

  const joinClassname = classNames({
    'cart-link-loyalty': true,
    'cart-link--outside-loyalty': true,
    'cart-link--outside-visible': !cart.length && (location.pathname === '/') && (loCardsLevel < 1),
    'cart-link--loading': loading
  });

  const handleCartLink = () => {
    closeDish();
    setTimeout(() => {
      navigate('/cart');
    }, 200);
  }

  const handleLoyaltyLink = () => {
    closeDish();
    setTimeout(() => {
      if (isAuthorized) {
        navigate('/account');
      } else {
        navigate('/register');
      }
    }, 200);
  }

  return (
      <>
        {
          cart.length ?
              <button onClick={() => handleCartLink()} className={linkClassname}>
              <span className='cart-link__loader'>
                <span className='loader'>{t('common.loading')}</span>
              </span>
                <CartWhiteIcon className='cart-link__icon'/>
                <span className='cart-link__text'>{t('page.cart.link')}</span>
                <span className='cart-link__price'>{totalPrice} ₽</span>
              </button>
              :
              <button onClick={() => handleLoyaltyLink()} className={joinClassname}>
                <span className='cart-link__text'>{t('page.cart.loyalty')}</span>
              </button>
        }
      </>
  );
}

export default CartLink;
