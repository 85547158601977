import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import { ReactComponent as OrderCreatedIcon } from '../../svg/order-created.svg';
import { ReactComponent as OrderErrorIcon } from '../../svg/order-error.svg';

import {
    joinUser,
    useAuthorized,
    useUserCustomError,
    useUserError,
    useUserInfo,
    useUserLoading,
    useUserToken
} from '../../ducks/auth';
import {
     createOrderPaymentForm, getOrderStatus
} from '../../ducks/order';

import {
    paymentMethods
} from '../../utils';

import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import {getPaymentStatus, paymentSelector} from "../../ducks/payment";


function OrderStatusPage() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [hideChild, setHideChild]= useOutletContext();
    const [showModal, setShowModal] = useState(false);

    const orderData = useSelector(paymentSelector);
    const dispatch = useDispatch();
    const isAuthorized = useAuthorized();
    const userToken = useUserToken();
    const userLoading = useUserLoading();
    const userError = useUserError();
    const userCustomError = useUserCustomError();

    let { id } = useParams();

    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/');
        }, 200)
    }

    const handleBack = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate(-1);
        }, 200)
    }

    useEffect(() => {
        if (location) {
            setTimeout(()=> {
                setShowModal(true);
            }, 200)
        }
    }, [location]);

    useEffect(() => {
        if (hideChild) {
            handleCloseModal();
            setHideChild(false);
        }
    }, [hideChild]);


    useEffect(() => {
        if (id)
        {
            dispatch(getPaymentStatus(id));
        }
    }, [id]);

    const handleJoinUser = () => {
        dispatch(joinUser({ userId: orderData.contact_id, locale: i18n.language }))
    }

    const handlePayAgain = () => {
        dispatch(createOrderPaymentForm({ orderId: orderData.id, token: userToken, locale: i18n.language }))
    }

    const MINUTE_MS = 1000 * 60;
    const orderStatuses = {
        'new': t('page.order.orderNew'),
        'paid': t('page.order.orderPaid'),
        'processing': t('page.order.orderProcessing'),
        'otpravlen-v-rest': t('page.order.orderForwarded'),
        'prinyat-restoran': t('page.order.orderAccepted'),
        'prigotovlen-rest': t('page.order.orderReadyToPickup'),
        'waiting-for-coll': t('page.order.orderAwaitingPickup'),
        'shipped': t('page.order.orderShipped'),
        'delivered': t('page.order.orderDelivered'),
        'completed': t('page.order.orderCompleted'),
        'canceled': t('page.order.orderCanceled')
    };

    useEffect(() => {
        let interval;
        // dispatch(getOrderStatus({ orderId: orderData.id, locale: i18n.language }))
        interval = setInterval(() => {
            // dispatch(getOrderStatus({ orderId: orderData.id, locale: i18n.language }))
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [orderData])

    // useEffect(() => {        // if (orderData.success && !orderData.payment) {
    //     //     openOrderCreatedFunc();
    //     //     dispatch(clearCart());
    //     //     navigate('/');
    //     // }
    //
    // }, [orderData.success, orderData.payment]);

    const blockClassnames = classNames({
        'order-created': true,
        'order-created--visible': showModal,
        'order-created--user': isAuthorized,
    });

    const userExist = userCustomError === 'Perhaps you are already registered, please try to log in or recover your password.' ||
        userCustomError === 'Возможно вы уже зарегистрированы, попробуйте войти или восстановить пароль.';

    const formErrorClassnames = classNames({
        'form__error': true,
        'form__error--visible': userError || userExist,
        'form__error--text-center': true,
        'form__error--black-text': userExist
    });

    let errorString = null;

    if (userError && userCustomError) {
        errorString = <span>{userCustomError}</span>
    }

    const readyString = orderData && orderData.pickup ? t('page.order.readyTime') : t('page.order.deliveryTime');

    return (
        <div className={blockClassnames}>
            <div className='order-created__top-icon'>
                {
                    orderData && orderData.state_id === 'new' ?
                        <OrderErrorIcon title='Заказ' /> : <OrderCreatedIcon title='Заказ' />
                }
            </div>
            <div className='order-created__header'>
                <div className='base-header'>
                    {t('page.order.orderNum')} {orderData.id} ({orderStatuses[orderData.state_id]})
                </div>
                <div className='base-header-grey'>
                    {t('page.order.orderTotal', { cost: orderData.total})}
                </div>
                {
                    orderData.state_id === 'new' ?
                        <div className='order-created__pay-button'>
                            <button onClick={handlePayAgain}  className='button yellow effect'>
                                {t('page.order.goToPayment')}
                            </button>
                        </div> : null
                }
            </div>
            <div className={formErrorClassnames}>
                {errorString}
            </div>
        </div>
    );

}

export default OrderStatusPage;
