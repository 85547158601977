import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import apiService from '../services';
import { cartSelector, cutlerySelector, priceSelector } from './cart'
import {
  cleanPhone,
  getDateStringForPromocode,
  collectOrderParamsForAmplitude,
  collectOrderParamsForFacebook, paymentMethods, paymentMethodString, paymentMethodsOptions
} from './../utils'
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import ym from 'react-yandex-metrika';

function sendAmplitudeEvent(event, params) {
  if (params) {
    amplitude.getInstance().logEvent(event, params);
  } else {
    amplitude.getInstance().logEvent(event);
  }
}

function sendFBPurchase(params) {
  ReactPixel.track('Purchase', params);
  ym('reachGoal', 'Purchase', params);
}

function sendYMEvent(event) {
  ym('reachGoal', event);
}

/**
 * Constants
 * */
export const moduleName = 'order';
// const prefix = `${appName}/${moduleName}`;
const prefix = 'order';

export const TOGGLE_ABOUT_PICKUP_MODAL = `${prefix}/TOGGLE_ABOUT_PICKUP_MODAL`;

export const SAVE_ORDER_DATA_REQUEST = `${prefix}/SAVE_ORDER_DATA_REQUEST`;
export const SAVE_ORDER_DATA = `${prefix}/SAVE_ORDER_DATA`;

export const GET_DELIVERY_COST_REQUEST = `${prefix}/GET_DELIVERY_COST_REQUEST`;
export const GET_DELIVERY_COST_START = `${prefix}/GET_DELIVERY_COST_START`;
export const GET_DELIVERY_COST_SUCCESS = `${prefix}/GET_DELIVERY_COST_SUCCESS`;
export const GET_DELIVERY_COST_ERROR = `${prefix}/GET_DELIVERY_COST_ERROR`;
export const GET_DELIVERY_COST_WRONG_ADDRESS_ERROR = `${prefix}/GET_DELIVERY_COST_WRONG_ADDRESS_ERROR`;

export const CREATE_ORDER_REQUEST = `${prefix}/CREATE_ORDER_REQUEST`;
export const CREATE_ORDER_START = `${prefix}/CREATE_ORDER_START`;
export const CREATE_ORDER_SUCCESS = `${prefix}/CREATE_ORDER_SUCCESS`;
export const CREATE_ORDER_ERROR = `${prefix}/CREATE_ORDER_ERROR`;

export const CREATE_ORDER_PAYMENT_SUCCESS = `${prefix}/CREATE_ORDER_PAYMENT_SUCCESS`;
export const CREATE_ORDER_REG_PAYMENT_SUCCESS = `${prefix}/CREATE_ORDER_REG_PAYMENT_SUCCESS`;

export const CREATE_ORDER_PAYMENT_SUCCESS_RETRY = `${prefix}/CREATE_ORDER_PAYMENT_SUCCESS_RETRY`;


export const CREATE_ORDER_PAYMENT_SUCCESS_V2 = `${prefix}/CREATE_ORDER_PAYMENT_SUCCESS_V2`;
export const CREATE_ORDER_REG_PAYMENT_SUCCESS_V2 = `${prefix}/CREATE_ORDER_REG_PAYMENT_SUCCESS_V2`;

export const CREATE_ORDER_PAYMENT_SUCCESS_V2_RETRY = `${prefix}/CREATE_ORDER_PAYMENT_SUCCESS_V2_RETRY`;


export const CREATE_ORDER_REG_REQUEST = `${prefix}/CREATE_ORDER_REG_REQUEST`;
export const CREATE_ORDER_REG_START = `${prefix}/CREATE_ORDER_REG_START`;
export const CREATE_ORDER_REG_SUCCESS = `${prefix}/CREATE_ORDER_REG_SUCCESS`;
export const CREATE_ORDER_REG_ERROR = `${prefix}/CREATE_ORDER_REG_ERROR`;

export const CREATE_PAYMENT_REQUEST = `${prefix}/CREATE_PAYMENT_REQUEST`;
export const CREATE_PAYMENT_START = `${prefix}/CREATE_PAYMENT_START`;
export const CREATE_PAYMENT_SUCCESS = `${prefix}/CREATE_PAYMENT_SUCCESS`;
export const CREATE_PAYMENT_ERROR = `${prefix}/CREATE_PAYMENT_ERROR`;

export const GET_ORDER_STATUS_REQUEST = `${prefix}/GET_ORDER_STATUS_REQUEST`;
export const GET_ORDER_STATUS_START = `${prefix}/GET_ORDER_STATUS_START`;
export const GET_ORDER_STATUS_SUCCESS = `${prefix}/GET_ORDER_STATUS_SUCCESS`;
export const GET_ORDER_STATUS_ERROR = `${prefix}/GET_ORDER_STATUS_ERROR`;

export const GET_ORDER_DISCOUNTS_REQUEST = `${prefix}/GET_ORDER_DISCOUNTS_REQUEST`;
export const GET_ORDER_DISCOUNTS_START = `${prefix}/GET_ORDER_DISCOUNTS_START`;
export const GET_ORDER_DISCOUNTS_SUCCESS = `${prefix}/GET_ORDER_DISCOUNTS_SUCCESS`;
export const GET_ORDER_DISCOUNTS_ERROR = `${prefix}/GET_ORDER_DISCOUNTS_ERROR`;
export const RESET_ORDER_DISCOUNTS_REQUEST = `${prefix}/RESET_ORDER_DISCOUNTS_REQUEST`;
export const RESET_ORDER_DISCOUNTS = `${prefix}/RESET_ORDER_DISCOUNTS`;

export const CHECK_PROMO_RESET = `${prefix}/CHECK_PROMO_RESET`;
export const CHECK_PROMO_REQUEST = `${prefix}/CHECK_PROMO_REQUEST`;
export const CHECK_PROMO_START = `${prefix}/CHECK_PROMO_START`;
export const CHECK_PROMO_SUCCESS = `${prefix}/CHECK_PROMO_SUCCESS`;
export const CHECK_PROMO_ERROR = `${prefix}/CHECK_PROMO_ERROR`;
export const CLEAR_ORDER_REQUEST = `${prefix}/CLEAR_ORDER_REQUEST`;
export const CLEAR_ORDER = `${prefix}/CLEAR_ORDER`;

export const RESET_DELIVERY_COST_REQUEST = `${prefix}/RESET_DELIVERY_COST_REQUEST`;
export const RESET_DELIVERY_COST = `${prefix}/RESET_DELIVERY_COST`;

const initialState = {
  address: '',
  entrance: '',
  intercom: '',
  floor: '',
  appartment: '',
  comment: '',
  day: '',
  month: '',
  year: '',
  time: '',
  preorderDateTime: JSON.parse(localStorage.getItem('preorderDateTime')) || '',
  name: '',
  phone: '',
  email: '',
  promo: '',
  discount: 0,
  balance: 0,
  write_off_available: 0,
  write_off_min: 0,
  freeShipping: false,
  deliveryCost: 0,
  deliveryTime: JSON.parse(localStorage.getItem('deliveryTime')) || '--:--',
  deliveryMin: 35,
  orderStatus: 'создан',
  restaurantId: 0,
  paymentMethod: JSON.parse(localStorage.getItem('paymentMethod')) || paymentMethods.CARD,
  change: '',
  pickup: JSON.parse(localStorage.getItem('orderPickup')) || '',
  loading: false,
  error: false,
  successPromo: false,
  errorPromo: false,
  errorDelivery: false,
  success: localStorage.getItem('orderSuccess') !== 'undefined'? JSON.parse(localStorage.getItem('orderSuccess')) : 0,
  newUserId: JSON.parse(localStorage.getItem('newUserId')) || 0,
  payment: null,
  payment_v2: null,
  showAboutPickupModal: false,
  referer: localStorage.getItem('referer')
}

/**
 * Reducer
 * */

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_ABOUT_PICKUP_MODAL:
      return {...state, showAboutPickupModal: payload};
    case SAVE_ORDER_DATA:
      return {...state, ...payload };
    case CREATE_ORDER_START:
      return {...state, loading: true, error: false };
    case CREATE_ORDER_SUCCESS:
      return {...state, loading: false, success: payload.success, newUserId: payload.newUserId };
    case CREATE_ORDER_PAYMENT_SUCCESS:
      return {...state, success: payload.success, newUserId: payload.newUserId, payment: payload.payment };
    case CREATE_ORDER_REG_PAYMENT_SUCCESS:
      return {...state, success: payload.success, payment: payload.payment };

    case CREATE_ORDER_PAYMENT_SUCCESS_RETRY:
      return {...state, payment: payload.payment };

    case CREATE_ORDER_PAYMENT_SUCCESS_V2:
      return {...state, success: payload.success, newUserId: payload.newUserId, payment_v2: payload.payment };
    case CREATE_ORDER_REG_PAYMENT_SUCCESS_V2:
      return {...state, success: payload.success, payment_v2: payload.payment };

    case CREATE_ORDER_PAYMENT_SUCCESS_V2_RETRY:
      return {...state, payment_v2: payload.payment };

    case CREATE_ORDER_ERROR:
      return {...state, loading: false, error: true };
    case CREATE_ORDER_REG_START:
      return {...state, loading: true, error: false };
    case CREATE_ORDER_REG_SUCCESS:
      return {...state, loading: false, success: payload.success };
    case CREATE_ORDER_REG_ERROR:
      return {...state, loading: false, error: true };
    case GET_ORDER_STATUS_SUCCESS:
      return {...state, orderStatus: payload.orderStatus }

    case CHECK_PROMO_RESET:
      return {...state, promo: '', discount: 0, freeShipping: false, successPromo: false, errorPromo: false };
    case CHECK_PROMO_START:
      return {...state, discount: 0, freeShipping: false, loading: true, successPromo: false, errorPromo: false };
    case CHECK_PROMO_SUCCESS:
      return {...state,
        promo: payload.promo,
        discount: payload.discount,
        freeShipping: payload.freeShipping,
        loading: false,
        successPromo: true
      };
    case GET_ORDER_DISCOUNTS_SUCCESS:
      return {...state, ...payload, loading: false};
    case CHECK_PROMO_ERROR:
      return {...state, promo: payload.promo, loading: false, errorPromo: true };
      case RESET_ORDER_DISCOUNTS:
      return {
        ...state,
        discount: 0,
        write_off_available: 0,
        write_off_min: 0,
      };
      case RESET_DELIVERY_COST:
      return {
        ...state,
        deliveryCost: 0,
        deliveryTime: '--:--',
        deliveryMin: 35,
        errorDelivery: false
       };
    case GET_DELIVERY_COST_START:
      return {...state, loading: true, errorDelivery: false };
    case GET_DELIVERY_COST_SUCCESS:
      return {
        ...state,
        deliveryCost: payload.deliveryCost,
        restaurantId: payload.restaurantId,
        deliveryMin: payload.deliveryMin,
        deliveryTime: payload.deliveryTime,
        loading: false
      };
    case GET_DELIVERY_COST_WRONG_ADDRESS_ERROR:
      return {...state, loading: false, errorDelivery: true };
    case CLEAR_ORDER:
      return {
        address: '',
        entrance: '',
        intercom: '',
        floor: '',
        appartment: '',
        comment: '',
        day: '',
        month: '',
        year: '',
        time: '',
        preorderDateTime: '',
        name: '',
        phone: '',
        phoneCodeAssetID: null,
        phoneCodeValid: false,
        phoneCodeError: false,
        phoneCodeErrorMessage: '',
        email: '',
        promo: '',
        discount: 0,
        balance: 0,
        write_off_available: 0,
        write_off_min: 0,
        freeShipping: false,
        deliveryCost: 0,
        deliveryTime: '--:--',
        deliveryMin: 35,
        orderStatus: 'создан',
        restaurantId: 0,
        paymentMethod: paymentMethods.EMPTY,
        change: '',
        pickup: '',
        loading: false,
        error: false,
        successPromo: false,
        errorPromo: false,
        errorDelivery: false,
        success: 0,
        newUserId: 0,
        payment: null,
        payment_v2: null
       };
    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const orderSelector = (state) => state[moduleName];
export const showAboutPickupModalSelector = (state) => state[moduleName].showAboutPickupModal;

/**
 * Action Creators
 * */

export const saveOrderData = (payload) => ({
  type: SAVE_ORDER_DATA_REQUEST,
  payload: payload
});

export const getDeliveryCost = (payload) => ({
  type: GET_DELIVERY_COST_REQUEST,
  payload: payload
});

export const createOrder = (payload) => ({
  type: CREATE_ORDER_REQUEST,
  payload: payload
});

export const createOrderReg = (payload) => ({
  type: CREATE_ORDER_REG_REQUEST,
  payload: payload
});

export const getOrderStatus = (payload) => ({
  type: GET_ORDER_STATUS_REQUEST,
  payload: payload
});

export const createOrderPaymentForm = (payload) => ({
  type: CREATE_PAYMENT_REQUEST,
  payload: payload
});

export const clearOrder = (payload) => ({
  type: CLEAR_ORDER_REQUEST
});

export const checkPromo = (payload) => ({
  type: CHECK_PROMO_REQUEST,
  payload: payload
});

export const resetPromo = (payload) => ({
  type: CHECK_PROMO_RESET
});

export const resetDeliveryCost = () => ({
  type: RESET_DELIVERY_COST_REQUEST
});

export const resetDiscounts = () => ({
  type: RESET_ORDER_DISCOUNTS_REQUEST
});

export const toggleAboutPickupModal = (payload) => ({
  type: TOGGLE_ABOUT_PICKUP_MODAL,
  payload: payload
});

/**
 * Sagas
 * */

export const resetDeliveryCostSaga = function* ({ payload }) {
  yield put({
    type: RESET_DELIVERY_COST
  });
};

export const resetDiscountsSaga = function* ({ payload }) {
  yield put({
    type: RESET_ORDER_DISCOUNTS
  });
};

export const saveOrderDataSaga = function* ({ payload }) {
  if (payload.paymentMethod && payload.paymentMethod !== paymentMethods.EMPTY) {
    yield call(sendAmplitudeEvent, 'checkout_name', {
      user_name: payload.name
    });
    yield call(sendAmplitudeEvent, 'checkout_cell', {
      user_cell: cleanPhone(payload.phone)
    });
    yield call(sendAmplitudeEvent, 'checkout_email', {
      user_email: payload.email
    });
    yield call(sendAmplitudeEvent, 'checkout_payment', {
      payment_type: paymentMethodString(payload.paymentMethod)
    });
  }

  yield put({
    type: SAVE_ORDER_DATA,
    payload: payload,
  });
};

export const getDeliveryCostSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_DELIVERY_COST_START,
      payload: payload,
    });

    const items = yield select(cartSelector);

    const shippingEnum = ['11', '15', '40', '42', "46"];

    const data = {
      'params': {
        'shipping_address.city': 'Москва',
        'shipping_address.country': 'rus',
        'shipping_address.region': '77',
        'shipping_address.street': payload.address,
        'shipping_id': payload.isPickup? shippingEnum[payload.pickupPoint] : '37',
      },
      'items': items.map((item) => {
        const { sku_id, amount, additions } = item;
        const itemForOrder = {
          sku_id: sku_id,
          quantity: amount,
        }

        if (additions && additions.length) {
          itemForOrder.services = additions.map((a) => {
            return { service_variant_id: a.additionId }
          });
        }

        return itemForOrder;
      })
    };

    const request = yield call(apiService.getDeliveryCost, data);

    if (request.data.error && request.data.error === 'По этому адресу доставка не доступна.') {
      yield put({
        type: GET_DELIVERY_COST_WRONG_ADDRESS_ERROR,
      });
    } else {
      const deliveryCost = request.data.calc_address.shipping_cost;
      const restaurantId = request.data.calc_address.restaurant_id;
      const deliveryTime = request.data.calc_address.delivery_time;
      const deliveryMin = Math.round(request.data.calc_address.delivery_min);

      localStorage.setItem('deliveryTime', JSON.stringify(deliveryTime));

      yield put({
        type: GET_DELIVERY_COST_SUCCESS,
        payload: { deliveryCost, restaurantId, deliveryTime, deliveryMin }
      });
    }
  } catch (error) {
    yield put({
      type: GET_DELIVERY_COST_ERROR
    });
  }
};

export const createOrderSaga = function* ({ payload }) {
  try {
    yield put({
      type: CREATE_ORDER_START,
      payload: payload,
    });

    const order = yield select(orderSelector);
    const items = yield select(cartSelector);
    const cutlery = yield select(cutlerySelector);
    const total = yield select(priceSelector);
    let preorderDateTime = '';

    const orderData = {
      'contact': {
        'name': order.name,
        'phone': cleanPhone(order.phone),
        'email': order.email
      },
      'agreement_marketing' : order.agreement_marketing,
      'params': {
        'shipping_params_person_count': String(cutlery)
      },
      'items': items.map((item) => {
        const { sku_id, amount, additions } = item;
        const itemForOrder = {
          sku_id: sku_id,
          quantity: amount,
        }

        if (additions && additions.length) {
          itemForOrder.services = additions.map((a) => {
            return { service_variant_id: a.additionId }
          });
        }

        return itemForOrder;
      })
    };

    if (total >= 1500) {
      orderData.items.push({
        sku_id: '56',
        quantity: 1
      })
    }

    if (order.pickup === 'pokrovka') {
      orderData.params.shipping_id = '11';
    } else if (order.pickup === 'mitnaya') {
      orderData.params.shipping_id = '15';
    } else if (order.pickup === 'petrovka') {
      orderData.params.shipping_id = '40';
    } else if (order.pickup === 'gruzinka') {
      orderData.params.shipping_id = '42';
    } else if (order.pickup === 'depo') {
      orderData.params.shipping_id = '46';
    } else {
      orderData.params.shipping_id = '37';
      orderData.params['shipping_address.city'] = 'Москва';
      orderData.params['shipping_address.country'] = 'rus';
      orderData.params['shipping_address.etazh'] = order.floor;
      orderData.params['shipping_address.kvartira'] = order.appartment;
      orderData.params['shipping_address.podezd'] = order.entrance;
      orderData.params['shipping_address.region'] = '77';
      orderData.params['shipping_address.street'] = order.address;

      if (order.intercom) {
        orderData.params['shipping_address.kod-domofona'] = order.intercom;
      }
    }

    if (order.comment) {
      orderData.comment = order.comment;
    }

    switch (order.paymentMethod) {
      case paymentMethods.CARD:
        orderData.params.payment_id = '39';
        break;

      case paymentMethods.CASH:
        orderData.params.payment_id = '1';
        orderData.params.payment_params_change = order.change;
        break;

      case paymentMethods.INVOICE:
        orderData.params.payment_id = '44';
        break;

      case paymentMethods.TEST:
        orderData.params.payment_id = '45';
        break;

      default: break;
    }

    if (order.day && order.month && order.year && order.time) {
      const preorderDate = `${order.day}.${order.month}.${order.year}`;
      preorderDateTime = `${order.time} ${order.day}.${order.month}.${order.year}`;

      orderData.params.shipping_params_desired_delivery_variant = 'Дата и время';
      orderData.params['shipping_params_desired_delivery.date'] = `${order.year}-${order.month}-${order.day}`;
      orderData.params['shipping_params_desired_delivery.date_str'] = preorderDate;
      orderData.params['shipping_params_desired_delivery.interval'] = order.time;
    } else {
      orderData.params.shipping_params_desired_delivery_variant = 'Ближайшее время';
    }

    if (order.promo) {
      orderData.coupon_code = order.promo;
    }

    const request = yield call(apiService.createOrder, orderData);
    const orderId = request.data.order_id;
    const newUserId = request.data.contact_id;

    localStorage.setItem('orderSuccess', JSON.stringify(orderId));
    localStorage.setItem('newUserId', JSON.stringify(newUserId));

    if (order.pickup) {
      localStorage.setItem('orderPickup', JSON.stringify(order.pickup));
    }

    if (preorderDateTime) {
      yield put({
        type: SAVE_ORDER_DATA,
        payload: { preorderDateTime: preorderDateTime },
      });

      localStorage.setItem('preorderDateTime', JSON.stringify(preorderDateTime));
    }

    const apmlitudeParams = collectOrderParamsForAmplitude(order, items);
    yield call(sendAmplitudeEvent, 'buttom_checkout_valide_purch', apmlitudeParams);
    yield call(sendYMEvent, 'create_order');

    const fbParams = collectOrderParamsForFacebook(items);
    yield call(sendFBPurchase, fbParams);

    if (order.paymentMethod === paymentMethods.CARD || order.paymentMethod === paymentMethods.TEST) {
      const payment = yield call(apiService.createPayment, { orderId: orderId });
      if (payment.data.type === 'widget') {
        yield put({
          type: CREATE_ORDER_PAYMENT_SUCCESS_V2,
          payload: { success: orderId, newUserId: newUserId, payment: payment.data }
        });
      } else {
        yield put({
          type: CREATE_ORDER_PAYMENT_SUCCESS,
          payload: { success: orderId, newUserId: newUserId, payment: payment.data }
        });
      }

    } else {
      yield put({
        type: CREATE_ORDER_SUCCESS,
        payload: { success: orderId, newUserId: newUserId }
      });
    }
    localStorage.setItem('paymentMethod', JSON.stringify(order.paymentMethod));

  } catch(error) {
    yield put({
      type: CREATE_ORDER_ERROR
    });
  }
};

export const createPaymentFormSaga = function* ({ payload }) {
  try {
    const orderId = payload.orderId;

    const payment = yield call(apiService.createPayment, { orderId: orderId });
    console.log(payment.data);

    if (payment.data.type === 'widget') {
      yield put({
        type: CREATE_ORDER_PAYMENT_SUCCESS_V2_RETRY,
        payload: { payment: payment.data }
      });
    } else {
      yield put({
        type: CREATE_ORDER_PAYMENT_SUCCESS_RETRY,
        payload: { payment: payment.data }
      });
    }

    // yield put({
    //   type: CREATE_ORDER_REG_PAYMENT_SUCCESS,
    //   payload: { success: orderId, payment: payment.data }
    // });

  } catch (e) {
    yield put({
      type: CREATE_PAYMENT_ERROR
    });
  }
}

export const createOrderRegSaga = function* ({ payload }) {
  try  {
    yield put({
      type: CREATE_ORDER_REG_START,
      payload: payload,
    });

    const order = yield select(orderSelector);
    const items = yield select(cartSelector);
    const cutlery = yield select(cutlerySelector);
    const total = yield select(priceSelector);
    const token = payload.token;
    const userId = payload.userId;
    let preorderDateTime = '';

    const orderData = {
      'contact_id': userId,
      'agreement_marketing' : order.agreement_marketing,
      'params': {
        'restaurant_id': order.restaurantId,
        'shipping_params_person_count': String(cutlery)
      },
      'items': items.map((item) => {
        const { sku_id, amount, additions } = item;
        const itemForOrder = {
          sku_id: sku_id,
          quantity: amount,
        }

        if (additions && additions.length) {
          itemForOrder.services = additions.map((a) => {
            return { service_variant_id: a.additionId }
          });
        }

        return itemForOrder;
      })
    };

    if (total >= 1500) {
      orderData.items.push({
        sku_id: '56',
        quantity: 1
      })
    }

    if (order.pickup === 'mitnaya') {
      orderData.params.shipping_id = '15';
    } else if (order.pickup === 'pokrovka') {
      orderData.params.shipping_id = '11';
    } else if (order.pickup === 'petrovka') {
      orderData.params.shipping_id = '40';
    } else if (order.pickup === 'gruzinka') {
      orderData.params.shipping_id = '42';
    } else if (order.pickup === 'depo') {
      orderData.params.shipping_id = '46';
    } else {
      orderData.params.shipping_id = '37';
      orderData.params['shipping_address.city'] = 'Москва';
      orderData.params['shipping_address.country'] = 'rus';
      orderData.params['shipping_address.etazh'] = order.floor;
      orderData.params['shipping_address.kvartira'] = order.appartment;
      orderData.params['shipping_address.podezd'] = order.entrance;
      orderData.params['shipping_address.region'] = '77';
      orderData.params['shipping_address.street'] = order.address;

      if (order.intercom) {
        orderData.params['shipping_address.kod-domofona'] = order.intercom;
      }
    }

    if (order.comment) {
      orderData.comment = order.comment;
    }

    switch (order.paymentMethod) {
      case paymentMethods.CARD:
        orderData.params.payment_id = '39';
        break;

      case paymentMethods.CASH:
        orderData.params.payment_id = '1';
        orderData.params.payment_params_change = order.change;
        break;

      case paymentMethods.INVOICE:
        orderData.params.payment_id = '44';
        break;

      case paymentMethods.TEST:
        orderData.params.payment_id = '45';
        break;

      default: break;
    }

    /* LoCards */
    orderData.params.payment_option = order.paymentMethodOption;
    if (order.paymentMethodOption === paymentMethodsOptions.WRITE_OFF_POINTS)
    {
      orderData.params.payment_write_off_points = order.writeOffPoints;
    }

    if (order.day && order.month && order.year && order.time) {
      const preorderDate = `${order.day}.${order.month}.${order.year}`;
      preorderDateTime = `${order.time} ${order.day}.${order.month}.${order.year}`;

      orderData.params.shipping_params_desired_delivery_variant = 'Дата и время';
      orderData.params['shipping_params_desired_delivery.date'] = `${order.year}-${order.month}-${order.day}`;
      orderData.params['shipping_params_desired_delivery.date_str'] = preorderDate;
      orderData.params['shipping_params_desired_delivery.interval'] = order.time;
    } else {
      orderData.params.shipping_params_desired_delivery_variant = 'Ближайшее время';
    }

    if (order.promo) {
      orderData.coupon_code = order.promo;
    }

    const request = yield call(apiService.createOrderReg, orderData, token);
    const orderId = request.data.order_id;

    localStorage.setItem('orderSuccess', JSON.stringify(orderId));

    if (order.pickup) {
      localStorage.setItem('orderPickup', JSON.stringify(order.pickup));
    }

    if (preorderDateTime) {
      yield put({
        type: SAVE_ORDER_DATA,
        payload: { preorderDateTime: preorderDateTime },
      });

      localStorage.setItem('preorderDateTime', JSON.stringify(preorderDateTime));
    }

    const apmlitudeParams = collectOrderParamsForAmplitude(order, items);
    yield call(sendAmplitudeEvent, 'buttom_checkout_valide_purch', apmlitudeParams);
    yield call(sendYMEvent, 'create_order');

    const fbParams = collectOrderParamsForFacebook(items);
    yield call(sendFBPurchase, fbParams);

    if (order.paymentMethod === paymentMethods.CARD || order.paymentMethod === paymentMethods.TEST) {
      const payment = yield call(apiService.createPayment, { orderId: orderId });

      if (payment.data.type === 'widget') {
        yield put({
          type: CREATE_ORDER_REG_PAYMENT_SUCCESS_V2,
          payload: { success: orderId, payment: payment.data }
        });
      } else {
        yield put({
          type: CREATE_ORDER_REG_PAYMENT_SUCCESS,
          payload: { success: orderId, payment: payment.data }
        });
      }
    } else {
      yield put({
        type: CREATE_ORDER_REG_SUCCESS,
        payload: { success: orderId }
      });
    }
    localStorage.setItem('paymentMethod', JSON.stringify(order.paymentMethod));
  } catch(error) {
    yield put({
      type: CREATE_ORDER_REG_ERROR
    });
  }
};

export const getOrderStatusSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_ORDER_STATUS_START,
      payload: payload,
    });

    const request = yield call(apiService.getOrderStatus, { orderId: payload.orderId, locale: payload.locale });

    yield put({
      type: GET_ORDER_STATUS_SUCCESS,
      payload: { orderStatus: request.data.order_status.toLowerCase() }
    });
  } catch(e) {
    yield put({
      type: GET_ORDER_STATUS_ERROR
    });
  }
}

export const clearOrderSaga = function* () {
  localStorage.removeItem('orderSuccess');
  localStorage.removeItem('newUserId');
  localStorage.removeItem('deliveryTime');
  localStorage.removeItem('orderPickup');
  localStorage.removeItem('preorderDateTime');

  yield put({
   type: CLEAR_ORDER
  });
};

export const checkPromoSaga = function* ({ payload }) {
  try {
    yield put({
      type: CHECK_PROMO_START,
      payload: payload,
    });

    const { userId, code, params } = payload;
    const items = yield select(cartSelector);
    const order = yield select(orderSelector);

    const data = {
      coupon_code: code,
      items: items.map((item) => {
        const { sku_id, amount, additions } = item;
        const itemForOrder = {
          sku_id: sku_id,
          quantity: amount,
        }

        if (additions && additions.length) {
          itemForOrder.services = additions.map((a) => {
            return { service_variant_id: a.additionId }
          });
        }

        return itemForOrder;
      })
    };

    if (userId) {
      data.contact_id = userId;
    }

    if (params) {
      data.delivery_date = `${params.year}-${params.month}-${params.day}`;
      data.delivery_type = params.delivery_type;
    } else {
      if (order.day && order.month && order.year && order.time) {
        data.delivery_date = `${order.year}-${order.month}-${order.day}`;
      } else {
        data.delivery_date = getDateStringForPromocode();
      }

      if (order.address) {
        data.delivery_type = 'courier';
      }
    }


    let response = yield call(apiService.checkPromo, data);
    const _data = response.data;
    const freeShipping = response.data.shipping === 0;

    if (code)
    {
      if (!_data.discount && !freeShipping) {
        // promo code not working
        yield put({
          type: CHECK_PROMO_ERROR,
          payload: { promo: code }
        });
      } else {
        // promo code active
        yield put({
          type: CHECK_PROMO_SUCCESS,
          payload: { discount: _data.discount, freeShipping, promo: code }
        });
      }
    }
    else
    {
      yield put({
        type: GET_ORDER_DISCOUNTS_SUCCESS,
        payload: _data
      });
    }

  } catch (error) {
    yield put({
      type: CHECK_PROMO_ERROR
    });
  }
};

export const saga = function* () {
  yield all(
    [
      takeEvery(SAVE_ORDER_DATA_REQUEST, saveOrderDataSaga),
      takeEvery(GET_DELIVERY_COST_REQUEST, getDeliveryCostSaga),
      takeEvery(CREATE_ORDER_REQUEST, createOrderSaga),
      takeEvery(CREATE_PAYMENT_REQUEST, createPaymentFormSaga),
      takeEvery(CREATE_ORDER_REG_REQUEST, createOrderRegSaga),
      takeEvery(GET_ORDER_STATUS_REQUEST, getOrderStatusSaga),
      takeEvery(CLEAR_ORDER_REQUEST, clearOrderSaga),
      takeEvery(CHECK_PROMO_REQUEST, checkPromoSaga),
      takeEvery(RESET_DELIVERY_COST_REQUEST, resetDeliveryCostSaga),
      takeEvery(RESET_ORDER_DISCOUNTS_REQUEST, resetDiscountsSaga)
    ]
  );
};
