import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as MaestrelloLogo } from '../../svg/maestrello.svg';
import MobileNav from './mobile-nav';
import DesktopNav from './desktop-nav';
import Promo from './promo';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import amplitude from 'amplitude-js';
import {useNavigate} from "react-router";

function Header({ closeDish }) {
  const [menu, toggleMenu] = useState(false);
  const [menuDeliveryClicked, toggleMenuDeliveryClicked] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (menu) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'visible';
      document.body.style.height = '100%';
    } else {
      if (location.pathname === '/') {
        document.documentElement.style.overflow = 'visible';
        document.body.style.overflow = 'visible';
        document.body.style.height = 'auto';
      }
    }
  }, [menu, location]);

  useEffect(() => {
    toggleMenu(false);
  }, [location]);

  const mobileNavClass = classNames({
    'header__mobile-nav': true,
    'header__mobile-nav--opened': menu
  });

  const burgerClass = classNames({
    'burger': true,
    'burger--opened': menu,
    'effect': true
  });

  const handleClickMenu = event => {
    event.preventDefault();
    toggleMenu(false);
    elementScrollIntoView(document.body, { behavior: 'smooth', block: 'start'  });
    toggleMenuDeliveryClicked(true);
    setTimeout(() => {
      navigate('/');
    }, 200);
  };

  useEffect(() => {
    if (menuDeliveryClicked) {
      amplitude.getInstance().logEvent('button_main_menu_delivery');
      toggleMenuDeliveryClicked(false);
    }
  }, [menuDeliveryClicked]);

  return (
    <div className='header'>
      <div className='header__top'>
        <div className='header__logo effect'>
          <NavLink to='/'>
            <MaestrelloLogo title='Пицца Maestrello' />
          </NavLink>
        </div>
        <div className='header__burger'>
          <div className={burgerClass} onClick={() => toggleMenu(!menu)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <nav className='header__desktop-nav'>
          <DesktopNav closeDish={ closeDish } />
        </nav>
      </div>
      <div className='header__promo'>
        <Promo/>
      </div>
      <div className={mobileNavClass}>
        <div className='header__mobile-nav-inner'>
          <MobileNav handleClickMenu={ handleClickMenu } closeDish={ closeDish } />
        </div>
      </div>
    </div>
  );
}

export default Header;
