import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import apiService from '../services';
import { cartSelector, cutlerySelector, priceSelector } from './cart'
import {
  cleanPhone,
  getDateStringForPromocode,
  collectOrderParamsForAmplitude,
  collectOrderParamsForFacebook, paymentMethods, paymentMethodString, paymentMethodsOptions
} from './../utils'
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import ym from 'react-yandex-metrika';

function sendAmplitudeEvent(event, params) {
  if (params) {
    amplitude.getInstance().logEvent(event, params);
  } else {
    amplitude.getInstance().logEvent(event);
  }
}

function sendFBPurchase(params) {
  ReactPixel.track('Purchase', params);
  ym('reachGoal', 'Purchase', params);
}

function sendYMEvent(event) {
  ym('reachGoal', event);
}

/**
 * Constants
 * */
export const moduleName = 'payment';
const prefix = 'payment';

export const GET_PAYMENT_STATUS_REQUEST = `${prefix}/GET_PAYMENT_STATUS_REQUEST`;
export const GET_PAYMENT_STATUS_START = `${prefix}/GET_PAYMENT_STATUS_START`;
export const GET_PAYMENT_STATUS_SUCCESS = `${prefix}/GET_PAYMENT_STATUS_SUCCESS`;
export const GET_PAYMENT_STATUS_ERROR = `${prefix}/GET_PAYMENT_STATUS_ERROR`;
export const CLEAR = `${prefix}/CLEAR`;


const initialState = {
  id: '',
  contact_id: '',
  create_datetime: '',
  state_id: 'new',
  total: '',
  shipping: '',
  discount: '',
  comment: '',
  payment_method: paymentMethods.CARD,
  pickup: false,
  hash: null,
  loading: false,
  error: false
}

/**
 * Reducer
 * */

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PAYMENT_STATUS_REQUEST:
      return {...state, ...payload, loading: true, error: false };
    case GET_PAYMENT_STATUS_SUCCESS:
      return {...state, ...payload, loading: false, error: false };
    case CLEAR:
      return {
        id: '',
        contact_id: '',
        create_datetime: '',
        state_id: 'new',
        total: '',
        shipping: '',
        comment: '',
        pickup: false,
        hash: null,
        loading: false,
        error: false
       };
    default:
      return state;
  }
}

/**
 * Selectors
 * */
export const paymentSelector = (state) => state[moduleName];

/**
 * Action Creators
 * */

export const getPaymentStatus = (payload) => ({
  type: GET_PAYMENT_STATUS_REQUEST,
  payload: payload
});

/**
 * Sagas
 * */

export const getPaymentStatusSaga = function* ({ payload }) {
  try {
    yield put({
      type: GET_PAYMENT_STATUS_START,
      payload: payload,
    });


    const request = yield call(apiService.getPaymentStatus, payload);

    console.log(request.data);

    const data = {
      id: request.data.id,
      contact_id: request.data.contact_id,
      create_datetime: '',
      state_id: request.data.state_id,
      total: parseFloat(request.data.total),
      shipping: parseFloat(request.data.shipping),
      discount: parseFloat(request.data.discount),
      comment: request.data.comment,
      payment_method: request.data.params.payment_plugin === 'cash' ? paymentMethods.CASH : paymentMethods.CARD,
      pickup: request.data.shipping_id !== "37",
    };

    yield put({
      type: GET_PAYMENT_STATUS_SUCCESS,
      payload: data
    });

  } catch (error) {
    yield put({
      type: GET_PAYMENT_STATUS_ERROR
    });
  }
};

export const saga = function* () {
  yield all(
    [
      takeEvery(GET_PAYMENT_STATUS_REQUEST, getPaymentStatusSaga),
    ]
  );
};
